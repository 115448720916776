import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.starts-with.js";
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
import PropTypes from "prop-types";
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import React from 'react';
import { Link } from 'react-router-dom';
import { EuiCode, EuiColorPickerSwatch } from '../../../../../src';

// @ts-ignore Importing from JS
import { useJsonVars } from '../_json/_get_json_vars';
import { ThemeExample } from '../_components/_theme_example';
import { ThemeValuesTable } from '../_components/_theme_values_table';
import { coreColors, coreTextVariants, grayColors, textOnly } from './_contrast_sass';
var euiBrandColors = _toConsumableArray(coreColors);
export var BrandSass = function BrandSass(_ref) {
  var description = _ref.description;
  var values = useJsonVars();
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeExample, {
    title: ___EmotionJSX("code", null, "$euiColor[Brand]"),
    description: description,
    example: ___EmotionJSX("div", {
      className: "guideSass__euiColorWarning"
    }, ___EmotionJSX("strong", null, "background: ", values.euiColorWarning)),
    snippet: 'background: $euiColorWarning;',
    snippetLanguage: "scss"
  }));
};
BrandSass.propTypes = {
  description: PropTypes.node
};
export var BrandValuesSass = function BrandValuesSass() {
  var values = useJsonVars();
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeValuesTable, {
    items: euiBrandColors.map(function (color) {
      return {
        id: color,
        token: "$".concat(color),
        value: values[color]
      };
    }),
    render: function render(item) {
      return ___EmotionJSX(EuiColorPickerSwatch, {
        color: item.value,
        disabled: true
      });
    }
  }));
};
var euiTextColors = [].concat(_toConsumableArray(textOnly), _toConsumableArray(coreTextVariants));
export var TextSass = function TextSass(_ref2) {
  var description = _ref2.description;
  var values = useJsonVars();
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeExample, {
    title: ___EmotionJSX("code", null, "$euiColor[Text]"),
    description: ___EmotionJSX(React.Fragment, null, description, ___EmotionJSX("p", null, "If your background color is anything other than or darker than the", ' ', ___EmotionJSX(EuiCode, null, "$euiPageBackgroundColor"), ", you will want to re-calculate the high contrast version by using the", ' ', ___EmotionJSX(Link, {
      to: "/theming/colors/utilities"
    }, ___EmotionJSX(EuiCode, null, "@include makeHighContrastColor($foreground, $background)")), ' ', "method.")),
    example: ___EmotionJSX("div", {
      className: "guideSass__euiColorWarningText"
    }, ___EmotionJSX("strong", null, "color: ", values.euiColorTextWarning)),
    snippet: 'color: $euiColorTextWarning;',
    snippetLanguage: "scss"
  }));
};
TextSass.propTypes = {
  description: PropTypes.node
};
export var TextValuesSass = function TextValuesSass() {
  var values = useJsonVars();
  var textColors = [].concat(_toConsumableArray(Object.keys(values).filter(function (v) {
    return v.startsWith('euiColorText');
  })), _toConsumableArray(euiTextColors));
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeValuesTable, {
    items: textColors.map(function (color) {
      return {
        id: color,
        token: "$".concat(color),
        value: values[color]
      };
    }),
    render: function render(item) {
      return ___EmotionJSX(EuiColorPickerSwatch, {
        color: item.value,
        disabled: true
      });
    }
  }));
};
export var BackgroundSass = function BackgroundSass(_ref3) {
  var description = _ref3.description;
  var values = useJsonVars();
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeExample, {
    title: ___EmotionJSX("code", null, "$euiColor[Background]"),
    description: ___EmotionJSX(React.Fragment, null, description),
    example: ___EmotionJSX("div", {
      className: "guideSass__euiColorBackgroundFilledWarning"
    }, ___EmotionJSX("strong", null, "background: ", values.euiColorBackgroundFilledWarning)),
    snippet: 'background-color: $euiColorBackgroundFilledWarning;',
    snippetLanguage: "scss"
  }));
};
BackgroundSass.propTypes = {
  description: PropTypes.node
};
export var BackgroundValuesSass = function BackgroundValuesSass() {
  var values = useJsonVars();
  var backgroundColors = Object.keys(values).filter(function (v) {
    return v.startsWith('euiColorBackground');
  });
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeValuesTable, {
    items: backgroundColors.map(function (color) {
      return {
        id: color,
        token: "$".concat(color),
        value: values[color]
      };
    }),
    render: function render(item) {
      return ___EmotionJSX(EuiColorPickerSwatch, {
        color: item.value,
        disabled: true
      });
    }
  }));
};
export var BorderSass = function BorderSass(_ref4) {
  var description = _ref4.description;
  var values = useJsonVars();
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeExample, {
    title: ___EmotionJSX("code", null, "$euiColor[Border]"),
    description: ___EmotionJSX(React.Fragment, null, description),
    example: ___EmotionJSX("div", {
      className: "guideSass__euiColorBorderBaseWarning"
    }, ___EmotionJSX("strong", null, "border-color: ", values.euiColorBorderBaseWarning)),
    snippet: 'border-color: $euiColorBorderBaseWarning;',
    snippetLanguage: "scss"
  }));
};
BorderSass.propTypes = {
  description: PropTypes.node
};
export var BorderValuesSass = function BorderValuesSass() {
  var values = useJsonVars();
  var borderColors = Object.keys(values).filter(function (v) {
    return v.startsWith('euiColorBorder');
  });
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeValuesTable, {
    items: borderColors.map(function (color) {
      return {
        id: color,
        token: "$".concat(color),
        value: values[color]
      };
    }),
    render: function render(item) {
      return ___EmotionJSX(EuiColorPickerSwatch, {
        color: item.value,
        disabled: true
      });
    }
  }));
};
var euiShadeColors = _toConsumableArray(grayColors);
export var ShadeSass = function ShadeSass(_ref5) {
  var description = _ref5.description;
  var values = useJsonVars();
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeExample, {
    title: ___EmotionJSX("code", null, "$euiColor[Shade]"),
    description: description,
    example: ___EmotionJSX("div", {
      className: "guideSass__tintMediumShade"
    }, ___EmotionJSX("strong", null, "background: ", values.euiColorWarning)),
    snippet: 'background: tintOrShade($euiColorMediumShade, 90%, 70%);',
    snippetLanguage: "scss"
  }));
};
ShadeSass.propTypes = {
  description: PropTypes.node
};
export var ShadeValuesSass = function ShadeValuesSass() {
  var values = useJsonVars();
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeValuesTable, {
    items: euiShadeColors.map(function (color) {
      return {
        id: color,
        token: "$".concat(color),
        value: values[color]
      };
    }),
    render: function render(item) {
      return ___EmotionJSX(EuiColorPickerSwatch, {
        color: item.value,
        disabled: true
      });
    }
  }));
};
var euiSpecialColors = ['euiPageBackgroundColor', 'euiColorHighlight', 'euiColorDisabled', 'euiColorDisabledText', 'euiColorGhost', 'euiColorInk', 'euiColorPlainLight', 'euiColorPlainDark'];
export var SpecialSass = ({
  description
}) => {
  return <>
      <ThemeExample title={<code>$euiColor[Special]</code>} description={description} example={<div className="guideSass__special">
            <strong>
              This text is always light and the background always dark.
            </strong>
          </div>} snippet={`color: \$euiColorPlainLight;
background-color: \$euiColorPlainDark;`} snippetLanguage="scss" />
    </>;
};
SpecialSass.propTypes = {
  description: PropTypes.node
};
export const SpecialValuesSass = () => {
  const values = useJsonVars();
  return <>
      <ThemeValuesTable items={euiSpecialColors.map(color => {
      return {
        id: color,
        token: `$${color}`,
        value: values[color]
      };
    })} render={item => <EuiColorPickerSwatch color={item.value} disabled />} />
    </>;
};
try {
  BrandSass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/color/_color_sass.tsx',
    description: '',
    displayName: 'BrandSass',
    methods: [],
    props: {
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'React.ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  TextSass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/color/_color_sass.tsx',
    description: '',
    displayName: 'TextSass',
    methods: [],
    props: {
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'React.ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  BackgroundSass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/color/_color_sass.tsx',
    description: '',
    displayName: 'BackgroundSass',
    methods: [],
    props: {
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'React.ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  BorderSass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/color/_color_sass.tsx',
    description: '',
    displayName: 'BorderSass',
    methods: [],
    props: {
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'React.ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  ShadeSass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/color/_color_sass.tsx',
    description: '',
    displayName: 'ShadeSass',
    methods: [],
    props: {
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'React.ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  SpecialSass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/color/_color_sass.tsx',
    description: '',
    displayName: 'SpecialSass',
    methods: [],
    props: {
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'React.ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}