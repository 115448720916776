/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { useEuiTheme } from '../../../services';
import { euiPopoverArrowStyles } from './_popover_arrow.styles';
export var POSITIONS = ['top', 'left', 'right', 'bottom'];
export var EuiPopoverArrow = ({
  children,
  position,
  style,
  ...rest
}) => {
  const euiTheme = useEuiTheme();
  const styles = euiPopoverArrowStyles(euiTheme);
  const cssStyles = [styles.euiPopoverArrow, styles[position]];
  return <div className="euiPopover__arrowWrapper" css={styles.euiPopoverArrowWrapper} style={style} {...rest}>
      <div className="euiPopover__arrow" data-popover-arrow={position} css={cssStyles} />
      {children}
    </div>;
};
EuiPopoverArrow.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  position: PropTypes.any.isRequired
};
try {
  EuiPopoverArrow.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/popover/popover_arrow/_popover_arrow.tsx',
    description: '',
    displayName: 'EuiPopoverArrow',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      position: {
        defaultValue: null,
        description: '',
        name: 'position',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/popover/popover_arrow/_popover_arrow.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: '"left" | "right" | "top" | "bottom"',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"top"'
          }, {
            value: '"bottom"'
          }]
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}