/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { useEuiI18n } from '../i18n';
import { euiMarkStyles, euiMarkScreenReaderStyles } from './mark.styles';
export var EuiMark = ({
  children,
  className,
  hasScreenReaderHelpText = true,
  ...rest
}) => {
  const classes = classNames('euiMark', className);
  const styles = useEuiMemoizedStyles(euiMarkStyles);
  const highlightStart = useEuiI18n('euiMark.highlightStart', 'highlight start');
  const highlightEnd = useEuiI18n('euiMark.highlightEnd', 'highlight end');
  const screenReaderStyles = useMemo(() => euiMarkScreenReaderStyles(highlightStart, highlightEnd), [highlightStart, highlightEnd]);
  const cssStyles = [styles.euiMark, hasScreenReaderHelpText && screenReaderStyles.hasScreenReaderHelpText];
  return <mark css={cssStyles} className={classes} {...rest}>
      {children}
    </mark>;
};
EuiMark.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Set to `false` to remove the CSS :before and :after
       * screen reader helper text
       */
  hasScreenReaderHelpText: PropTypes.bool,
  /**
       * ReactNode to render as this component's content
       */
  children: PropTypes.node.isRequired
};
try {
  EuiMark.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/mark/mark.tsx',
    description: '',
    displayName: 'EuiMark',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'eui/src/components/mark/mark.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      hasScreenReaderHelpText: {
        defaultValue: {
          value: 'true'
        },
        description: 'Set to `false` to remove the CSS :before and :after\n' + 'screen reader helper text',
        name: 'hasScreenReaderHelpText',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/mark/mark.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}