function _EMOTION_STRINGIFIED_CSS_ERROR__() {
  return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
}
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
import PropTypes from "prop-types";
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import chroma from 'chroma-js';
import React from 'react';
import { EuiText, EuiFlexGrid, EuiCopy, EuiFlexItem, EuiPanel, EuiHorizontalRule, EuiListGroupItem, useEuiTheme } from '../../../../../src';

// @ts-ignore Importing from JS
import { useJsonVars } from '../_json/_get_json_vars';
export var coreColors = ['euiColorPrimary', 'euiColorAccent', 'euiColorAccentSecondary', 'euiColorSuccess', 'euiColorWarning', 'euiColorDanger'];
export var coreTextVariants = ['euiColorPrimaryText', 'euiColorAccentText', 'euiColorSuccessText', 'euiColorWarningText', 'euiColorDangerText', 'euiColorTextPrimary', 'euiColorTextAccent', 'euiColorTextAccentSecondary', 'euiColorTextSuccess', 'euiColorTextWarning', 'euiColorTextDanger'];
export var grayColors = ['euiColorEmptyShade', 'euiColorLightestShade', 'euiColorLightShade', 'euiColorMediumShade', 'euiColorDarkShade', 'euiColorDarkestShade', 'euiColorFullShade'];
export var textOnly = ['euiTextColor', 'euiTitleColor', 'euiTextSubduedColor', 'euiLinkColor', 'euiColorTextParagraph', 'euiColorTextHeading', 'euiColorTextSubdued', 'euiColorTextDisabled', 'euiColorTextInverse'];
export var backgroundBaseColors = ['euiColorBackgroundBasePrimary', 'euiColorBackgroundBaseAccent', 'euiColorBackgroundBaseAccentSecondary', 'euiColorBackgroundBaseSuccess', 'euiColorBackgroundBaseWarning', 'euiColorBackgroundBaseDanger', 'euiColorBackgroundBaseSubdued', 'euiColorBackgroundBaseDisabled', 'euiColorBackgroundBaseHighlighted', 'euiColorBackgroundBasePlain'];
export var backgroundLightColors = ['euiColorBackgroundLightPrimary', 'euiColorBackgroundLightAccent', 'euiColorBackgroundLightAccentSecondary', 'euiColorBackgroundLightSuccess', 'euiColorBackgroundLightWarning', 'euiColorBackgroundLightDanger', 'euiColorBackgroundLightText'];
export var backgroundFilledColors = ['euiColorBackgroundFilledPrimary', 'euiColorBackgroundFilledAccent', 'euiColorBackgroundFilledAccentSecondary', 'euiColorBackgroundFilledSuccess', 'euiColorBackgroundFilledWarning', 'euiColorBackgroundFilledDanger', 'euiColorBackgroundFilledText'];
export var backgroundColors = [].concat(backgroundBaseColors, backgroundLightColors, backgroundFilledColors);
export var textColors = [].concat(textOnly, ['euiColorGhost', 'euiColorInk']);
export var allowedColors = [].concat(coreColors, grayColors, ['euiColorDisabled', 'euiColorGhost', 'euiColorInk']);
export var textVariants = [].concat(coreTextVariants, _toConsumableArray(textColors));
import { getContrastRatings } from './_contrast_utilities';
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "twy0ct-ColorSectionSass",
  styles: "grid-template-rows:auto;grid-auto-flow:row;label:ColorSectionSass;"
} : {
  name: "twy0ct-ColorSectionSass",
  styles: "grid-template-rows:auto;grid-auto-flow:row;label:ColorSectionSass;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export var ColorSectionSass = function ColorSectionSass(_ref) {
  var color = _ref.color,
    minimumContrast = _ref.minimumContrast,
    showTextVariants = _ref.showTextVariants,
    matchPanelColor = _ref.matchPanelColor;
  var palette = useJsonVars();
  var colorsForContrast = showTextVariants ? textVariants : allowedColors;
  var colorsWithMinContrast = colorsForContrast.filter(function (_color) {
    var backgroundColor = palette[color];
    var foregroundColor = palette[_color];
    var contrast = chroma.contrast(backgroundColor, foregroundColor);
    return contrast && contrast >= minimumContrast;
  });
  function colorIsCore(color) {
    return coreColors.includes(color) || coreTextVariants.includes(color);
  }
  return ___EmotionJSX(EuiPanel, {
    color: "transparent",
    hasBorder: false,
    paddingSize: matchPanelColor ? 'l' : 'none',
    style: {
      background: matchPanelColor ? palette[color] : undefined
    }
  }, ___EmotionJSX(EuiText, {
    size: "xs"
  }, ___EmotionJSX(EuiFlexGrid, {
    css: _ref2,
    columns: 2,
    direction: "column",
    gutterSize: "s"
  }, showTextVariants && colorIsCore(color) && ___EmotionJSX(ColorsContrastItem, {
    foreground: "".concat(color, "Text"),
    background: 'euiPageBackgroundColor',
    minimumContrast: minimumContrast
  }), colorsWithMinContrast.map(function (color2) {
    if (colorIsCore(color) && colorIsCore(color2)) {
      // i.e. don't render if both are core colors
      return;
    }
    return ___EmotionJSX(ColorsContrastItem, {
      foreground: color2,
      background: color,
      key: color2,
      minimumContrast: minimumContrast
    });
  }))));
};
ColorSectionSass.propTypes = {
  color: PropTypes.string.isRequired,
  minimumContrast: PropTypes.number.isRequired,
  showTextVariants: PropTypes.bool.isRequired,
  matchPanelColor: PropTypes.bool
};
var ColorsContrastItem = ({
  foreground,
  background,
  minimumContrast
}) => {
  const {
    euiTheme
  } = useEuiTheme();
  const palette = useJsonVars();
  const backgroundColor = palette[background];
  const foregroundColor = palette[foreground];
  const backgroundIsBody = background === 'euiPageBackgroundColor';
  const contrast = chroma.contrast(backgroundColor, foregroundColor);
  if (!contrast || contrast < minimumContrast) {
    return <></>;
  }
  const {
    contrastRating,
    contrastRatingBadge
  } = getContrastRatings(contrast, foreground);
  const contrastIsAcceptableToCopy = contrast >= 3;
  const textToCopy = backgroundIsBody ? `color: $${foreground};` : `background-color: $${background};
color: $${foreground};`;
  const beforeMessage = contrastIsAcceptableToCopy ? <small>
      <code>{`$${foreground} / $${background}`}</code>
      <EuiHorizontalRule margin="xs" />
      <kbd>Click</kbd> to copy SASS configuration
    </small> : <small>
      Cannot copy configuration because the contrast is not acceptable
    </small>;
  return <EuiFlexItem className="eui-textCenter">
      <EuiCopy anchorClassName="eui-displayBlock" title={<span>
            {contrastRatingBadge} Contrast is {contrast.toFixed(1)}
          </span>} beforeMessage={beforeMessage} afterMessage={<small>Copied!</small>} textToCopy={textToCopy}>
        {copy => <EuiListGroupItem iconType={contrastRating} size="s" onClick={contrastIsAcceptableToCopy ? copy : undefined} aria-label={contrastIsAcceptableToCopy ? 'Click to copy Sass configurations' : undefined} style={{
        backgroundColor: backgroundColor,
        color: foregroundColor,
        borderRadius: euiTheme.border.radius.medium
      }}
      // @ts-expect-error - this isn't a valid color type, we mostly just want to disable the default EuiListGroupItem button color from being rendered since we're setting our own via `style`
      color="inherit" label={sanitizeColorName(foreground)} />}
      </EuiCopy>
    </EuiFlexItem>;
};
ColorsContrastItem.propTypes = {
  foreground: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  minimumContrast: PropTypes.number.isRequired
};
function sanitizeColorName(color) {
  const string = color.split('euiColor').pop();
  // Make sure `Color` is removed where `Color` is used at the end of the name
  const noColor = string?.replace('Color', '');
  // Make sure `eui` is removed if it wasnt the full `euiColor` string
  const noEui = noColor?.split('eui').pop();
  // Add space between caplital letters
  return noEui?.replace(/([A-Z])/g, ' $1').trim();
}
try {
  ColorSectionSass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/color/_contrast_sass.tsx',
    description: '',
    displayName: 'ColorSectionSass',
    methods: [],
    props: {
      color: {
        defaultValue: null,
        description: '',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/color/_contrast_sass.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      minimumContrast: {
        defaultValue: null,
        description: '',
        name: 'minimumContrast',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/color/_contrast_sass.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      showTextVariants: {
        defaultValue: null,
        description: '',
        name: 'showTextVariants',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/color/_contrast_sass.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      matchPanelColor: {
        defaultValue: null,
        description: '',
        name: 'matchPanelColor',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/color/_contrast_sass.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}