/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useCombinedRefs, useEuiTheme, useEuiMemoizedStyles } from '../../services';
import { DEFAULT_LANGUAGE, checkSupportedLanguage, getHtmlContent, highlightByLine } from './utils';
import { useOverflow } from './code_block_overflow';
import { useCopy } from './code_block_copy';
import { useFullScreen, EuiCodeBlockFullScreenWrapper } from './code_block_full_screen';
import { EuiCodeBlockControls } from './code_block_controls';
import { EuiCodeBlockVirtualized } from './code_block_virtualized';
import { euiCodeBlockStyles, euiCodeBlockPreStyles, euiCodeBlockCodeStyles } from './code_block.styles';

// Based on observed line height for non-virtualized code blocks
var fontSizeToRowHeightMap = {
  s: 18,
  m: 21,
  l: 24
};
export var FONT_SIZES = ['s', 'm', 'l'];
export var PADDING_SIZES = ['none', 's', 'm', 'l'];

// This exclusive union enforces specific props based on isVirtualized

export var EuiCodeBlock = ({
  language: _language = DEFAULT_LANGUAGE,
  transparentBackground = false,
  paddingSize = 'l',
  fontSize = 's',
  isCopyable = false,
  copyAriaLabel,
  whiteSpace = 'pre-wrap',
  children,
  className,
  overflowHeight,
  isVirtualized: _isVirtualized,
  lineNumbers = false,
  ...rest
}) => {
  const euiTheme = useEuiTheme();
  const language = useMemo(() => checkSupportedLanguage(_language), [_language]);
  const lineNumbersConfig = useMemo(() => {
    const config = typeof lineNumbers === 'object' ? lineNumbers : {};
    return lineNumbers ? {
      start: 1,
      show: true,
      ...config
    } : {
      start: 1,
      show: false
    };
  }, [lineNumbers]);

  // Used by `FixedSizeList` when `isVirtualized=true` or `children` is parsable
  const data = useMemo(() => {
    if (typeof children !== 'string') {
      return [];
    }
    return highlightByLine(children, language, lineNumbersConfig, euiTheme);
  }, [children, language, lineNumbersConfig, euiTheme]);

  // Used by `pre` when `isVirtualized=false` or `children` is not parsable
  const content = useMemo(() => getHtmlContent(data, children), [data, children]);
  const isVirtualized = useMemo(() => !!(_isVirtualized && Array.isArray(data)), [_isVirtualized, data]);
  const {
    innerTextRef,
    copyButton
  } = useCopy({
    copyAriaLabel,
    isCopyable,
    isVirtualized,
    children
  });
  const {
    setWrapperRef,
    tabIndex,
    overflowHeightStyles
  } = useOverflow({
    overflowHeight
  });
  const combinedRef = useCombinedRefs([innerTextRef, setWrapperRef]);
  const {
    fullScreenButton,
    isFullScreen,
    onKeyDown
  } = useFullScreen({
    overflowHeight
  });
  const hasControls = !!(copyButton || fullScreenButton);
  const hasBothControls = !!(copyButton && fullScreenButton);
  const styles = useEuiMemoizedStyles(euiCodeBlockStyles);
  const cssStyles = [styles.euiCodeBlock, styles[fontSize], transparentBackground && styles.transparentBackground, hasControls && (hasBothControls ? styles.hasBothControls[paddingSize] : styles.hasControls[paddingSize])];
  const preStyles = useEuiMemoizedStyles(euiCodeBlockPreStyles);
  const [preProps, preFullscreenProps] = useMemo(() => {
    const isWhiteSpacePre = whiteSpace === 'pre' || isVirtualized;
    const cssStyles = [preStyles.euiCodeBlock__pre, isWhiteSpacePre ? preStyles.whiteSpace.pre.pre : preStyles.whiteSpace.preWrap.preWrap];
    const preProps = {
      className: 'euiCodeBlock__pre',
      css: [...cssStyles, preStyles.padding[paddingSize], hasControls && (isWhiteSpacePre ? preStyles.whiteSpace.pre.controlsOffset[paddingSize] : preStyles.whiteSpace.preWrap.controlsOffset[paddingSize])],
      tabIndex: isVirtualized ? 0 : tabIndex
    };
    const preFullscreenProps = {
      className: 'euiCodeBlock__pre',
      css: [...cssStyles,
      // Force fullscreen to use xl padding
      preStyles.padding.xl, hasControls && (isWhiteSpacePre ? preStyles.whiteSpace.pre.controlsOffset.xl : preStyles.whiteSpace.preWrap.controlsOffset.xl)],
      tabIndex: 0,
      onKeyDown
    };
    return [preProps, preFullscreenProps];
  }, [preStyles, whiteSpace, isVirtualized, hasControls, paddingSize, onKeyDown, tabIndex]);
  const codeStyles = useEuiMemoizedStyles(euiCodeBlockCodeStyles);
  const codeProps = useMemo(() => {
    const cssStyles = [codeStyles.euiCodeBlock__code, isVirtualized && codeStyles.isVirtualized];
    return {
      className: 'euiCodeBlock__code',
      css: cssStyles,
      'data-code-language': language,
      ...rest
    };
  }, [codeStyles, language, isVirtualized, rest]);
  return <div css={cssStyles} className={classNames('euiCodeBlock', className)} style={overflowHeightStyles}>
      {isVirtualized ? <EuiCodeBlockVirtualized data={data} rowHeight={fontSizeToRowHeightMap[fontSize]} overflowHeight={overflowHeight} preProps={preProps} codeProps={codeProps} /> : <pre {...preProps} ref={combinedRef} style={overflowHeightStyles}>
          <code {...codeProps}>{content}</code>
        </pre>}
      <EuiCodeBlockControls controls={[fullScreenButton, copyButton]} paddingSize={paddingSize} />

      {isFullScreen && <EuiCodeBlockFullScreenWrapper>
          {isVirtualized ? <EuiCodeBlockVirtualized data={data} rowHeight={fontSizeToRowHeightMap.l} preProps={preFullscreenProps} codeProps={codeProps} /> : <pre {...preFullscreenProps}>
              <code {...codeProps}>{content}</code>
            </pre>}
          <EuiCodeBlockControls controls={[fullScreenButton, copyButton]} paddingSize="l" />
        </EuiCodeBlockFullScreenWrapper>}
    </div>;
};
EuiCodeBlock.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Sets the syntax highlighting for a specific language
       * @see [https://prismjs.com/#supported-languages](https://prismjs.com/#supported-languages) for options
       */
  language: PropTypes.string,
  transparentBackground: PropTypes.bool,
  paddingSize: PropTypes.any,
  fontSize: PropTypes.any,
  /**
     * Specify how `white-space` inside the element is handled.
     * `pre` respects line breaks/white space but doesn't force them to wrap the line
     * `pre-wrap` respects line breaks/white space but does force them to wrap the line when necessary.
     */
  whiteSpace: PropTypes.oneOfType([PropTypes.oneOf(["pre", "pre-wrap"]), PropTypes.oneOfType([PropTypes.oneOf(["pre"]), PropTypes.oneOf(["pre", "pre-wrap"])])]),
  /**
     * Displays an icon button to copy the code snippet to the clipboard.
     */
  isCopyable: PropTypes.bool,
  /**
     * Customizes the aria-label for the copy button.
     *
     * @default 'Copy'
     */
  copyAriaLabel: PropTypes.string,
  /**
     * Displays line numbers.
     * Optionally accepts a configuration object for setting the starting number,
     * visually highlighting ranges, or annotating specific lines:
     * `{ start: 100, highlight: '1, 5-10, 20-30, 40', annotations: { 6: 'A special note about this line' } }`
     */
  lineNumbers: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.shape({
    start: PropTypes.number,
    highlight: PropTypes.string,
    annotations: PropTypes.any
  }).isRequired]),
  /**
     * Sets the maximum container height.
     * Accepts a pixel value (`300`) or a percentage (`'100%'`)
     * Ensure the container has calcuable height when using a percentage
     */
  overflowHeight: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]), PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]).isRequired, PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired])])]),
  /**
     * Renders code block lines virtually.
     * Useful for improving load times of large code blocks.
     *
     * When using this configuration, `overflowHeight` is required and
     * `whiteSpace` can only be `pre`.
     */
  isVirtualized: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOfType([PropTypes.oneOf([true]).isRequired, PropTypes.oneOf([false])])])
};
try {
  EuiCodeBlock.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/code/code_block.tsx',
    description: '',
    displayName: 'EuiCodeBlock',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      language: {
        defaultValue: null,
        description: 'Sets the syntax highlighting for a specific language\n' + '@see [https://prismjs.com/#supported-languages](https://prismjs.com/#supported-languages) for options',
        name: 'language',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/code/utils.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      transparentBackground: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'transparentBackground',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/code/utils.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      paddingSize: {
        defaultValue: {
          value: 'l'
        },
        description: '',
        name: 'paddingSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "l"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }]
        }
      },
      fontSize: {
        defaultValue: {
          value: 's'
        },
        description: '',
        name: 'fontSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }]
        }
      },
      whiteSpace: {
        defaultValue: {
          value: 'pre-wrap'
        },
        description: 'Specify how `white-space` inside the element is handled.\n' + "`pre` respects line breaks/white space but doesn't force them to wrap the line\n" + '`pre-wrap` respects line breaks/white space but does force them to wrap the line when necessary.',
        name: 'whiteSpace',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"pre" | "pre-wrap"',
          value: [{
            value: '"pre"'
          }, {
            value: '"pre-wrap"'
          }]
        }
      },
      isCopyable: {
        defaultValue: {
          value: 'false'
        },
        description: 'Displays an icon button to copy the code snippet to the clipboard.',
        name: 'isCopyable',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      copyAriaLabel: {
        defaultValue: {
          value: "'Copy'"
        },
        description: 'Customizes the aria-label for the copy button.',
        name: 'copyAriaLabel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      lineNumbers: {
        defaultValue: {
          value: 'false'
        },
        description: 'Displays line numbers.\n' + 'Optionally accepts a configuration object for setting the starting number,\n' + 'visually highlighting ranges, or annotating specific lines:\n' + "`{ start: 100, highlight: '1, 5-10, 20-30, 40', annotations: { 6: 'A special note about this line' } }`",
        name: 'lineNumbers',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | LineNumbersConfig'
        }
      },
      overflowHeight: {
        defaultValue: null,
        description: 'Sets the maximum container height.\n' + "Accepts a pixel value (`300`) or a percentage (`'100%'`)\n" + 'Ensure the container has calcuable height when using a percentage',
        name: 'overflowHeight',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      isVirtualized: {
        defaultValue: null,
        description: 'Renders code block lines virtually.\n' + 'Useful for improving load times of large code blocks.\n' + '\n' + 'When using this configuration, `overflowHeight` is required and\n' + '`whiteSpace` can only be `pre`.',
        name: 'isVirtualized',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/code/code_block.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}