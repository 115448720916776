/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component, createRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { withEuiTheme } from '../../services';
import { EuiFlexGroup, EuiFlexItem } from '../flex';
import { EuiToolTip } from '../tool_tip';
import { EuiIcon } from '../icon';
import { euiFilterSelectItemStyles } from './filter_select_item.styles';
var resolveIconAndColor = function resolveIconAndColor(checked) {
  if (!checked) {
    return {
      icon: 'empty'
    };
  }
  return checked === 'on' ? {
    icon: 'check',
    color: 'text'
  } : {
    icon: 'cross',
    color: 'text'
  };
};

/**
 * TODO: This component should removed in favor of EuiSelectable usage
 * once EuiComboBox has been converted to dogfood EuiSelectable.
 *
 * @deprecated - Use EuiSelectable instead
 */
export class EuiFilterSelectItemClass extends Component {
  static defaultProps = {
    showIcons: true
  };
  buttonRef = null;
  tooltipRef = createRef();
  state = {
    hasFocus: false
  };
  focus = () => {
    if (this.buttonRef) {
      this.buttonRef.focus();
    }
  };
  toggleToolTip = isFocused => {
    if (isFocused) {
      this.tooltipRef?.current?.showToolTip();
    } else {
      this.tooltipRef?.current?.hideToolTip();
    }
  };
  hasFocus = () => {
    return this.state.hasFocus;
  };
  render() {
    const {
      theme,
      children,
      className,
      disabled,
      checked,
      isFocused,
      showIcons,
      toolTipContent,
      toolTipProps,
      style,
      ...rest
    } = this.props;
    const styles = euiFilterSelectItemStyles(theme);
    const cssStyles = [styles.euiFilterSelectItem, isFocused && styles.isFocused];
    const classes = classNames('euiFilterSelectItem', className);
    const hasToolTip =
    // we're using isValidElement here as EuiToolTipAnchor uses
    // cloneElement to enhance the element with required attributes
    React.isValidElement(children) && !disabled && toolTipContent;
    let anchorProps = undefined;
    if (hasToolTip) {
      const anchorStyles = toolTipProps?.anchorProps?.style ? {
        ...toolTipProps?.anchorProps?.style,
        ...style
      } : style;
      anchorProps = toolTipProps?.anchorProps ? {
        ...toolTipProps.anchorProps,
        style: anchorStyles
      } : {
        style
      };
      this.toggleToolTip(isFocused ?? false);
    }
    let iconNode;
    if (showIcons) {
      const {
        icon,
        color
      } = resolveIconAndColor(checked);
      iconNode = <EuiFlexItem grow={false}>
          <EuiIcon color={color} type={icon} />
        </EuiFlexItem>;
    }
    const optionItem = <button ref={ref => this.buttonRef = ref} role="option" type="button" aria-selected={checked === 'on'} className={classes} css={cssStyles} disabled={disabled} aria-disabled={disabled} style={!hasToolTip ? style : undefined} {...rest}>
        <EuiFlexGroup alignItems="center" gutterSize="s" component="span" responsive={false}>
          {iconNode}
          <EuiFlexItem className="euiFilterSelectItem__content eui-textTruncate" component="span">
            {children}
          </EuiFlexItem>
        </EuiFlexGroup>
      </button>;
    return hasToolTip ? <EuiToolTip ref={this.tooltipRef} display="block" content={toolTipContent} position="left" {...toolTipProps} anchorProps={anchorProps}>
        {optionItem}
      </EuiToolTip> : optionItem;
  }
}

/**
 * @deprecated - Use EuiSelectable instead
 */
EuiFilterSelectItemClass.propTypes = {
  checked: PropTypes.oneOf(["on", "off"]),
  showIcons: PropTypes.bool,
  isFocused: PropTypes.bool,
  toolTipContent: PropTypes.node,
  toolTipProps: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
export const EuiFilterSelectItem = withEuiTheme(EuiFilterSelectItemClass);
try {
  EuiFilterSelectItemClass.__docgenInfo = {
    tags: {
      deprecated: '- Use EuiSelectable instead'
    },
    filePath: '/app/packages/eui/src/components/filter_group/filter_select_item.tsx',
    description: 'TODO: This component should removed in favor of EuiSelectable usage\n' + 'once EuiComboBox has been converted to dogfood EuiSelectable.',
    displayName: 'EuiFilterSelectItemClass',
    methods: [],
    props: {
      theme: {
        defaultValue: null,
        description: '',
        name: 'theme',
        parent: {
          fileName: 'eui/src/services/theme/hooks.tsx',
          name: 'WithEuiThemeProps'
        },
        declarations: [{
          fileName: 'eui/src/services/theme/hooks.tsx',
          name: 'WithEuiThemeProps'
        }],
        required: true,
        type: {
          name: 'UseEuiTheme<{}>'
        }
      },
      checked: {
        defaultValue: null,
        description: '',
        name: 'checked',
        parent: {
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'FilterChecked',
          value: [{
            value: '"off"'
          }, {
            value: '"on"'
          }]
        }
      },
      showIcons: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'showIcons',
        parent: {
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isFocused: {
        defaultValue: null,
        description: '',
        name: 'isFocused',
        parent: {
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      toolTipContent: {
        defaultValue: null,
        description: '',
        name: 'toolTipContent',
        parent: {
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      toolTipProps: {
        defaultValue: null,
        description: '',
        name: 'toolTipProps',
        parent: {
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiToolTipProps, "content" | "children">>'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['WithEuiThemeProps', 'EuiFilterSelectItemProps', 'CommonProps', 'ButtonHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'Attributes']
  };
} catch (e) {}
try {
  EuiFilterSelectItem.__docgenInfo = {
    tags: {
      deprecated: '- Use EuiSelectable instead'
    },
    filePath: '/app/packages/eui/src/components/filter_group/filter_select_item.tsx',
    description: '',
    displayName: 'EuiFilterSelectItem',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      checked: {
        defaultValue: null,
        description: '',
        name: 'checked',
        parent: {
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'FilterChecked',
          value: [{
            value: '"off"'
          }, {
            value: '"on"'
          }]
        }
      },
      toolTipProps: {
        defaultValue: null,
        description: '',
        name: 'toolTipProps',
        parent: {
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiToolTipProps, "content" | "children">>'
        }
      },
      toolTipContent: {
        defaultValue: null,
        description: '',
        name: 'toolTipContent',
        parent: {
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      showIcons: {
        defaultValue: null,
        description: '',
        name: 'showIcons',
        parent: {
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isFocused: {
        defaultValue: null,
        description: '',
        name: 'isFocused',
        parent: {
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/filter_group/filter_select_item.tsx',
          name: 'EuiFilterSelectItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<unknown>'
        }
      }
    },
    extendedInterfaces: ['WithEuiThemeProps', 'EuiFilterSelectItemProps', 'CommonProps', 'ButtonHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'Attributes']
  };
} catch (e) {}