import React from 'react';
import PropTypes from "prop-types";
import { EuiCopy } from '../../../../src';
import { ThemeExample } from '../theme/_components/_theme_example';
export var LANGUAGES = ['javascript', 'html'];
export var UtilityClassesSection = ({
  code,
  ...rest
}) => {
  return <ThemeExample title={<EuiCopy textToCopy={code}>
          {copy => <button onClick={copy}>
              <strong>
                <code>{code}</code>
              </strong>
            </button>}
        </EuiCopy>} {...rest} />;
};
UtilityClassesSection.propTypes = {
  color: PropTypes.any,
  title: PropTypes.node,
  description: PropTypes.node,
  type: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.oneOf([null])]),
  property: PropTypes.string,
  example: PropTypes.node,
  examplePanel: PropTypes.shape({
    /**
       * Padding for all four sides
       */
    paddingSize: PropTypes.any,
    /**
       * When true the panel will grow in height to match `EuiFlexItem`
       */
    grow: PropTypes.bool,
    panelRef: PropTypes.any,
    /**
       * Background color of the panel;
       * Usually a lightened form of the brand colors
       */
    color: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.oneOf(["highlighted"])]),
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  snippet: PropTypes.node,
  snippetLanguage: PropTypes.any,
  props: PropTypes.node,
  provider: PropTypes.shape({
    property: PropTypes.string,
    type: PropTypes.string
  }),
  code: PropTypes.string.isRequired
};
try {
  UtilityClassesSection.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/utility_classes/utility_classes_section.tsx',
    description: '',
    displayName: 'UtilityClassesSection',
    methods: [],
    props: {
      color: {
        defaultValue: null,
        description: '',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_components/_theme_example.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"transparent" | "accent" | "accentSecondary" | "primary" | "success" | "warning" | "danger" | "subdued" | "plain" | "highlighted"',
          value: [{
            value: '"transparent"'
          }, {
            value: '"accent"'
          }, {
            value: '"accentSecondary"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }, {
            value: '"plain"'
          }, {
            value: '"highlighted"'
          }]
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_components/_theme_example.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_components/_theme_example.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      type: {
        defaultValue: null,
        description: '',
        name: 'type',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_components/_theme_example.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      property: {
        defaultValue: null,
        description: '',
        name: 'property',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_components/_theme_example.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      example: {
        defaultValue: null,
        description: '',
        name: 'example',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_components/_theme_example.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      examplePanel: {
        defaultValue: null,
        description: '',
        name: 'examplePanel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_components/_theme_example.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLDivElement> & Omit<_EuiPanelProps, "hasBorder" | "hasShadow" | "borderRadius">'
        }
      },
      snippet: {
        defaultValue: null,
        description: '',
        name: 'snippet',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_components/_theme_example.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      snippetLanguage: {
        defaultValue: null,
        description: '',
        name: 'snippetLanguage',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_components/_theme_example.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      props: {
        defaultValue: null,
        description: '',
        name: 'props',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_components/_theme_example.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      provider: {
        defaultValue: null,
        description: '',
        name: 'provider',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_components/_theme_example.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '{ property?: string; type?: string; }'
        }
      },
      code: {
        defaultValue: null,
        description: '',
        name: 'code',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/utility_classes/utility_classes_section.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}