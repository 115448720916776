import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Fragment, useContext } from 'react';
import { EuiI18nConsumer } from '../context';
import { I18nContext } from '../context/context';
import { processStringToChildren } from './i18n_util';
function errorOnMissingValues(token) {
  throw new Error("I18n mapping for token \"".concat(token, "\" is a formatting function but no values were provided."));
}
function lookupToken(options) {
  var token = options.token,
    i18nMapping = options.i18nMapping,
    valueDefault = options.valueDefault,
    i18nMappingFunc = options.i18nMappingFunc,
    values = options.values,
    render = options.render;
  var renderable = i18nMapping && i18nMapping[token] || valueDefault;
  if (typeof renderable === 'function') {
    if (values === undefined) {
      return errorOnMissingValues(token);
    }
    // @ts-ignore TypeScript complains that `DEFAULT` doesn't have a call signature but we verified `renderable` is a function
    var rendered = renderable(values);
    return i18nMappingFunc && typeof rendered === 'string' ? i18nMappingFunc(rendered) : rendered;
  } else if (values === undefined || typeof renderable !== 'string') {
    if (i18nMappingFunc && typeof valueDefault === 'string') {
      renderable = i18nMappingFunc(valueDefault);
    }
    // there's a hole in the typings here as there is no guarantee that i18nMappingFunc
    // returned the same type of the default value, but we need to keep that assumption
    return renderable;
  }
  var children = processStringToChildren(renderable, values, i18nMappingFunc);
  if (typeof children === 'string') {
    // likewise, `processStringToChildren` returns a string or ReactNode[] depending on
    // the type of `values`, so we will make the assumption that the default value is correct.
    return children;
  }
  var Component = render ? render(children) : () => <Fragment>{children}</Fragment>;

  // same reasons as above, we can't promise the transforms match the default's type
  return React.createElement(Component, values);
}
function isI18nTokensShape(x) {
  return x.tokens != null;
}

// Must use the generics <T extends {}>
// If instead typed with React.FunctionComponent there isn't feedback given back to the dev
// when using a `values` object with a renderer callback.
const EuiI18n = props => <EuiI18nConsumer>
    {i18nConfig => {
    const {
      mapping,
      mappingFunc,
      render
    } = i18nConfig;
    if (isI18nTokensShape(props)) {
      return props.children(props.tokens.map((token, idx) => lookupToken({
        token,
        i18nMapping: mapping,
        i18nMappingFunc: mappingFunc,
        valueDefault: props.defaults[idx],
        values: props.values,
        render
      })));
    }
    const tokenValue = lookupToken({
      token: props.token,
      i18nMapping: mapping,
      valueDefault: props.default,
      i18nMappingFunc: mappingFunc,
      values: props.values,
      render
    });
    if (props.children) {
      return props.children(tokenValue);
    } else {
      return tokenValue;
    }
  }}
  </EuiI18nConsumer>;

// A single default could be a string, react child, or render function

// An array with multiple defaults can only be an array of strings or elements

function useEuiI18n(...props) {
  const i18nConfig = useContext(I18nContext);
  const {
    mapping,
    mappingFunc,
    render
  } = i18nConfig;
  if (typeof props[0] === 'string') {
    const [token, defaultValue, values] = props;
    return lookupToken({
      token,
      i18nMapping: mapping,
      valueDefault: defaultValue,
      i18nMappingFunc: mappingFunc,
      values,
      render
    });
  } else {
    const [tokens, defaultValues] = props;
    return tokens.map((token, idx) => lookupToken({
      token,
      i18nMapping: mapping,
      valueDefault: defaultValues[idx],
      i18nMappingFunc: mappingFunc,
      render
    }));
  }
}
export { EuiI18n, useEuiI18n };
try {
  EuiI18n.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/i18n/i18n.tsx',
    description: '',
    displayName: 'EuiI18n',
    methods: [],
    props: {
      default: {
        defaultValue: null,
        description: '',
        name: 'default',
        parent: {
          fileName: 'eui/src/components/i18n/i18n.tsx',
          name: 'I18nTokenShape'
        },
        declarations: [{
          fileName: 'eui/src/components/i18n/i18n.tsx',
          name: 'I18nTokenShape'
        }],
        required: false,
        type: {
          name: 'Renderable<T>'
        }
      },
      token: {
        defaultValue: null,
        description: '',
        name: 'token',
        parent: {
          fileName: 'eui/src/components/i18n/i18n.tsx',
          name: 'I18nTokenShape'
        },
        declarations: [{
          fileName: 'eui/src/components/i18n/i18n.tsx',
          name: 'I18nTokenShape'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      tokens: {
        defaultValue: null,
        description: '',
        name: 'tokens',
        parent: {
          fileName: 'eui/src/components/i18n/i18n.tsx',
          name: 'I18nTokensShape'
        },
        declarations: [{
          fileName: 'eui/src/components/i18n/i18n.tsx',
          name: 'I18nTokensShape'
        }],
        required: false,
        type: {
          name: 'string[]'
        }
      },
      defaults: {
        defaultValue: null,
        description: '',
        name: 'defaults',
        parent: {
          fileName: 'eui/src/components/i18n/i18n.tsx',
          name: 'I18nTokensShape'
        },
        declarations: [{
          fileName: 'eui/src/components/i18n/i18n.tsx',
          name: 'I18nTokensShape'
        }],
        required: false,
        type: {
          name: 'any[]'
        }
      },
      children: {
        defaultValue: null,
        description: 'Render function that returns a ReactElement',
        name: 'children',
        parent: {
          fileName: 'eui/src/components/i18n/i18n.tsx',
          name: 'I18nTokensShape'
        },
        declarations: [{
          fileName: 'eui/src/components/i18n/i18n.tsx',
          name: 'I18nTokensShape'
        }, {
          fileName: 'eui/src/components/i18n/i18n.tsx',
          name: 'I18nTokenShape'
        }],
        required: true,
        type: {
          name: '(x: T[number][]) => ReactNode'
        }
      },
      values: {
        defaultValue: null,
        description: '',
        name: 'values',
        parent: {
          fileName: 'eui/src/components/i18n/i18n.tsx',
          name: 'I18nTokensShape'
        },
        declarations: [{
          fileName: 'eui/src/components/i18n/i18n.tsx',
          name: 'I18nTokensShape'
        }, {
          fileName: 'eui/src/components/i18n/i18n.tsx',
          name: 'I18nTokenShape'
        }],
        required: false,
        type: {
          name: '{} | Record<string, ReactNode>'
        }
      }
    },
    extendedInterfaces: ['I18nTokenShape', 'I18nTokensShape']
  };
} catch (e) {}