/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useCallback, useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useMouseMove, useEuiTheme, useEuiMemoizedStyles } from '../../../services';
import { logicalStyles } from '../../../global_styling';
import { euiRangeDraggableStyles, euiRangeDraggableInnerStyles } from './range_draggable.styles';
export var EuiRangeDraggable = ({
  className,
  showTicks,
  lowerPosition,
  upperPosition,
  onChange,
  min,
  max,
  disabled,
  value,
  ...rest
}) => {
  const euiTheme = useEuiTheme();
  const outerStyle = useMemo(() => {
    return logicalStyles({
      left: lowerPosition,
      right: `calc(100% - ${upperPosition} - ${euiTheme.euiTheme.size.base})`
    });
  }, [lowerPosition, upperPosition, euiTheme.euiTheme.size.base]);
  const handleChange = useCallback(({
    x
  }, isFirstInteraction) => {
    if (disabled) return;
    onChange(x, isFirstInteraction);
  }, [disabled, onChange]);
  const [handleMouseDown, handleInteraction] = useMouseMove(handleChange);
  const classes = classNames('euiRangeDraggable', className);
  const styles = useEuiMemoizedStyles(euiRangeDraggableStyles);
  const cssStyles = [styles.euiRangeDraggable, showTicks && styles.hasTicks, disabled && styles.disabled];
  const innerStyles = useEuiMemoizedStyles(euiRangeDraggableInnerStyles);
  const cssInnerStyles = [innerStyles.euiRangeDraggable__inner, disabled ? styles.disabled : innerStyles.enabled];
  const commonProps = {
    className: classes,
    css: cssStyles,
    role: 'slider',
    'aria-valuemin': min,
    'aria-valuemax': max,
    'aria-valuenow': Number(value[0]),
    'aria-valuetext': `${value[0]}, ${value[1]}`,
    'aria-disabled': !!disabled,
    tabIndex: !!disabled ? -1 : 0
  };
  return <div style={outerStyle} {...commonProps} {...rest}>
      <div className="euiRangeDraggable__inner" css={cssInnerStyles} onMouseDown={handleMouseDown} onTouchStart={handleInteraction} onTouchMove={handleInteraction} />
    </div>;
};
EuiRangeDraggable.propTypes = {
  lowerPosition: PropTypes.string.isRequired,
  upperPosition: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
try {
  EuiRangeDraggable.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/range/range_draggable.tsx',
    description: '',
    displayName: 'EuiRangeDraggable',
    methods: [],
    props: {
      lowerPosition: {
        defaultValue: null,
        description: '',
        name: 'lowerPosition',
        parent: {
          fileName: 'eui/src/components/form/range/range_draggable.tsx',
          name: 'EuiRangeDraggableProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_draggable.tsx',
          name: 'EuiRangeDraggableProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      upperPosition: {
        defaultValue: null,
        description: '',
        name: 'upperPosition',
        parent: {
          fileName: 'eui/src/components/form/range/range_draggable.tsx',
          name: 'EuiRangeDraggableProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_draggable.tsx',
          name: 'EuiRangeDraggableProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/form/range/range_draggable.tsx',
          name: 'EuiRangeDraggableProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/range_draggable.tsx',
          name: 'EuiRangeDraggableProps'
        }],
        required: true,
        type: {
          name: '(x: number, isFirstInteraction?: boolean) => void'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_DualRangeValue'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_DualRangeValue'
        }],
        required: true,
        type: {
          name: '[string | number, string | number]'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      min: {
        defaultValue: null,
        description: '',
        name: 'min',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeValuesProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      showTicks: {
        defaultValue: null,
        description: 'Shows clickable tick marks and labels at the given interval (`step`/`tickInterval`)',
        name: 'showTicks',
        parent: {
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        },
        declarations: [{
          fileName: 'eui/src/components/form/range/types.ts',
          name: '_SharedRangeVisualConfiguration'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['EuiRangeDraggableProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', '_DualRangeValue', '_SharedRangeValuesProps', 'InputHTMLAttributes', '_SharedRangeVisualConfiguration']
  };
} catch (e) {}