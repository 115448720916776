/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { EuiText } from '../text';
import { EuiInlineEditForm, SMALL_SIZE_FORM, MEDIUM_SIZE_FORM } from './inline_edit_form';
import { euiInlineEditTextStyles } from './inline_edit_text.styles';
export var EuiInlineEditText = ({
  className,
  size = 'm',
  readModeProps: _readModeProps,
  isReadOnly,
  ...rest
}) => {
  const classes = classNames('euiInlineEditText', className);
  const styles = useEuiMemoizedStyles(euiInlineEditTextStyles);
  const cssStyles = [styles.euiInlineEditText, styles.fontSize[size]];
  const isSmallSize = ['xs', 's'].includes(size);
  const sizes = isSmallSize ? SMALL_SIZE_FORM : MEDIUM_SIZE_FORM;
  const readModeProps = useMemo(() => {
    if (!isReadOnly) return _readModeProps;
    return {
      ..._readModeProps,
      role: 'paragraph'
    };
  }, [_readModeProps, isReadOnly]);
  return <EuiInlineEditForm className={classes} css={cssStyles} readModeProps={readModeProps} isReadOnly={isReadOnly} sizes={sizes} {...rest}>
      {textReadModeValue => <EuiText size={size} className="eui-textTruncate">
          {textReadModeValue}
        </EuiText>}
    </EuiInlineEditForm>;
};
EuiInlineEditText.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  placeholder: PropTypes.string,
  /**
       * Callback that fires when a user clicks the save button.
       * Passes the current edited text value as an argument.
       *
       * To validate the value of the edited text, pass back a boolean flag.
       * If `false`, EuiInlineEdit will remain in edit mode, where loading or invalid states can be set.
       * If `true`, EuiInlineEdit will return to read mode.
       */
  onSave: PropTypes.func,
  /**
       * Form label that appears above the form control.
       * This is required for accessibility because there is no visual label on the input.
       */
  inputAriaLabel: PropTypes.string.isRequired,
  /**
       * Starts the component in edit mode
       */
  startWithEditOpen: PropTypes.bool,
  /**
       * Props that will be applied directly to the `EuiEmptyButton` displayed in read mode
       */
  readModeProps: PropTypes.any,
  /**
       * Multiple props objects that can be applied directly to various child components displayed in edit mode.
       * - `formRowProps` will be passed to `EuiFormRow`
       * - `inputProps` will be passed to `EuiFieldText`
       * - `saveButtonProps` & `cancelButtonProps` will be passed to their respective `EuiIconButton`s
       */
  editModeProps: PropTypes.shape({
    formRowProps: PropTypes.any,
    inputProps: PropTypes.any,
    saveButtonProps: PropTypes.any,
    cancelButtonProps: PropTypes.any
  }),
  /**
       * Loading state - only displayed in edit mode
       */
  isLoading: PropTypes.bool,
  /**
       * Invalid state - only displayed edit mode
       */
  isInvalid: PropTypes.bool,
  /**
       * Locks inline edit in read mode and displays the text value
       */
  isReadOnly: PropTypes.bool,
  /**
         * Initial inline edit text value
         */
  defaultValue: PropTypes.string,
  /**
         * To use inline edit as a controlled component, continuously pass the value via this prop
         */
  value: PropTypes.string,
  /**
         * Callback required to receive and update `value` based on user input
         */
  onChange: PropTypes.func,
  /**
         * Callback required to reset `value` to the previous read mode text value.
         */
  onCancel: PropTypes.func,
  /**
     * Text size level
     */
  size: PropTypes.any
};
try {
  EuiInlineEditText.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/inline_edit/inline_edit_text.tsx',
    description: '',
    displayName: 'EuiInlineEditText',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      placeholder: {
        defaultValue: null,
        description: '',
        name: 'placeholder',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onSave: {
        defaultValue: null,
        description: 'Callback that fires when a user clicks the save button.\n' + 'Passes the current edited text value as an argument.\n' + '\n' + 'To validate the value of the edited text, pass back a boolean flag.\n' + 'If `false`, EuiInlineEdit will remain in edit mode, where loading or invalid states can be set.\n' + 'If `true`, EuiInlineEdit will return to read mode.',
        name: 'onSave',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(value: string) => boolean | void | Promise<boolean | void>'
        }
      },
      inputAriaLabel: {
        defaultValue: null,
        description: 'Form label that appears above the form control.\n' + 'This is required for accessibility because there is no visual label on the input.',
        name: 'inputAriaLabel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      startWithEditOpen: {
        defaultValue: null,
        description: 'Starts the component in edit mode',
        name: 'startWithEditOpen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      readModeProps: {
        defaultValue: null,
        description: 'Props that will be applied directly to the `EuiEmptyButton` displayed in read mode',
        name: 'readModeProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<EuiButtonEmptyPropsForButton>'
        }
      },
      editModeProps: {
        defaultValue: null,
        description: 'Multiple props objects that can be applied directly to various child components displayed in edit mode.\n' + '- `formRowProps` will be passed to `EuiFormRow`\n' + '- `inputProps` will be passed to `EuiFieldText`\n' + '- `saveButtonProps` & `cancelButtonProps` will be passed to their respective `EuiIconButton`s',
        name: 'editModeProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '{ formRowProps?: Partial<(DisambiguateSet<LabelProps, LegendProps> & { labelType?: "legend"; } & CommonProps & { display?: "center" | "row" | "columnCompressed" | "centerCompressed" | "rowCompressed"; ... 11 more ...; isDisabled?: boolean; } & Omit<...>) | (DisambiguateSet<...> & ... 3 more ... & HTMLAttributes<...>...'
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Loading state - only displayed in edit mode',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: 'Invalid state - only displayed edit mode',
        name: 'isInvalid',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isReadOnly: {
        defaultValue: null,
        description: 'Locks inline edit in read mode and displays the text value',
        name: 'isReadOnly',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      value: {
        defaultValue: null,
        description: 'To use inline edit as a controlled component, continuously pass the value via this prop',
        name: 'value',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onCancel: {
        defaultValue: null,
        description: 'Callback required to reset `value` to the previous read mode text value.',
        name: 'onCancel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_form.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(perviousValue: string) => void'
        }
      },
      size: {
        defaultValue: {
          value: 'm'
        },
        description: 'Text size level',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/inline_edit/inline_edit_text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'EuiInlineEditTextSizes',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }]
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}