import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useRouteMatch } from 'react-router-dom';
import { EuiSpacer, EuiErrorBoundary, EuiButton, EuiButtonEmpty, EuiFlyout, EuiPageSection, useIsWithinBreakpoints } from '../../../../src';
import { slugify } from '../../../../src/services/string/slugify';

// @ts-ignore Not TS yet
import playgroundService from '../../services/playground/playground';
import { GuideSectionExample } from './guide_section_parts/guide_section_example';
import { GuideSectionExampleText } from './guide_section_parts/guide_section_text';
import { GuideSectionExampleTabs } from './guide_section_parts/guide_section_tabs';
import classNames from 'classnames';
export var GuideSectionCodeTypesMap = {
  JS: {
    name: 'demoJS',
    displayName: 'Demo JS'
  },
  TSX: {
    name: 'demoTSX',
    displayName: 'Demo TS'
  },
  STRING_JS: {
    name: 'demoJS',
    displayName: 'Demo JS'
  },
  SNIPPET: {
    name: 'snippet',
    displayName: 'Snippet'
  },
  SASS: {
    name: 'sass',
    displayName: 'Sass'
  },
  PROPS: {
    name: 'props',
    displayName: 'Props'
  }
};
export var GuideSection = ({
  id,
  title,
  isBeta,
  isNew,
  text,
  demo,
  fullScreen,
  source = [],
  props = {},
  playground,
  ghostBackground,
  wrapText = true,
  demoPanelProps,
  exampleToggles,
  snippet,
  color,
  children,
  nested,
  className,
  sectionProps
}) => {
  const {
    path
  } = useRouteMatch();
  const isLargeBreakpoint = useIsWithinBreakpoints(['m', 'l', 'xl']);
  const [renderingPlayground, setRenderingPlayground] = useState(false);
  const renderTabs = () => {
    const hasSnippet = !!snippet;

    // Don't duplicate in case this function is run multiple times
    if (hasSnippet && !source.find(tab => tab.name === 'snippet')) {
      source.push({
        ...GuideSectionCodeTypesMap.SNIPPET,
        snippets: snippet,
        displayName: `${GuideSectionCodeTypesMap.SNIPPET.displayName}${Array.isArray(snippet) ? 's' : ''}`
      });
    }
    const hasPropsTabAlready = source.find(tab => tab.name === 'props');
    if (Object.keys(props).length && !hasPropsTabAlready // Don't duplicate in case this function is run multiple times
    ) {
      source.push({
        ...GuideSectionCodeTypesMap.PROPS,
        props: props
      });
    }
    const tabs = [];
    if (source) {
      source.map(source => {
        tabs.push({
          // @ts-ignore Complicated
          ...GuideSectionCodeTypesMap[source.type],
          // Make sure the `name` is unique in case there are multiple source languages
          name: source.displayName ? slugify(source.displayName) :
          // @ts-ignore Complicated
          GuideSectionCodeTypesMap[source.type].name,
          ...source
        });
      });
    }
    const playgroundToggle = renderPlaygroundToggle();
    return tabs.length || playgroundToggle ? <GuideSectionExampleTabs tabs={tabs} rightSideControl={playgroundToggle} /> : undefined;
  };
  const renderPlaygroundToggle = () => {
    const isPlaygroundUnsupported = typeof window !== 'undefined' && typeof document !== 'undefined' && !!window.MSInputMethodContext &&
    // @ts-ignore doesn't exist?
    !!document.documentMode;
    if (!isPlaygroundUnsupported && !!playground) {
      return <EuiButtonEmpty size="xs" iconType="controls" onClick={() => {
        setRenderingPlayground(rendering => !rendering);
      }}>
          Playground
        </EuiButtonEmpty>;
    }
  };
  const renderPlayground = () => {
    const {
      config,
      setGhostBackground,
      playgroundClassName,
      playgroundCssStyles,
      playgroundPanelProps
    } = playground();
    return playgroundService({
      config,
      setGhostBackground,
      playgroundClassName,
      playgroundCssStyles,
      playgroundPanelProps,
      playgroundToggle: renderPlaygroundToggle(),
      tabs: renderTabs()
    });
  };
  return <EuiPageSection color={!isLargeBreakpoint ? 'transparent' : color || 'transparent'} paddingSize={nested ? 'none' : 'l'} restrictWidth {...sectionProps} id={title ? undefined : id} // Prefer setting the ID on titles, if present
  className={classNames('guideSection', className)}>
      <EuiSpacer size={(color || title) && isLargeBreakpoint ? 'xxl' : 'xs'} />
      <GuideSectionExampleText title={title} id={id} isBeta={isBeta} isNew={isNew} wrapText={wrapText}>
        {text}
      </GuideSectionExampleText>

      {renderingPlayground && <EuiFlyout onClose={() => setRenderingPlayground(false)} size="l" paddingSize="none" closeButtonPosition="outside">
          {renderPlayground()}
        </EuiFlyout>}
      {(demo || fullScreen && fullScreen.showButton !== false) && <>
          {(nested || text) && <EuiSpacer />}
          <GuideSectionExample example={<EuiErrorBoundary>
                {fullScreen == null ? <div>{demo}</div> : demo == null ? <EuiButton iconType="fullScreen" href={`#${path}/${fullScreen.slug}`}>
                    Open demo
                  </EuiButton> : demo}
              </EuiErrorBoundary>} tabs={renderTabs()} ghostBackground={ghostBackground} demoPanelProps={demoPanelProps} exampleToggles={exampleToggles} />
        </>}

      {children}
      <EuiSpacer size={color && isLargeBreakpoint ? 'xxl' : 'xs'} />
    </EuiPageSection>;
};
GuideSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  isBeta: PropTypes.bool,
  isNew: PropTypes.bool,
  text: PropTypes.node,
  source: PropTypes.arrayOf(PropTypes.any.isRequired),
  demo: PropTypes.node,
  fullScreen: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    demo: PropTypes.node.isRequired,
    showButton: PropTypes.bool
  }),
  props: PropTypes.any,
  playground: PropTypes.any,
  wrapText: PropTypes.bool,
  snippet: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired).isRequired]),
  color: PropTypes.any,
  children: PropTypes.node,
  nested: PropTypes.bool,
  sectionProps: PropTypes.any
};
try {
  GuideSection.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/components/guide_section/guide_section.tsx',
    description: '',
    displayName: 'GuideSection',
    methods: [],
    props: {
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isBeta: {
        defaultValue: null,
        description: '',
        name: 'isBeta',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isNew: {
        defaultValue: null,
        description: '',
        name: 'isNew',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      text: {
        defaultValue: null,
        description: '',
        name: 'text',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      source: {
        defaultValue: {
          value: '[]'
        },
        description: '',
        name: 'source',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: 'any[]'
        }
      },
      demo: {
        defaultValue: null,
        description: '',
        name: 'demo',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      fullScreen: {
        defaultValue: null,
        description: '',
        name: 'fullScreen',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: '{ slug: string; demo: ReactNode; showButton?: boolean; }'
        }
      },
      props: {
        defaultValue: {
          value: '{}'
        },
        description: '',
        name: 'props',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: 'object'
        }
      },
      playground: {
        defaultValue: null,
        description: '',
        name: 'playground',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      wrapText: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'wrapText',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      snippet: {
        defaultValue: null,
        description: '',
        name: 'snippet',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: 'string | string[]'
        }
      },
      color: {
        defaultValue: null,
        description: '',
        name: 'color',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'PanelColor',
          value: [{
            value: '"transparent"'
          }, {
            value: '"accent"'
          }, {
            value: '"accentSecondary"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"subdued"'
          }, {
            value: '"plain"'
          }, {
            value: '"highlighted"'
          }]
        }
      },
      nested: {
        defaultValue: null,
        description: '',
        name: 'nested',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      sectionProps: {
        defaultValue: null,
        description: '',
        name: 'sectionProps',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section.tsx',
          name: 'GuideSectionProps'
        }],
        required: false,
        type: {
          name: 'EuiPageSectionProps'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      exampleToggles: {
        defaultValue: null,
        description: 'Creates another inner split panel containing an array of custom controls',
        name: 'exampleToggles',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      demoPanelProps: {
        defaultValue: null,
        description: '',
        name: 'demoPanelProps',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLDivElement> & Omit<_EuiPanelProps, "hasBorder" | "hasShadow" | "borderRadius">'
        }
      },
      ghostBackground: {
        defaultValue: null,
        description: '',
        name: 'ghostBackground',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['GuideSectionProps', 'CommonProps', 'GuideSectionExample']
  };
} catch (e) {}