/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { forwardRef } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keys, useEuiMemoizedStyles } from '../../services';
import { euiTabsStyles } from './tabs.styles';
import { EuiTabsContext } from './tabs_context';
export var SIZES = ['s', 'm', 'l', 'xl'];
export var EuiTabs = forwardRef(({
  children,
  className,
  bottomBorder = true,
  expand = false,
  size = 'm',
  ...rest
}, ref) => {
  const classes = classNames('euiTabs', className);
  const styles = useEuiMemoizedStyles(euiTabsStyles);
  const cssStyles = [styles.euiTabs, styles[size], bottomBorder && styles.bottomBorder];
  const handleKeyDown = event => {
    const tablist = event.currentTarget;
    const tabs = tablist?.querySelectorAll('[role="tab"]:not(:disabled, [inert])');
    if (!tabs?.length) return;
    const currentIndex = Array.from(tabs).findIndex(tab => tab.matches(':focus'));
    if (event.key === keys.ARROW_LEFT) {
      const previousIndex = (currentIndex === 0 ? tabs.length : currentIndex) - 1;
      tabs[previousIndex].focus();
    } else if (event.key === keys.ARROW_RIGHT) {
      const nextIndex = currentIndex === tabs.length - 1 ? 0 : currentIndex + 1;
      tabs[nextIndex].focus();
    }
  };
  return <div ref={ref} className={classes} css={cssStyles} onKeyDown={handleKeyDown} {...children && {
    role: 'tablist'
  }} {...rest}>
        <EuiTabsContext.Provider value={{
      expand,
      size
    }}>
          {children}
        </EuiTabsContext.Provider>
      </div>;
});
EuiTabs.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * ReactNode to render as this component's content
       */
  children: PropTypes.node,
  /**
       * Evenly stretches each tab to fill the
       * horizontal space
       */
  expand: PropTypes.bool,
  /**
       * Adds a bottom border to separate it from the content after
       */
  bottomBorder: PropTypes.bool,
  /**
       * Sizes affect both font size and overall size.
       * Only use the `xl` size when displayed as page titles.
       */
  size: PropTypes.any
};
EuiTabs.displayName = 'EuiTabs';
try {
  EuiTabs.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/tabs/tabs.tsx',
    description: '',
    displayName: 'EuiTabs',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/tabs/tabs.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      expand: {
        defaultValue: {
          value: 'false'
        },
        description: 'Evenly stretches each tab to fill the\nhorizontal space',
        name: 'expand',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tabs/tabs.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      bottomBorder: {
        defaultValue: {
          value: 'true'
        },
        description: 'Adds a bottom border to separate it from the content after',
        name: 'bottomBorder',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tabs/tabs.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      size: {
        defaultValue: {
          value: 'm'
        },
        description: 'Sizes affect both font size and overall size.\n' + 'Only use the `xl` size when displayed as page titles.',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tabs/tabs.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<HTMLDivElement>'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', 'Attributes']
  };
} catch (e) {}