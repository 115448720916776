import React, { useState } from 'react';
import PropTypes from "prop-types";
import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiRange, EuiToolTip, EuiIcon, EuiSwitch, EuiSpacer } from '../../../../../src/components';

// @ts-ignore Importing from JS
import { ratingAAA, ratingAA18, ratingAA, ratingAll } from './_contrast_utilities';
export var ContrastSlider = ({
  contrastValue,
  showTextVariants,
  onChange,
  ...rest
}) => {
  const [value, setValue] = useState(contrastValue);
  const [checked, setChecked] = useState(showTextVariants);
  const ticks = [{
    value: 0,
    label: <EuiToolTip position="bottom" content={<ul>
              <li>
                <EuiIcon type="minusInCircle" /> Contrast is between 2 and 3.
                Use only for disabled or inconsequential content.
              </li>
              <li>
                <EuiIcon type="cross" /> Contrast is less than 2. Do not use.
              </li>
            </ul>}>
          {ratingAll}
        </EuiToolTip>
  }, {
    value: 3,
    label: <EuiToolTip position="bottom" content={<p>
              <EuiIcon type="partial" /> Partially passes with a contrast of 3+,
              but only for graphics or if the text is at least 18px, or 14px and
              bold
            </p>}>
          {ratingAA18}
        </EuiToolTip>
  }, {
    value: 4.5,
    label: <EuiToolTip position="bottom" content={<p>
              <EuiIcon type="checkInCircleFilled" /> Passes with a contrast of
              4.5+
            </p>}>
          {ratingAA}
        </EuiToolTip>
  }, {
    value: 7,
    label: <EuiToolTip position="bottom" content={<p>
              <EuiIcon type="checkInCircleFilled" /> Passes with a contrast of
              7+
            </p>}>
          {ratingAAA}
        </EuiToolTip>
  }];
  return <EuiFlexGroup gutterSize="xl" justifyContent="center" {...rest}>
      <EuiFlexItem>
        <EuiFormRow id="ratingsRange" label="Minimum color contrast combinations to show" fullWidth>
          <EuiRange min={0} max={7} step={0.5} value={value} onChange={e => {
          setValue(e.currentTarget.value);
          // @ts-ignore Help
          onChange(e.currentTarget.value, checked);
        }} showTicks ticks={ticks} valueAppend="+" fullWidth />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFormRow labelType="legend" label="Show text variant versions of core color tokens as the foreground" hasChildLabel={false}>
          <div>
            <EuiSpacer size="s" />
            <EuiSwitch label="Show text variant" checked={showTextVariants} onChange={e => {
            setChecked(e.target.checked);
            // @ts-ignore Help
            onChange(value, e.target.checked);
          }} />
          </div>
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>;
};
ContrastSlider.propTypes = {
  contrastValue: PropTypes.any.isRequired,
  showTextVariants: PropTypes.bool.isRequired,
  onChange: PropTypes.func
};
try {
  ContrastSlider.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/color/_contrast_slider.tsx',
    description: '',
    displayName: 'ContrastSlider',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      direction: {
        defaultValue: null,
        description: '',
        name: 'direction',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/flex/flex_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"row" | "column" | "rowReverse" | "columnReverse"',
          value: [{
            value: '"row"'
          }, {
            value: '"column"'
          }, {
            value: '"rowReverse"'
          }, {
            value: '"columnReverse"'
          }]
        }
      },
      gutterSize: {
        defaultValue: null,
        description: '',
        name: 'gutterSize',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/flex/flex_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      responsive: {
        defaultValue: null,
        description: '',
        name: 'responsive',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/flex/flex_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      alignItems: {
        defaultValue: null,
        description: '',
        name: 'alignItems',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/flex/flex_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "baseline" | "stretch" | "flexStart" | "flexEnd"',
          value: [{
            value: '"center"'
          }, {
            value: '"baseline"'
          }, {
            value: '"stretch"'
          }, {
            value: '"flexStart"'
          }, {
            value: '"flexEnd"'
          }]
        }
      },
      component: {
        defaultValue: {
          value: '"div"'
        },
        description: 'Customize the component type that is rendered.\n' + '\n' + 'It can be any valid React component type like a tag name string\n' + "such as `'div'` or `'span'`, a React component (a function, a class,\n" + 'or an exotic component like `memo()`).\n' + '\n' + '`EuiFlexGroup` accepts and forwards all extra props to the custom\n' + 'component.',
        name: 'component',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/flex/flex_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '"div"'
        }
      },
      justifyContent: {
        defaultValue: null,
        description: '',
        name: 'justifyContent',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/flex/flex_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "flexStart" | "flexEnd" | "spaceBetween" | "spaceAround" | "spaceEvenly"',
          value: [{
            value: '"center"'
          }, {
            value: '"flexStart"'
          }, {
            value: '"flexEnd"'
          }, {
            value: '"spaceBetween"'
          }, {
            value: '"spaceAround"'
          }, {
            value: '"spaceEvenly"'
          }]
        }
      },
      wrap: {
        defaultValue: null,
        description: '',
        name: 'wrap',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/flex/flex_group.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      contrastValue: {
        defaultValue: null,
        description: '',
        name: 'contrastValue',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/color/_contrast_slider.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string | number'
        }
      },
      showTextVariants: {
        defaultValue: null,
        description: '',
        name: 'showTextVariants',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/color/_contrast_slider.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/color/_contrast_slider.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(value: string | number, checked: boolean) => void'
        }
      }
    },
    extendedInterfaces: ['HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'CommonProps', 'Attributes']
  };
} catch (e) {}