import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiSplitPanel } from '../../../../../src/components/panel';
import { EuiHorizontalRule, useEuiTheme } from '../../../../../src';
export var GuideSectionExample = ({
  example,
  tabs,
  ghostBackground = false,
  tabContent,
  demoPanelProps,
  exampleToggles
}) => {
  const classes = classNames(demoPanelProps?.className, {
    guideDemo__ghostBackground: ghostBackground
  });
  const {
    highContrastMode
  } = useEuiTheme();
  return <EuiSplitPanel.Outer hasBorder hasShadow={false} data-eui-docs-example>
      <EuiSplitPanel.Inner color="plain" {...demoPanelProps} className={classes}>
        {example}
      </EuiSplitPanel.Inner>
      {exampleToggles && !highContrastMode && <EuiHorizontalRule margin="none" />}
      {exampleToggles && <EuiSplitPanel.Inner paddingSize="m" color="subdued">
          {exampleToggles}
        </EuiSplitPanel.Inner>}
      {exampleToggles && tabs && !highContrastMode && <EuiHorizontalRule margin="none" />}
      {(tabs || tabContent) && <EuiSplitPanel.Inner paddingSize="none" color="subdued">
          {tabs}
          {tabContent}
        </EuiSplitPanel.Inner>}
    </EuiSplitPanel.Outer>;
};
GuideSectionExample.propTypes = {
  example: PropTypes.node.isRequired,
  tabs: PropTypes.node,
  /** Forces display of a certain content (playground props table) */tabContent: PropTypes.node,
  ghostBackground: PropTypes.bool,
  demoPanelProps: PropTypes.shape({
    /**
       * Padding for all four sides
       */
    paddingSize: PropTypes.any,
    /**
       * When true the panel will grow in height to match `EuiFlexItem`
       */
    grow: PropTypes.bool,
    panelRef: PropTypes.any,
    /**
       * Background color of the panel;
       * Usually a lightened form of the brand colors
       */
    color: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.oneOf(["highlighted"])]),
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  /** Creates another inner split panel containing an array of custom controls */exampleToggles: PropTypes.any
};
try {
  GuideSectionExample.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
    description: '',
    displayName: 'GuideSectionExample',
    methods: [],
    props: {
      example: {
        defaultValue: null,
        description: '',
        name: 'example',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      tabs: {
        defaultValue: null,
        description: '',
        name: 'tabs',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      tabContent: {
        defaultValue: null,
        description: 'Forces display of a certain content (playground props table)',
        name: 'tabContent',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      ghostBackground: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'ghostBackground',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      demoPanelProps: {
        defaultValue: null,
        description: '',
        name: 'demoPanelProps',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        }],
        required: false,
        type: {
          name: 'HTMLAttributes<HTMLDivElement> & Omit<_EuiPanelProps, "hasBorder" | "hasShadow" | "borderRadius">'
        }
      },
      exampleToggles: {
        defaultValue: null,
        description: 'Creates another inner split panel containing an array of custom controls',
        name: 'exampleToggles',
        parent: {
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        },
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_example.tsx',
          name: 'GuideSectionExample'
        }],
        required: false,
        type: {
          name: 'any'
        }
      }
    },
    extendedInterfaces: ['GuideSectionExample']
  };
} catch (e) {}