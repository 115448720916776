/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import classNames from 'classnames';
import PropTypes from "prop-types";
import React from 'react';
import { EuiScreenReaderOnly } from '../accessibility';
import { EuiIcon } from '../icon';
import { EuiLoadingSpinner } from '../loading';
import { useEuiMemoizedStyles } from '../../services';
import { useI18nCompleteStep, useI18nDisabledStep, useI18nErrorsStep, useI18nIncompleteStep, useI18nStep, useI18nWarningStep, useI18nLoadingStep, useI18nCurrentStep } from './step_strings';
import { euiStepNumberStyles, euiStepNumberContentStyles } from './step_number.styles';
export var STATUS = ['incomplete', 'disabled', 'loading', 'warning', 'danger', 'complete', 'current'];
export var EuiStepNumber = ({
  className,
  status,
  number,
  titleSize = 's',
  ...rest
}) => {
  const ariaLabelsMap = {
    step: useI18nStep({
      number
    }),
    current: useI18nCurrentStep({
      number
    }),
    incomplete: useI18nIncompleteStep({
      number
    }),
    complete: useI18nCompleteStep({
      number
    }),
    disabled: useI18nDisabledStep({
      number
    }),
    warning: useI18nWarningStep({
      number
    }),
    danger: useI18nErrorsStep({
      number
    }),
    loading: useI18nLoadingStep({
      number
    })
  };
  const classes = classNames('euiStepNumber', className);
  const styles = useEuiMemoizedStyles(euiStepNumberStyles);
  const cssStyles = [styles.euiStepNumber, styles[titleSize], status && styles[status]];
  const iconStyles = useEuiMemoizedStyles(euiStepNumberContentStyles);
  let content;
  let screenReaderText;
  switch (status) {
    // Loading spinner
    case 'loading':
      {
        const iconSizeMap = {
          none: 'm',
          xs: 'l',
          s: 'xl',
          m: 'xl'
        };
        screenReaderText = ariaLabelsMap.loading;
        content = <EuiLoadingSpinner className="euiStepNumber__loader" size={iconSizeMap[titleSize]} />;
        break;
      }
    // Statuses with icons
    case 'danger':
    case 'warning':
    case 'complete':
      {
        const cssIconStyles = [iconStyles.euiStepNumber__icon, status === 'warning' ? iconStyles.warning[titleSize] : iconStyles[status],
        // EuiIcon does not support a xxs size so far,
        // we use custom sizing here instead
        titleSize === 'none' && iconStyles[titleSize]];
        const iconTypeMap = {
          danger: 'cross',
          warning: 'warning',
          complete: 'check'
        };
        const iconSizeMap = {
          xxs: 's',
          xs: 's',
          s: 'm',
          m: 'm'
        };
        content = <EuiIcon type={iconTypeMap[status]} aria-label={ariaLabelsMap[status]} size={iconSizeMap[titleSize]} className="euiStepNumber__icon" css={cssIconStyles} />;
        break;
      }
    // Statuses with numbers
    case 'incomplete':
    case 'current':
    case 'disabled':
    default:
      screenReaderText = ariaLabelsMap[status || 'step'];
      if (titleSize === 'none') {
        break;
      }
      content = <span aria-hidden="true" className="euiStepNumber__number">
          {number}
        </span>;
      break;
  }
  return <span className={classes} css={cssStyles} {...rest}>
      {screenReaderText && <EuiScreenReaderOnly>
          <span>{screenReaderText}</span>
        </EuiScreenReaderOnly>}
      {content}
    </span>;
};
EuiStepNumber.propTypes = {
  /**
     * May replace the number provided in props.number with alternate styling
     */
  status: PropTypes.any,
  number: PropTypes.number,
  /**
     * Title sizing equivalent to EuiTitle, but only `m`, `s`, `xs`.
     * `none` indicates no step number should be rendered.
     * @default s
     */
  titleSize: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.oneOf(["none"])]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiStepNumber.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/steps/step_number.tsx',
    description: '',
    displayName: 'EuiStepNumber',
    methods: [],
    props: {
      status: {
        defaultValue: null,
        description: 'May replace the number provided in props.number with alternate styling',
        name: 'status',
        parent: {
          fileName: 'eui/src/components/steps/step_number.tsx',
          name: 'EuiStepNumberProps'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/step_number.tsx',
          name: 'EuiStepNumberProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"warning" | "danger" | "disabled" | "current" | "loading" | "incomplete" | "complete"',
          value: [{
            value: '"warning"'
          }, {
            value: '"danger"'
          }, {
            value: '"disabled"'
          }, {
            value: '"current"'
          }, {
            value: '"loading"'
          }, {
            value: '"incomplete"'
          }, {
            value: '"complete"'
          }]
        }
      },
      number: {
        defaultValue: null,
        description: '',
        name: 'number',
        parent: {
          fileName: 'eui/src/components/steps/step_number.tsx',
          name: 'EuiStepNumberProps'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/step_number.tsx',
          name: 'EuiStepNumberProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      titleSize: {
        defaultValue: {
          value: 's'
        },
        description: 'Title sizing equivalent to EuiTitle, but only `m`, `s`, `xs`.\n' + '`none` indicates no step number should be rendered.',
        name: 'titleSize',
        parent: {
          fileName: 'eui/src/components/steps/step_number.tsx',
          name: 'EuiStepNumberProps'
        },
        declarations: [{
          fileName: 'eui/src/components/steps/step_number.tsx',
          name: 'EuiStepNumberProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "xs"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }]
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiStepNumberProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}