/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useCallback, useMemo } from 'react';
import PropTypes from "prop-types";
import { EuiSpacer } from '../../spacer';
import { EuiText } from '../../text';
import { EuiSuperSelect } from '../../form/super_select'; // Note: needs to be pointed at this specific subdir for Storybook to inherit types correctly??

import { EuiColorPaletteDisplay } from '../color_palette_display';
import { EuiFlexGroup, EuiFlexItem } from '../../flex';
export var EuiColorPalettePicker = ({
  className,
  compressed = false,
  disabled,
  fullWidth = false,
  isInvalid = false,
  onChange,
  readOnly = false,
  valueOfSelected,
  palettes,
  append,
  prepend,
  selectionDisplay = 'palette',
  ...rest
}) => {
  const getPalette = useCallback(({
    type,
    palette,
    title
  }) => {
    return <EuiColorPaletteDisplay type={type} palette={palette} title={title} />;
  }, []);
  const paletteOptions = useMemo(() => palettes.map(item => {
    const {
      type,
      value,
      title,
      append,
      palette,
      ...rest
    } = item;
    const paletteForDisplay = item.type !== 'text' ? getPalette(item) : null;
    return {
      value: String(value),
      inputDisplay: selectionDisplay === 'title' || type === 'text' ? title : paletteForDisplay,
      dropdownDisplay: <div className="euiColorPalettePicker__item">
              {title && type !== 'text' &&
        // Accessible labels are managed by color_palette_display_fixed and
        // color_palette_display_gradient. Adding the aria-hidden attribute
        // here to ensure screen readers don't speak the listbox options twice.
        <>
                  <EuiFlexGroup responsive={false}>
                    <EuiFlexItem>
                      <EuiText aria-hidden="true" className="euiColorPalettePicker__itemTitle" size="xs">
                        {title}
                      </EuiText>
                    </EuiFlexItem>
                    {append && <EuiFlexItem grow={0}>{append}</EuiFlexItem>}
                  </EuiFlexGroup>
                  <EuiSpacer size="xs" />
                </>}
              {type === 'text' ? title : paletteForDisplay}
            </div>,
      ...rest
    };
  }), [getPalette, palettes, selectionDisplay]);
  return <EuiSuperSelect className={className} options={paletteOptions} valueOfSelected={valueOfSelected} onChange={onChange} hasDividers isInvalid={isInvalid} compressed={compressed} disabled={disabled} readOnly={readOnly} fullWidth={fullWidth} append={append} prepend={prepend} {...rest} />;
};
EuiColorPalettePicker.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  buttonRef: PropTypes.any,
  /**
     * @default false
     */
  compressed: PropTypes.bool,
  /**
     * Expand to fill 100% of the parent.
     * Defaults to `fullWidth` prop of `<EuiForm>`.
     * @default false
     */
  fullWidth: PropTypes.bool,
  /**
     * @default false
     */
  isInvalid: PropTypes.bool,
  /**
     * @default false
     */
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  /**
       * Placeholder to display when the current selected value is empty.
       */
  placeholder: PropTypes.node,
  /**
     * Creates an input group with element(s) coming before input.
     * `string` | `ReactElement` or an array of these
     */
  prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  /**
     * Creates an input group with element(s) coming after input.
     * `string` | `ReactElement` or an array of these
     */
  append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  valueOfSelected: PropTypes.any,
  /**
       * Classes for the context menu item
       */
  itemClassName: PropTypes.string,
  /**
       * You must pass an `onChange` function to handle the update of the value
       */
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  /**
       * Controls whether the options are shown. Default: false
       */
  isOpen: PropTypes.bool,
  /**
       * Optional props to pass to the underlying [EuiInputPopover](/#/layout/popover#popover-attached-to-input-element).
       * Allows fine-grained control of the popover dropdown menu, including
       * `repositionOnScroll` for EuiSuperSelects used within scrollable containers,
       * and customizing popover panel styling.
       *
       * Does not accept a nested `popoverProps.isOpen` property - use the top level
       * `isOpen` API instead.
       */
  popoverProps: PropTypes.any,
  /**
       *  Specify what should be displayed after a selection: a `palette` or `title`
       */
  selectionDisplay: PropTypes.oneOf(["palette", "title"]),
  /**
       * An array of one of the following objects: #EuiColorPalettePickerPaletteText, #EuiColorPalettePickerPaletteFixed, #EuiColorPalettePickerPaletteGradient
       */
  palettes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({
    /**
       *  For storing unique value of item
       */
    value: PropTypes.string.isRequired,
    /**
       *  The name of your palette
       */
    title: PropTypes.string.isRequired,
    /**
       * `text`: a text only option (a title is required).
       */
    type: PropTypes.oneOf(["text"]).isRequired,
    /**
       * Array of color `strings` or an array of #PaletteColorStop. The stops must be numbers in an ordered range.
       */
    palette: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string.isRequired).isRequired, PropTypes.arrayOf(PropTypes.shape({
      stop: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired
    }).isRequired).isRequired]),
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }).isRequired, PropTypes.shape({
    /**
       *  For storing unique value of item
       */
    value: PropTypes.string.isRequired,
    /**
       *  The name of your palette
       */
    title: PropTypes.string,
    /**
       * Node appended to right of title
       */
    append: PropTypes.node,
    /**
       * `fixed`: individual color blocks
       */
    type: PropTypes.oneOf(["fixed"]).isRequired,
    /**
       * Array of color `strings` or an array of #PaletteColorStop. The stops must be numbers in an ordered range.
       */
    palette: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string.isRequired).isRequired, PropTypes.arrayOf(PropTypes.shape({
      stop: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired
    }).isRequired).isRequired]).isRequired,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }).isRequired, PropTypes.shape({
    /**
       *  For storing unique value of item
       */
    value: PropTypes.string.isRequired,
    /**
       *  The name of your palette
       */
    title: PropTypes.string,
    /**
       * Node appended to right of title
       */
    append: PropTypes.node,
    /**
       * `gradient`: each color fades into the next
       */
    type: PropTypes.oneOf(["gradient"]).isRequired,
    /**
       * Array of color `strings` or an array of #PaletteColorStop. The stops must be numbers in an ordered range.
       */
    palette: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string.isRequired).isRequired, PropTypes.arrayOf(PropTypes.shape({
      stop: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired
    }).isRequired).isRequired]).isRequired,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }).isRequired]).isRequired).isRequired
};
try {
  EuiColorPalettePicker.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/color_picker/color_palette_picker/color_palette_picker.tsx',
    description: '',
    displayName: 'EuiColorPalettePicker',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      onFocus: {
        defaultValue: null,
        description: '',
        name: 'onFocus',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(event?: FocusEvent<Element, Element>) => void'
        }
      },
      onBlur: {
        defaultValue: null,
        description: '',
        name: 'onBlur',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(event?: FocusEvent<Element, Element>) => void'
        }
      },
      onChange: {
        defaultValue: null,
        description: 'You must pass an `onChange` function to handle the update of the value',
        name: 'onChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(value: string) => void'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: 'Expand to fill 100% of the parent.\n' + 'Defaults to `fullWidth` prop of `<EuiForm>`.',
        name: 'fullWidth',
        parent: {
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: {
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLButtonElement>'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      placeholder: {
        defaultValue: null,
        description: 'Placeholder to display when the current selected value is empty.',
        name: 'placeholder',
        parent: {
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        }, {
          fileName: 'eui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      readOnly: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'readOnly',
        parent: {
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isOpen: {
        defaultValue: null,
        description: 'Controls whether the options are shown. Default: false',
        name: 'isOpen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select_control.tsx',
          name: 'EuiSuperSelectControlProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      valueOfSelected: {
        defaultValue: null,
        description: '',
        name: 'valueOfSelected',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      itemClassName: {
        defaultValue: null,
        description: 'Classes for the context menu item',
        name: 'itemClassName',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      popoverProps: {
        defaultValue: null,
        description: 'Optional props to pass to the underlying [EuiInputPopover](/#/layout/popover#popover-attached-to-input-element).\n' + 'Allows fine-grained control of the popover dropdown menu, including\n' + '`repositionOnScroll` for EuiSuperSelects used within scrollable containers,\n' + 'and customizing popover panel styling.\n' + '\n' + 'Does not accept a nested `popoverProps.isOpen` property - use the top level\n' + '`isOpen` API instead.',
        name: 'popoverProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/super_select/super_select.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<CommonProps & Omit<EuiInputPopoverProps, "isOpen">>'
        }
      },
      selectionDisplay: {
        defaultValue: {
          value: 'palette'
        },
        description: 'Specify what should be displayed after a selection: a `palette` or `title`',
        name: 'selectionDisplay',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_palette_picker/color_palette_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"title" | "palette"',
          value: [{
            value: '"title"'
          }, {
            value: '"palette"'
          }]
        }
      },
      palettes: {
        defaultValue: null,
        description: 'An array of one of the following objects: #EuiColorPalettePickerPaletteText, #EuiColorPalettePickerPaletteFixed, #EuiColorPalettePickerPaletteGradient',
        name: 'palettes',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_palette_picker/color_palette_picker.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'EuiColorPalettePickerPaletteProps[]'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'EuiSuperSelectControlProps', 'ButtonHTMLAttributes']
  };
} catch (e) {}