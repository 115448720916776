const _excluded = ["applyTime", "buttonProps"],
  _excluded2 = ["contentProps", "onClick"];
function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function (n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
function _objectWithoutProperties(e, t) {
  if (null == e) return {};
  var o,
    r,
    i = _objectWithoutPropertiesLoose(e, t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);
  }
  return i;
}
function _objectWithoutPropertiesLoose(r, e) {
  if (null == r) return {};
  var t = {};
  for (var n in r) if ({}.hasOwnProperty.call(r, n)) {
    if (e.indexOf(n) >= 0) continue;
    t[n] = r[n];
  }
  return t;
}
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from "prop-types";
import { useEuiMemoizedStyles } from '../../../../services';
import { useEuiI18n } from '../../../i18n';
import { EuiButtonEmpty } from '../../../button';
import { EuiIcon } from '../../../icon';
import { EuiPopover } from '../../../popover';
import { euiQuickSelectPopoverStyles } from './quick_select_popover.styles';
import { EuiQuickSelectPanel } from './quick_select_panel';
import { EuiQuickSelect } from './quick_select';
import { EuiCommonlyUsedTimeRanges } from './commonly_used_time_ranges';
import { EuiRecentlyUsed } from './recently_used';
import { EuiRefreshInterval } from '../../auto_refresh/refresh_interval';
export var EuiQuickSelectPopover = function EuiQuickSelectPopover(_ref) {
  var _applyTime = _ref.applyTime,
    _ref$buttonProps = _ref.buttonProps,
    buttonProps = _ref$buttonProps === void 0 ? {} : _ref$buttonProps,
    props = _objectWithoutProperties(_ref, _excluded);
  var buttonContentProps = buttonProps.contentProps,
    buttonOnClick = buttonProps.onClick,
    quickSelectButtonProps = _objectWithoutProperties(buttonProps, _excluded2);
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    prevQuickSelect = _useState2[0],
    setQuickSelect = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    isOpen = _useState4[0],
    setIsOpen = _useState4[1];
  var closePopover = useCallback(function () {
    return setIsOpen(false);
  }, []);
  var togglePopover = useCallback(function () {
    return setIsOpen(function (isOpen) {
      return !isOpen;
    });
  }, []);
  var applyTime = useCallback(function (_ref2) {
    var start = _ref2.start,
      end = _ref2.end,
      quickSelect = _ref2.quickSelect,
      _ref2$keepPopoverOpen = _ref2.keepPopoverOpen,
      keepPopoverOpen = _ref2$keepPopoverOpen === void 0 ? false : _ref2$keepPopoverOpen;
    _applyTime({
      start: start,
      end: end
    });
    if (quickSelect) {
      setQuickSelect(quickSelect);
    }
    if (!keepPopoverOpen) {
      closePopover();
    }
  }, [_applyTime, closePopover]);
  var buttonlabel = useEuiI18n('euiQuickSelectPopover.buttonLabel', 'Date quick select');
  var styles = useEuiMemoizedStyles(euiQuickSelectPopoverStyles);
  var quickSelectButtonOnClick = function quickSelectButtonOnClick(e) {
    togglePopover();
    buttonOnClick === null || buttonOnClick === void 0 || buttonOnClick(e);
  };
  var quickSelectButton = ___EmotionJSX(EuiButtonEmpty, _extends({
    css: styles.euiQuickSelectPopoverButton,
    contentProps: _objectSpread({
      css: styles.euiQuickSelectPopoverButton__content
    }, buttonContentProps),
    onClick: quickSelectButtonOnClick,
    "aria-label": buttonlabel,
    title: buttonlabel,
    size: "xs",
    iconType: "arrowDown",
    iconSide: "right",
    isDisabled: props.isDisabled,
    "data-test-subj": "superDatePickerToggleQuickMenuButton"
  }, quickSelectButtonProps), ___EmotionJSX(EuiIcon, {
    type: "calendar"
  }));
  return ___EmotionJSX(EuiPopover, {
    button: quickSelectButton,
    isOpen: isOpen,
    closePopover: closePopover,
    anchorPosition: "downLeft"
  }, ___EmotionJSX(EuiQuickSelectPanels, _extends({}, props, {
    applyTime: applyTime,
    prevQuickSelect: prevQuickSelect
  })));
};
EuiQuickSelectPopover.propTypes = {
  applyRefreshInterval: PropTypes.func,
  applyTime: PropTypes.func.isRequired,
  commonlyUsedRanges: PropTypes.arrayOf(PropTypes.shape({
    end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
    label: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
  }).isRequired).isRequired,
  customQuickSelectPanels: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.element.isRequired
  }).isRequired),
  customQuickSelectRender: PropTypes.func,
  dateFormat: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isPaused: PropTypes.bool.isRequired,
  recentlyUsedRanges: PropTypes.arrayOf(PropTypes.shape({
    end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
    label: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
  }).isRequired).isRequired,
  refreshInterval: PropTypes.number.isRequired,
  refreshMinInterval: PropTypes.number,
  intervalUnits: PropTypes.any,
  start: PropTypes.string.isRequired,
  timeOptions: PropTypes.shape({
    timeTenseOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    timeUnitsOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    relativeOptions: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.oneOf(["s", "m", "h", "d", "w", "M", "y"]).isRequired, PropTypes.oneOf(["s+", "m+", "h+", "d+", "w+", "M+", "y+"]).isRequired]).isRequired
    }).isRequired).isRequired,
    relativeRoundingLabels: PropTypes.any.isRequired,
    refreshUnitsOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    commonDurationRanges: PropTypes.arrayOf(PropTypes.shape({
      end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
      label: PropTypes.string,
      start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
    }).isRequired).isRequired
  }).isRequired,
  buttonProps: PropTypes.any
};
export var EuiQuickSelectPanels = ({
  start,
  end,
  dateFormat,
  timeOptions,
  commonlyUsedRanges,
  recentlyUsedRanges,
  customQuickSelectPanels,
  customQuickSelectRender,
  isPaused,
  refreshInterval,
  refreshMinInterval,
  intervalUnits,
  applyRefreshInterval,
  applyTime,
  prevQuickSelect
}) => {
  const styles = useEuiMemoizedStyles(euiQuickSelectPopoverStyles);
  const quickSelectElement = <EuiQuickSelect applyTime={applyTime} start={start} end={end} prevQuickSelect={prevQuickSelect} timeOptions={timeOptions} />;
  const commonlyUsedElement = <EuiCommonlyUsedTimeRanges applyTime={applyTime} commonlyUsedRanges={commonlyUsedRanges} />;
  const recentlyUsedElement = <EuiRecentlyUsed applyTime={applyTime} commonlyUsedRanges={commonlyUsedRanges} dateFormat={dateFormat} recentlyUsedRanges={recentlyUsedRanges} />;
  const refreshIntervalElement = applyRefreshInterval && <EuiRefreshInterval onRefreshChange={applyRefreshInterval} isPaused={isPaused} refreshInterval={refreshInterval} minInterval={refreshMinInterval} intervalUnits={intervalUnits} />;
  const customQuickSelectPanelsElement = useMemo(() => {
    if (!customQuickSelectPanels) {
      return null;
    }
    return customQuickSelectPanels.map(({
      title,
      content
    }) => {
      return <EuiQuickSelectPanel key={title} title={title}>
          {React.cloneElement(content, {
          applyTime
        })}
        </EuiQuickSelectPanel>;
    });
  }, [customQuickSelectPanels, applyTime]);
  return <div css={styles.euiQuickSelectPopover} className="euiQuickSelectPopover" data-test-subj="superDatePickerQuickMenu">
      {customQuickSelectRender ? customQuickSelectRender({
      quickSelect: quickSelectElement,
      commonlyUsedRanges: commonlyUsedElement,
      recentlyUsedRanges: recentlyUsedElement,
      refreshInterval: refreshIntervalElement,
      customQuickSelectPanels: customQuickSelectPanelsElement
    }) : <>
          {quickSelectElement}
          {commonlyUsedElement}
          {recentlyUsedElement}
          {refreshIntervalElement}
          {customQuickSelectPanelsElement}
        </>}
    </div>;
};
EuiQuickSelectPanels.propTypes = {
  applyRefreshInterval: PropTypes.func,
  applyTime: PropTypes.func.isRequired,
  commonlyUsedRanges: PropTypes.arrayOf(PropTypes.shape({
    end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
    label: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
  }).isRequired).isRequired,
  customQuickSelectPanels: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.element.isRequired
  }).isRequired),
  customQuickSelectRender: PropTypes.func,
  dateFormat: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  isPaused: PropTypes.bool.isRequired,
  recentlyUsedRanges: PropTypes.arrayOf(PropTypes.shape({
    end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
    label: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
  }).isRequired).isRequired,
  refreshInterval: PropTypes.number.isRequired,
  refreshMinInterval: PropTypes.number,
  intervalUnits: PropTypes.any,
  start: PropTypes.string.isRequired,
  timeOptions: PropTypes.shape({
    timeTenseOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    timeUnitsOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    relativeOptions: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.oneOf(["s", "m", "h", "d", "w", "M", "y"]).isRequired, PropTypes.oneOf(["s+", "m+", "h+", "d+", "w+", "M+", "y+"]).isRequired]).isRequired
    }).isRequired).isRequired,
    relativeRoundingLabels: PropTypes.any.isRequired,
    refreshUnitsOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    commonDurationRanges: PropTypes.arrayOf(PropTypes.shape({
      end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
      label: PropTypes.string,
      start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
    }).isRequired).isRequired
  }).isRequired,
  prevQuickSelect: PropTypes.shape({
    timeTense: PropTypes.string.isRequired,
    timeValue: PropTypes.number.isRequired,
    timeUnits: PropTypes.oneOf(["s", "m", "h", "d", "w", "M", "y"]).isRequired
  })
};
try {
  EuiQuickSelectPopover.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
    description: '',
    displayName: 'EuiQuickSelectPopover',
    methods: [],
    props: {
      applyRefreshInterval: {
        defaultValue: null,
        description: '',
        name: 'applyRefreshInterval',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: false,
        type: {
          name: 'ApplyRefreshInterval'
        }
      },
      applyTime: {
        defaultValue: null,
        description: '',
        name: 'applyTime',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'ApplyTime'
        }
      },
      commonlyUsedRanges: {
        defaultValue: null,
        description: '',
        name: 'commonlyUsedRanges',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'DurationRange[]'
        }
      },
      customQuickSelectPanels: {
        defaultValue: null,
        description: '',
        name: 'customQuickSelectPanels',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: false,
        type: {
          name: 'QuickSelectPanel[]'
        }
      },
      customQuickSelectRender: {
        defaultValue: null,
        description: '',
        name: 'customQuickSelectRender',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: false,
        type: {
          name: '(options: CustomQuickSelectRenderOptions) => ReactNode'
        }
      },
      dateFormat: {
        defaultValue: null,
        description: '',
        name: 'dateFormat',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      end: {
        defaultValue: null,
        description: '',
        name: 'end',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '',
        name: 'isDisabled',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      isPaused: {
        defaultValue: null,
        description: '',
        name: 'isPaused',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      recentlyUsedRanges: {
        defaultValue: null,
        description: '',
        name: 'recentlyUsedRanges',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'DurationRange[]'
        }
      },
      refreshInterval: {
        defaultValue: null,
        description: '',
        name: 'refreshInterval',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      refreshMinInterval: {
        defaultValue: null,
        description: '',
        name: 'refreshMinInterval',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      intervalUnits: {
        defaultValue: null,
        description: '',
        name: 'intervalUnits',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "h"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"h"'
          }]
        }
      },
      start: {
        defaultValue: null,
        description: '',
        name: 'start',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      timeOptions: {
        defaultValue: null,
        description: '',
        name: 'timeOptions',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'TimeOptions'
        }
      },
      buttonProps: {
        defaultValue: {
          value: '{}'
        },
        description: '',
        name: 'buttonProps',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiButtonEmptyPropsForButton, "children" | "isLoading" | "isDisabled">>'
        }
      }
    },
    extendedInterfaces: ['EuiQuickSelectPopoverProps']
  };
} catch (e) {}
try {
  EuiQuickSelectPanels.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
    description: '',
    displayName: 'EuiQuickSelectPanels',
    methods: [],
    props: {
      end: {
        defaultValue: null,
        description: '',
        name: 'end',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      dateFormat: {
        defaultValue: null,
        description: '',
        name: 'dateFormat',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      commonlyUsedRanges: {
        defaultValue: null,
        description: '',
        name: 'commonlyUsedRanges',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'DurationRange[]'
        }
      },
      customQuickSelectPanels: {
        defaultValue: null,
        description: '',
        name: 'customQuickSelectPanels',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: false,
        type: {
          name: 'QuickSelectPanel[]'
        }
      },
      customQuickSelectRender: {
        defaultValue: null,
        description: '',
        name: 'customQuickSelectRender',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: false,
        type: {
          name: '(options: CustomQuickSelectRenderOptions) => ReactNode'
        }
      },
      isPaused: {
        defaultValue: null,
        description: '',
        name: 'isPaused',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      recentlyUsedRanges: {
        defaultValue: null,
        description: '',
        name: 'recentlyUsedRanges',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'DurationRange[]'
        }
      },
      refreshInterval: {
        defaultValue: null,
        description: '',
        name: 'refreshInterval',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      refreshMinInterval: {
        defaultValue: null,
        description: '',
        name: 'refreshMinInterval',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      start: {
        defaultValue: null,
        description: '',
        name: 'start',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      timeOptions: {
        defaultValue: null,
        description: '',
        name: 'timeOptions',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'TimeOptions'
        }
      },
      applyTime: {
        defaultValue: null,
        description: '',
        name: 'applyTime',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: true,
        type: {
          name: 'ApplyTime'
        }
      },
      applyRefreshInterval: {
        defaultValue: null,
        description: '',
        name: 'applyRefreshInterval',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: false,
        type: {
          name: 'ApplyRefreshInterval'
        }
      },
      intervalUnits: {
        defaultValue: null,
        description: '',
        name: 'intervalUnits',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'EuiQuickSelectPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "h"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"h"'
          }]
        }
      },
      prevQuickSelect: {
        defaultValue: null,
        description: '',
        name: 'prevQuickSelect',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/quick_select_popover/quick_select_popover.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'QuickSelect'
        }
      }
    },
    extendedInterfaces: ['EuiQuickSelectPopoverProps']
  };
} catch (e) {}