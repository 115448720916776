/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import { copyToClipboard } from '../../services';
import { EuiToolTip } from '../tool_tip';
export class EuiCopy extends Component {
  static defaultProps = {
    afterMessage: 'Copied'
  };
  constructor(props) {
    super(props);
    this.state = {
      tooltipText: this.props.beforeMessage
    };
  }
  copy = () => {
    const isCopied = copyToClipboard(this.props.textToCopy);
    if (isCopied) {
      this.setState({
        tooltipText: this.props.afterMessage
      });
    }
  };
  resetTooltipText = () => {
    this.setState({
      tooltipText: this.props.beforeMessage
    });
  };
  render() {
    const {
      children,
      textToCopy,
      beforeMessage,
      afterMessage,
      ...rest
    } = this.props;
    return (
      // See `src/components/tool_tip/tool_tip.js` for explanation of below eslint-disable
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <EuiToolTip content={this.state.tooltipText} onMouseOut={this.resetTooltipText} {...rest}>
        {children(this.copy)}
      </EuiToolTip>
    );
  }
}
EuiCopy.propTypes = {
  /**
     * Text that will be copied to clipboard when copy function is executed.
     */
  textToCopy: PropTypes.string.isRequired,
  /**
     * Tooltip message displayed before copy function is called.
     */
  beforeMessage: PropTypes.node,
  /**
     * Tooltip message displayed after copy function is called that lets the user know that
     * 'textToCopy' has been copied to the clipboard.
     */
  afterMessage: PropTypes.node,
  /**
     * Function that must return a component. First argument is 'copy' function.
     * Use your own logic to create the component that users interact with when triggering copy.
     */
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiCopy.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/copy/copy.tsx',
    description: '',
    displayName: 'EuiCopy',
    methods: [],
    props: {
      textToCopy: {
        defaultValue: null,
        description: 'Text that will be copied to clipboard when copy function is executed.',
        name: 'textToCopy',
        parent: {
          fileName: 'eui/src/components/copy/copy.tsx',
          name: 'EuiCopyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/copy/copy.tsx',
          name: 'EuiCopyProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      beforeMessage: {
        defaultValue: null,
        description: 'Tooltip message displayed before copy function is called.',
        name: 'beforeMessage',
        parent: {
          fileName: 'eui/src/components/copy/copy.tsx',
          name: 'EuiCopyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/copy/copy.tsx',
          name: 'EuiCopyProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      afterMessage: {
        defaultValue: {
          value: 'Copied'
        },
        description: 'Tooltip message displayed after copy function is called that lets the user know that\n' + "'textToCopy' has been copied to the clipboard.",
        name: 'afterMessage',
        parent: {
          fileName: 'eui/src/components/copy/copy.tsx',
          name: 'EuiCopyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/copy/copy.tsx',
          name: 'EuiCopyProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      children: {
        defaultValue: null,
        description: "Function that must return a component. First argument is 'copy' function.\n" + 'Use your own logic to create the component that users interact with when triggering copy.',
        name: 'children',
        parent: {
          fileName: 'eui/src/components/copy/copy.tsx',
          name: 'EuiCopyProps'
        },
        declarations: [{
          fileName: 'eui/src/components/copy/copy.tsx',
          name: 'EuiCopyProps'
        }],
        required: true,
        type: {
          name: '(copy: () => void) => ReactElement'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      id: {
        defaultValue: null,
        description: 'Unless you provide one, this will be randomly generated.',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      title: {
        defaultValue: null,
        description: 'An optional title for your tooltip.',
        name: 'title',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      content: {
        defaultValue: null,
        description: 'The main content of your tooltip.',
        name: 'content',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      onMouseOut: {
        defaultValue: null,
        description: 'If supplied, called when mouse movement causes the tool tip to be\n' + 'hidden.',
        name: 'onMouseOut',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: '(event: MouseEvent<HTMLSpanElement, MouseEvent>) => void'
        }
      },
      position: {
        defaultValue: null,
        description: 'Suggested position. If there is not enough room for it this will be changed.',
        name: 'position',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ToolTipPositions',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }, {
            value: '"top"'
          }, {
            value: '"bottom"'
          }]
        }
      },
      display: {
        defaultValue: null,
        description: 'Common display alternatives for the anchor wrapper',
        name: 'display',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"inlineBlock" | "block"',
          value: [{
            value: '"inlineBlock"'
          }, {
            value: '"block"'
          }]
        }
      },
      anchorProps: {
        defaultValue: null,
        description: 'Passes onto the span wrapping the trigger.',
        name: 'anchorProps',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLSpanElement>'
        }
      },
      delay: {
        defaultValue: null,
        description: 'Delay before showing tooltip. Good for repeatable items.',
        name: 'delay',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ToolTipDelay',
          value: [{
            value: '"regular"'
          }, {
            value: '"long"'
          }]
        }
      },
      anchorClassName: {
        defaultValue: null,
        description: 'Passes onto the span wrapping the trigger.',
        name: 'anchorClassName',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      repositionOnScroll: {
        defaultValue: null,
        description: "When `true`, the tooltip's position is re-calculated when the user\n" + 'scrolls. This supports having fixed-position tooltip anchors.\n' + '\n' + 'When nesting an `EuiTooltip` in a scrollable container, `repositionOnScroll` should be `true`',
        name: 'repositionOnScroll',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      disableScreenReaderOutput: {
        defaultValue: {
          value: 'false'
        },
        description: 'Disables the tooltip content being read by screen readers when focusing the trigger element.\n' + 'Do not use when the trigger `aria-label` and tooltip `content` can be rephrased to be standalone\n' + 'information (action & additional information).\n' + 'Enable this prop only when the trigger has a descriptive label that either duplicates or includes\n' + 'the tooltip content and would result in repetitive output.',
        name: 'disableScreenReaderOutput',
        parent: {
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        },
        declarations: [{
          fileName: 'eui/src/components/tool_tip/tool_tip.tsx',
          name: 'EuiToolTipProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['EuiCopyProps', 'CommonProps', 'EuiToolTipProps']
  };
} catch (e) {}