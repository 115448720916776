/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keys, CanvasTextUtils, RenderWithEuiStylesMemoizer } from '../../../services';
import { EuiScreenReaderOnly } from '../../accessibility';
import { EuiFormControlLayout } from '../../form/form_control_layout';
import { EuiComboBoxOptionAppendPrepend } from '../utils';
import { EuiComboBoxPill } from './combo_box_pill';
import { euiComboBoxInputStyles } from './combo_box_input.styles';
export class EuiComboBoxInput extends Component {
  state = {
    inputWidth: 2,
    hasFocus: false
  };
  widthUtils;
  inputRefCallback = el => {
    this.widthUtils = new CanvasTextUtils({
      container: el
    });
    this.props.inputRef?.(el);
  };
  updateInputSize = inputValue => {
    if (!this.widthUtils) return;
    if (this.asPlainText) return;
    this.widthUtils.setTextToCheck(inputValue);
    // Canvas has minute subpixel differences in rendering compared to DOM
    // We'll buffer the input by ~2px just to ensure sufficient width
    const inputWidth = Math.ceil(this.widthUtils.textWidth) + 2;
    this.setState({
      inputWidth
    });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.searchValue !== this.props.searchValue) {
      this.updateInputSize(this.props.searchValue);
    }
  }
  onFocus = event => {
    this.props.onFocus(event);
    this.setState({
      hasFocus: true
    });
  };
  onBlur = event => {
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
    this.setState({
      hasFocus: false
    });
  };
  onKeyDown = event => {
    const {
      searchValue,
      hasSelectedOptions,
      selectedOptions,
      onRemoveOption,
      singleSelection,
      isListOpen,
      onOpenListClick,
      onChange
    } = this.props;
    const searchIsEmpty = !searchValue.length;
    if (event.key === keys.BACKSPACE) {
      // When backspacing in a plain text combobox, change normally and remove the selection
      if (this.asPlainText) {
        onChange(event.currentTarget.value);
        if (hasSelectedOptions) {
          onRemoveOption(selectedOptions[selectedOptions.length - 1]);
        }
      }
      // When backspacing from an empty input, delete the last pill option in the list
      else if (searchIsEmpty && hasSelectedOptions) {
        onRemoveOption(selectedOptions[selectedOptions.length - 1]);
        if (!!singleSelection && !isListOpen) {
          onOpenListClick();
        }
      }
    }
  };
  get asPlainText() {
    const {
      singleSelection
    } = this.props;
    const isSingleSelectionConfig = singleSelection && typeof singleSelection === 'object';
    return !!(isSingleSelectionConfig && singleSelection.asPlainText);
  }
  get searchValue() {
    const {
      searchValue,
      selectedOptions
    } = this.props;
    if (this.asPlainText) {
      return searchValue || selectedOptions?.[0]?.label || '';
    } else {
      return searchValue;
    }
  }
  renderPills = () => {
    // Don't render a pill for plain text comboboxes - use the input instead
    if (this.asPlainText) return null;
    // Don't render the single pill selection while searching
    if (this.props.singleSelection && this.props.searchValue) return null;
    const {
      selectedOptions,
      isDisabled,
      onRemoveOption
    } = this.props;
    if (!selectedOptions || !selectedOptions.length) return null;
    return selectedOptions.map(option => {
      const {
        key,
        label,
        color,
        onClick,
        append,
        prepend,
        truncationProps,
        toolTipContent,
        toolTipProps,
        ...rest
      } = option;
      const pillOnClose = isDisabled || this.props.singleSelection || onClick ? undefined : onRemoveOption;
      return <EuiComboBoxPill option={option} onClose={pillOnClose} key={key ?? label.toLowerCase()} color={color} onClick={onClick} onClickAriaLabel={onClick ? 'Change' : undefined} {...rest}>
          {label}
        </EuiComboBoxPill>;
    });
  };
  render() {
    const {
      compressed,
      focusedOptionId,
      fullWidth,
      hasSelectedOptions,
      id,
      isDisabled,
      isListOpen,
      noIcon,
      onChange,
      onClear,
      onClick,
      onFocus,
      onCloseListClick,
      onOpenListClick,
      placeholder,
      rootId,
      searchValue,
      selectedOptions,
      singleSelection,
      value,
      prepend,
      append,
      isLoading,
      isInvalid,
      autoFocus,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledby
    } = this.props;
    let removeOptionMessage;
    let removeOptionMessageId;
    if (this.state.hasFocus) {
      const readPlaceholder = placeholder ? `${placeholder}.` : '';
      const removeOptionMessageContent = `Combo box. Selected. ${searchValue ? `${searchValue}. Selected. ` : ''}${selectedOptions && selectedOptions.length > 0 ? `${value}. Press Backspace to delete ${selectedOptions[selectedOptions.length - 1].label}. ` : ''}Combo box input. ${readPlaceholder} Type some text or, to display a list of choices, press Down Arrow. ` + 'To exit the list of choices, press Escape.';
      removeOptionMessageId = rootId('removeOptionMessage');

      // aria-live="assertive" will read this message aloud immediately once it enters the DOM.
      // We'll render to the DOM when the input gains focus and remove it when the input loses focus.
      // We'll use aria-hidden to prevent default aria information from being read by the screen
      // reader.
      removeOptionMessage = <EuiScreenReaderOnly>
          <span aria-live="polite" id={removeOptionMessageId}>
            {removeOptionMessageContent}
          </span>
        </EuiScreenReaderOnly>;
    }
    const isInGroup = singleSelection && (prepend || append);
    const showPlaceholder = placeholder && !selectedOptions?.length && !searchValue;
    const clickProps = {};
    if (!isDisabled && onClear && hasSelectedOptions) {
      clickProps.clear = {
        'data-test-subj': 'comboBoxClearButton',
        onClick: onClear
      };
    }
    let icon;
    if (!noIcon && !isDisabled) {
      icon = {
        'aria-label': isListOpen ? 'Close list of options' : 'Open list of options',
        'data-test-subj': 'comboBoxToggleListButton',
        disabled: isDisabled,
        onClick: isListOpen && !isDisabled ? onCloseListClick : onOpenListClick,
        side: 'right',
        tabIndex: -1,
        type: 'arrowDown'
      };
    }
    const wrapClasses = classNames('euiComboBox__inputWrap', {
      'euiComboBox__inputWrap--plainText': this.asPlainText
    });
    return <RenderWithEuiStylesMemoizer>
        {stylesMemoizer => {
        const styles = stylesMemoizer(euiComboBoxInputStyles);
        const cssStyles = [styles.euiComboBoxInputWrapper, !singleSelection && styles.multiSelect, compressed ? styles.compressed : styles.uncompressed, ...(this.asPlainText || showPlaceholder ? [styles.plainText.plainText, compressed ? styles.plainText.compressed : styles.plainText.uncompressed] : []), isDisabled ? styles.disabled : isInvalid ? styles.invalid : isListOpen ? styles.open : undefined, isInGroup && styles.inGroup];
        const formLayoutStyles = [styles.formLayout.euiComboBox__formControlLayout, !singleSelection && styles.formLayout.multiSelect];
        return <EuiFormControlLayout icon={icon} {...clickProps} inputId={id} isLoading={isLoading} isInvalid={isInvalid} isDisabled={isDisabled} compressed={compressed} fullWidth={fullWidth} prepend={prepend} append={append} css={formLayoutStyles}>
              <div css={cssStyles} className={wrapClasses} data-test-subj="comboBoxInput" onClick={onClick} tabIndex={-1} // becomes onBlur event's relatedTarget, otherwise relatedTarget is null when clicking on this div
          >
                {this.renderPills()}
                <EuiComboBoxOptionAppendPrepend option={this.asPlainText ? selectedOptions?.[0] : undefined} classNamePrefix="euiComboBoxPlainTextSelection" marginSize="xxs">
                  <input aria-activedescendant={focusedOptionId} aria-autocomplete="list" aria-controls={isListOpen ? rootId('listbox') : ''} aria-expanded={isListOpen} aria-label={ariaLabel} aria-labelledby={ariaLabelledby} aria-invalid={isInvalid} aria-haspopup="listbox" css={styles.euiComboBoxInput} className="euiComboBox__input" data-test-subj="comboBoxSearchInput" disabled={isDisabled} id={id} onBlur={this.onBlur} onChange={event => onChange(event.target.value)} onFocus={this.onFocus} onKeyDown={this.onKeyDown} ref={this.inputRefCallback} role="combobox" style={{
                inlineSize: this.asPlainText || showPlaceholder ? '100%' : this.state.inputWidth
              }} placeholder={showPlaceholder ? placeholder : undefined} value={this.searchValue} autoFocus={autoFocus} autoComplete="off"
              // Force the menu to re-open on every input click - only necessary when plain text
              onClick={this.asPlainText ? onFocus : undefined} // Type shenanigans - event should be mostly the same
              />
                </EuiComboBoxOptionAppendPrepend>
                {removeOptionMessage}
              </div>
            </EuiFormControlLayout>;
      }}
      </RenderWithEuiStylesMemoizer>;
  }
}
EuiComboBoxInput.propTypes = {
  compressed: PropTypes.bool.isRequired,
  focusedOptionId: PropTypes.string,
  fullWidth: PropTypes.bool,
  hasSelectedOptions: PropTypes.bool.isRequired,
  id: PropTypes.string,
  inputRef: PropTypes.any,
  isDisabled: PropTypes.bool,
  isListOpen: PropTypes.bool.isRequired,
  noIcon: PropTypes.bool.isRequired,
  onBlur: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  onCloseListClick: PropTypes.func.isRequired,
  onFocus: PropTypes.any.isRequired,
  onOpenListClick: PropTypes.func.isRequired,
  onRemoveOption: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rootId: PropTypes.any.isRequired,
  searchValue: PropTypes.string.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({
    isGroupLabelOption: PropTypes.bool,
    label: PropTypes.string.isRequired,
    key: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      isGroupLabelOption: PropTypes.bool,
      label: PropTypes.string.isRequired,
      key: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.any.isRequired),
      value: PropTypes.any,
      prepend: PropTypes.node,
      append: PropTypes.node,
      truncationProps: PropTypes.any,
      /**
         * Optional custom tooltip content for the button
         */
      toolTipContent: PropTypes.node,
      /**
         * Optional props to pass to the underlying **[EuiToolTip](/#/display/tooltip)**
         */
      toolTipProps: PropTypes.any,
      className: PropTypes.string,
      "aria-label": PropTypes.string,
      "data-test-subj": PropTypes.string,
      css: PropTypes.any
    }).isRequired),
    value: PropTypes.any,
    prepend: PropTypes.node,
    append: PropTypes.node,
    truncationProps: PropTypes.any,
    /**
       * Optional custom tooltip content for the button
       */
    toolTipContent: PropTypes.node,
    /**
       * Optional props to pass to the underlying **[EuiToolTip](/#/display/tooltip)**
       */
    toolTipProps: PropTypes.any,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }).isRequired).isRequired,
  singleSelection: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.shape({
    asPlainText: PropTypes.bool
  }).isRequired]),
  toggleButtonRef: PropTypes.any,
  value: PropTypes.string,
  prepend: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  append: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired).isRequired]),
  isLoading: PropTypes.bool,
  isInvalid: PropTypes.bool,
  autoFocus: PropTypes.bool,
  "aria-label": PropTypes.string,
  "aria-labelledby": PropTypes.string,
  className: PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiComboBoxInput.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
    description: '',
    displayName: 'EuiComboBoxInput',
    methods: [],
    props: {
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      focusedOptionId: {
        defaultValue: null,
        description: '',
        name: 'focusedOptionId',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      fullWidth: {
        defaultValue: null,
        description: '',
        name: 'fullWidth',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasSelectedOptions: {
        defaultValue: null,
        description: '',
        name: 'hasSelectedOptions',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      inputRef: {
        defaultValue: null,
        description: '',
        name: 'inputRef',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: '(instance: HTMLInputElement) => void'
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '',
        name: 'isDisabled',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isListOpen: {
        defaultValue: null,
        description: '',
        name: 'isListOpen',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      noIcon: {
        defaultValue: null,
        description: '',
        name: 'noIcon',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      onBlur: {
        defaultValue: null,
        description: '',
        name: 'onBlur',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: 'FocusEventHandler<HTMLInputElement>'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: true,
        type: {
          name: '(searchValue: string) => void'
        }
      },
      onClear: {
        defaultValue: null,
        description: '',
        name: 'onClear',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: true,
        type: {
          name: '() => void'
        }
      },
      onCloseListClick: {
        defaultValue: null,
        description: '',
        name: 'onCloseListClick',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: true,
        type: {
          name: '() => void'
        }
      },
      onFocus: {
        defaultValue: null,
        description: '',
        name: 'onFocus',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: true,
        type: {
          name: 'FocusEventHandler<HTMLInputElement>'
        }
      },
      onOpenListClick: {
        defaultValue: null,
        description: '',
        name: 'onOpenListClick',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: true,
        type: {
          name: '() => void'
        }
      },
      onRemoveOption: {
        defaultValue: null,
        description: '',
        name: 'onRemoveOption',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: true,
        type: {
          name: 'OptionHandler<T>'
        }
      },
      placeholder: {
        defaultValue: null,
        description: '',
        name: 'placeholder',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      rootId: {
        defaultValue: null,
        description: '',
        name: 'rootId',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: true,
        type: {
          name: '(idSuffix?: string) => string'
        }
      },
      searchValue: {
        defaultValue: null,
        description: '',
        name: 'searchValue',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      selectedOptions: {
        defaultValue: null,
        description: '',
        name: 'selectedOptions',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: true,
        type: {
          name: 'EuiComboBoxOptionOption<T>[]'
        }
      },
      singleSelection: {
        defaultValue: null,
        description: '',
        name: 'singleSelection',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: 'boolean | EuiComboBoxSingleSelectionShape'
        }
      },
      toggleButtonRef: {
        defaultValue: null,
        description: '',
        name: 'toggleButtonRef',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: '(instance: HTMLButtonElement | HTMLSpanElement) => void'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      prepend: {
        defaultValue: null,
        description: '',
        name: 'prepend',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: '',
        name: 'append',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      autoFocus: {
        defaultValue: null,
        description: '',
        name: 'autoFocus',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-labelledby': {
        defaultValue: null,
        description: '',
        name: 'aria-labelledby',
        parent: {
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        },
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_input/combo_box_input.tsx',
          name: 'EuiComboBoxInputProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiComboBoxInputProps', 'CommonProps']
  };
} catch (e) {}