import React from 'react';
import PropTypes from "prop-types";
export var EuiSideNavItem = function EuiSideNavItem() {
  return ___EmotionJSX("div", null);
};
EuiSideNavItem.propTypes = {
  /**
     * A value that is passed to React as the `key` for this item
     */
  id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  /**
     * If set to true it will force the item to display in an "open" state at all times.
     */
  forceOpen: PropTypes.bool,
  /**
     * Array containing additional item objects, representing nested children of this navigation item.
     */
  items: PropTypes.arrayOf(PropTypes.shape({
    /**
       * A value that is passed to React as the `key` for this item
       */
    id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
    /**
       * If set to true it will force the item to display in an "open" state at all times.
       */
    forceOpen: PropTypes.bool,
    /**
       * Array containing additional item objects, representing nested children of this navigation item.
       */
    items: PropTypes.arrayOf(PropTypes.any.isRequired),
    /**
       * React node representing the text to render for this item (usually a string will suffice).
       */
    name: PropTypes.node.isRequired,
    /**
       * Function overriding default rendering for this navigation item — when called, it should return a React node representing a replacement navigation item.
       */
    renderItem: PropTypes.func
  }).isRequired),
  /**
     * React node representing the text to render for this item (usually a string will suffice).
     */
  name: PropTypes.node.isRequired,
  /**
     * Function overriding default rendering for this navigation item — when called, it should return a React node representing a replacement navigation item.
     */
  renderItem: PropTypes.func
};
export var EuiSideNavHeading = () => <div />;
EuiSideNavHeading.propTypes = {
  children: PropTypes.node.isRequired,
  /**
     * The actual HTML heading element to wrap the `heading`.
     * Default is `h2`
     */
  element: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "span"]),
  /**
     * For best accessibility, `<nav>` elements should have a nested heading. But you can hide this element if it's redundent from something else (except on mobile).
     */
  screenReaderOnly: PropTypes.bool
};
try {
  EuiSideNavItem.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/side_nav/props.tsx',
    description: '',
    displayName: 'EuiSideNavItem',
    methods: [],
    props: {
      id: {
        defaultValue: null,
        description: 'A value that is passed to React as the `key` for this item',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/side_nav/side_nav_types.ts',
          name: 'EuiSideNavItemType'
        },
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_types.ts',
          name: 'EuiSideNavItemType'
        }],
        required: true,
        type: {
          name: 'string | number'
        }
      },
      forceOpen: {
        defaultValue: null,
        description: 'If set to true it will force the item to display in an "open" state at all times.',
        name: 'forceOpen',
        parent: {
          fileName: 'eui/src/components/side_nav/side_nav_types.ts',
          name: 'EuiSideNavItemType'
        },
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_types.ts',
          name: 'EuiSideNavItemType'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      items: {
        defaultValue: null,
        description: 'Array containing additional item objects, representing nested children of this navigation item.',
        name: 'items',
        parent: {
          fileName: 'eui/src/components/side_nav/side_nav_types.ts',
          name: 'EuiSideNavItemType'
        },
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_types.ts',
          name: 'EuiSideNavItemType'
        }],
        required: false,
        type: {
          name: 'EuiSideNavItemType<any>[]'
        }
      },
      name: {
        defaultValue: null,
        description: 'React node representing the text to render for this item (usually a string will suffice).',
        name: 'name',
        parent: {
          fileName: 'eui/src/components/side_nav/side_nav_types.ts',
          name: 'EuiSideNavItemType'
        },
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_types.ts',
          name: 'EuiSideNavItemType'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      renderItem: {
        defaultValue: null,
        description: 'Function overriding default rendering for this navigation item — when called, it should return a React node representing a replacement navigation item.',
        name: 'renderItem',
        parent: {
          fileName: 'eui/src/components/side_nav/side_nav_types.ts',
          name: 'EuiSideNavItemType'
        },
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_types.ts',
          name: 'EuiSideNavItemType'
        }],
        required: false,
        type: {
          name: 'RenderItem<any>'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      rel: {
        defaultValue: null,
        description: '',
        name: 'rel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onClick: {
        defaultValue: null,
        description: "Callback function to be passed as the navigation item's `onClick` prop,\n" + 'and by default it will force rendering of the item as a `<button>` instead of a link',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'MouseEventHandler<HTMLElement | HTMLButtonElement>'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      target: {
        defaultValue: null,
        description: '',
        name: 'target',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      href: {
        defaultValue: null,
        description: "Is an optional string to be passed as the navigation item's `href` prop,\n" + 'and by default it will force rendering of the item as an `<a>`',
        name: 'href',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      style: {
        defaultValue: null,
        description: 'className, css, and style are passed to the parent wrapper, not the button',
        name: 'style',
        parent: {
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: '_EuiSideNavItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: '_EuiSideNavItemProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      isSelected: {
        defaultValue: null,
        description: 'If set to true it will render the item in a visible\n' + '"selected" state, and will force all ancestor navigation items\n' + 'to render in an "open" state',
        name: 'isSelected',
        parent: {
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: '_EuiSideNavItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: '_EuiSideNavItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      buttonClassName: {
        defaultValue: null,
        description: 'Passed to the actual `.euiSideNavItemButton` element',
        name: 'buttonClassName',
        parent: {
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: '_EuiSideNavItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: '_EuiSideNavItemProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      truncate: {
        defaultValue: null,
        description: "Restrict the item's text length to a single line",
        name: 'truncate',
        parent: {
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: '_EuiSideNavItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: '_EuiSideNavItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      icon: {
        defaultValue: null,
        description: 'React node which will be rendered as a small icon to the\n' + 'left of the navigation item text',
        name: 'icon',
        parent: {
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: '_EuiSideNavItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: '_EuiSideNavItemProps'
        }],
        required: false,
        type: {
          name: 'ReactElement'
        }
      },
      emphasize: {
        defaultValue: null,
        description: "Enhances the whole item's section (including nested items) with\n" + 'a slight background and bold top item',
        name: 'emphasize',
        parent: {
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: '_EuiSideNavItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/side_nav/side_nav_item.tsx',
          name: '_EuiSideNavItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['EuiSideNavItemType', 'CommonProps', '_EuiSideNavItemProps']
  };
} catch (e) {}
try {
  EuiSideNavHeading.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/side_nav/props.tsx',
    description: '',
    displayName: 'EuiSideNavHeading',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/title/title.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      size: {
        defaultValue: null,
        description: '',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/title/title.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs" | "l" | "xxxs" | "xxs"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xxxs"'
          }, {
            value: '"xxs"'
          }]
        }
      },
      textTransform: {
        defaultValue: null,
        description: '',
        name: 'textTransform',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/title/title.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '"uppercase"'
        }
      },
      element: {
        defaultValue: null,
        description: 'The actual HTML heading element to wrap the `heading`.\nDefault is `h2`',
        name: 'element',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/side_nav/_side_nav_heading.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span"',
          value: [{
            value: '"h1"'
          }, {
            value: '"h2"'
          }, {
            value: '"h3"'
          }, {
            value: '"h4"'
          }, {
            value: '"h5"'
          }, {
            value: '"h6"'
          }, {
            value: '"span"'
          }]
        }
      },
      screenReaderOnly: {
        defaultValue: null,
        description: "For best accessibility, `<nav>` elements should have a nested heading. But you can hide this element if it's redundent from something else (except on mobile).",
        name: 'screenReaderOnly',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/side_nav/_side_nav_heading.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['EuiSideNavItemType', 'CommonProps', '_EuiSideNavItemProps']
  };
} catch (e) {}