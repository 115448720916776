/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiFlexGroup, EuiFlexItem } from '../../flex';
import { useEuiMemoizedStyles, useGeneratedHtmlId } from '../../../services';
import { euiHeaderAlertStyles } from './header_alert.styles';
export var EuiHeaderAlert = ({
  action,
  className,
  date,
  text,
  title,
  badge,
  ...rest
}) => {
  const styles = useEuiMemoizedStyles(euiHeaderAlertStyles);
  const classes = classNames('euiHeaderAlert', className);
  const ariaId = useGeneratedHtmlId();
  return <article aria-labelledby={`${ariaId}-title`} className={classes} css={styles.euiHeaderAlert} {...rest}>
      <EuiFlexGroup justifyContent="spaceBetween">
        <EuiFlexItem>
          <div className="euiHeaderAlert__date" css={styles.euiHeaderAlert__date}>
            {date}
          </div>
        </EuiFlexItem>
        {badge && <EuiFlexItem grow={false}>{badge}</EuiFlexItem>}
      </EuiFlexGroup>

      <h3 id={`${ariaId}-title`} className="euiHeaderAlert__title" css={styles.euiHeaderAlert__title}>
        {title}
      </h3>
      <div className="euiHeaderAlert__text" css={styles.euiHeaderAlert__text}>
        {text}
      </div>
      {action && <div className="euiHeaderAlert__action" css={styles.euiHeaderAlert__action}>
          {action}
        </div>}
    </article>;
};
EuiHeaderAlert.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Adds a link to the alert.
       */
  action: PropTypes.node,
  date: PropTypes.node.isRequired,
  text: PropTypes.node,
  title: PropTypes.node.isRequired,
  /**
       * Accepts an `EuiBadge` that displays on the alert
       */
  badge: PropTypes.node
};
try {
  EuiHeaderAlert.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/header/header_alert/header_alert.tsx',
    description: '',
    displayName: 'EuiHeaderAlert',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      action: {
        defaultValue: null,
        description: 'Adds a link to the alert.',
        name: 'action',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/header/header_alert/header_alert.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      date: {
        defaultValue: null,
        description: '',
        name: 'date',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/header/header_alert/header_alert.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      text: {
        defaultValue: null,
        description: '',
        name: 'text',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/header/header_alert/header_alert.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/header/header_alert/header_alert.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      badge: {
        defaultValue: null,
        description: 'Accepts an `EuiBadge` that displays on the alert',
        name: 'badge',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/header/header_alert/header_alert.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}