/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useGeneratedHtmlId, findElementBySelectorOrRef, useEuiMemoizedStyles } from '../../services';
import { logicalStyles } from '../../global_styling';
import { EuiPopover, EuiWrappingPopover } from '../popover';
import { EuiBeacon } from '../beacon';
import { EuiTourHeader } from './_tour_header';
import { EuiTourFooter } from './_tour_footer';
import { euiTourStyles, euiTourBeaconStyles } from './tour.styles';
export var EuiTourStep = ({
  anchorPosition = 'leftUp',
  anchor,
  children,
  className,
  css,
  closePopover = () => {},
  content,
  isStepOpen = false,
  minWidth = 300,
  maxWidth = 600,
  onFinish,
  step = 1,
  stepsTotal,
  subtitle,
  title,
  decoration = 'beacon',
  footerAction,
  panelProps,
  panelClassName,
  ...rest
}) => {
  const titleId = useGeneratedHtmlId();
  if (step === 0) {
    console.warn('EuiTourStep `step` should use 1-based indexing. Please update to eliminate 0 indexes.');
  }
  const [anchorNode, setAnchorNode] = useState(null);
  const [popoverPosition, setPopoverPosition] = useState();
  const onPositionChange = useCallback(position => {
    setPopoverPosition(position);
  }, []);
  useEffect(() => {
    if (anchor) {
      // Wait until next tick to find anchor node in case it's not already
      // in DOM requestAnimationFrame isn't used here because we don't need to
      // synchronize with repainting ticks and the updated value still
      // needs to go through a react DOM rerender which may take more than
      // 1 frame (16ms) of time.
      // TODO: It would be ideal to have some kind of intersection observer here instead
      const timeout = window.setTimeout(() => {
        setAnchorNode(findElementBySelectorOrRef(anchor));
      });
      return () => window.clearTimeout(timeout);
    }
  }, [anchor]);
  const anchorClasses = classNames('euiTourAnchor', className);
  const popoverClasses = classNames('euiTour', panelClassName);
  const tourStyles = useEuiMemoizedStyles(euiTourStyles);
  const beaconStyles = useEuiMemoizedStyles(euiTourBeaconStyles);
  const beaconCss = [beaconStyles.euiTourBeacon, isStepOpen && beaconStyles.isOpen, popoverPosition && beaconStyles[popoverPosition]];
  const hasBeacon = decoration === 'beacon';
  const widthStyles = useMemo(() => logicalStyles({
    minWidth,
    maxWidth
  }), [minWidth, maxWidth]);
  const noAnchor = !anchor && children;
  const PopoverComponent = noAnchor ? EuiPopover : EuiWrappingPopover;
  const button = noAnchor ? children : anchorNode;
  return button ? <PopoverComponent button={button} className={anchorClasses} anchorPosition={anchorPosition} closePopover={closePopover} isOpen={isStepOpen} ownFocus={false} panelClassName={popoverClasses} panelProps={{
    ...panelProps,
    css: [tourStyles.euiTour, css, panelProps?.css]
  }} offset={hasBeacon ? 10 : 0} aria-labelledby={titleId} arrowChildren={hasBeacon && <EuiBeacon css={beaconCss} className="euiTour__beacon" />} onPositionChange={onPositionChange} {...rest}>
      <div style={widthStyles}>
        <EuiTourHeader id={titleId} title={title} subtitle={subtitle} />
        <div className="euiTour__content">{content}</div>
        <EuiTourFooter footerAction={footerAction} step={step} stepsTotal={stepsTotal} onFinish={onFinish} />
      </div>
    </PopoverComponent> : null;
};
EuiTourStep.propTypes = {
  className: PropTypes.string,
  /**
     * Provide a name to the popover panel
     */
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * Alignment of the popover and arrow relative to the button
     */
  anchorPosition: PropTypes.any,
  /**
     * Style and position alteration for arrow-less attachment.
     * Intended for use with inputs as anchors, e.g. EuiInputPopover
     */
  attachToAnchor: PropTypes.bool,
  /**
     * Restrict the popover's position within this element
     */
  container: PropTypes.any,
  /**
     * CSS display type for both the popover and anchor
     */
  display: PropTypes.any,
  /**
     * Object of props passed to EuiFocusTrap
     */
  focusTrapProps: PropTypes.any,
  /**
     * Show arrow indicating to originating button
     */
  hasArrow: PropTypes.bool,
  /**
     * Specifies what element should initially have focus; Can be a DOM
     * node, or a selector string (which will be passed to
     * document.querySelector() to find the DOM node), or a function that
     * returns a DOM node.
     *
     * If not passed, initial focus defaults to the popover panel.
     */
  initialFocus: PropTypes.any,
  /**
     * Passed directly to EuiPortal for DOM positioning. Both properties are
     * required if prop is specified
     */
  insert: PropTypes.shape({
    sibling: PropTypes.any.isRequired,
    position: PropTypes.oneOf(["before", "after"]).isRequired
  }),
  /**
     * Traps tab focus within the popover contents
     */
  ownFocus: PropTypes.bool,
  /**
     * Custom class added to the EuiPanel containing the popover contents
     */
  panelClassName: PropTypes.string,
  /**
     * EuiPanel padding on all sides
     */
  panelPaddingSize: PropTypes.any,
  /**
     * Standard DOM `style` attribute. Passed to the EuiPanel
     */
  panelStyle: PropTypes.any,
  /**
     * Object of props passed to EuiPanel. See #EuiPopoverPanelProps
     */
  panelProps: PropTypes.shape({
    element: PropTypes.oneOf(["div"]),
    /**
       * Padding for all four sides
       */
    paddingSize: PropTypes.any,
    /**
       * Corner border radius
       */
    borderRadius: PropTypes.any,
    /**
       * When true the panel will grow in height to match `EuiFlexItem`
       */
    grow: PropTypes.bool,
    panelRef: PropTypes.any,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  panelRef: PropTypes.any,
  /**
     * Optional screen reader instructions to announce upon popover open,
     * in addition to EUI's default popover instructions for Escape on close.
     * Useful for popovers that may have additional keyboard capabilities such as
     * arrow navigation.
     */
  popoverScreenReaderText: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
  popoverRef: PropTypes.any,
  /**
     * When `true`, the popover's position is re-calculated when the user
     * scrolls, this supports having fixed-position popover anchors. When nesting
     * an `EuiPopover` in a scrollable container, `repositionOnScroll` should be `true`
     */
  repositionOnScroll: PropTypes.bool,
  /**
     * By default, popovers will attempt to position themselves along the initial
     * axis specified. If there is not enough room either vertically or horizontally
     * however, the popover will attempt to reposition itself along the secondary
     * cross axis if there is room there instead.
     *
     * If you do not not want this repositioning to occur (and it is acceptable for
     * the popover to appear offscreen), set this to false to disable this behavior.
     *
     * @default true
     */
  repositionToCrossAxis: PropTypes.bool,
  /**
     * By default, popover content inherits the z-index of the anchor
     * component; pass `zIndex` to override
     */
  zIndex: PropTypes.number,
  /**
     * Distance away from the anchor that the popover will render
     */
  offset: PropTypes.number,
  /**
     * Minimum distance between the popover and the bounding container;
     * Pass an array of 4 values to adjust each side differently: `[top, right, bottom, left]`
     * @default 16
     */
  buffer: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.any.isRequired]),
  /**
     * Element to pass as the child element of the arrow;
     * Use case is typically limited to an accompanying `EuiBeacon`
     */
  arrowChildren: PropTypes.node,
  /**
     * Alternative option to `aria-label` that takes an `id`.
     * Usually takes the `id` of the popover title
     */
  "aria-labelledby": PropTypes.string,
  /**
     * Function callback for when the popover positon changes
     */
  onPositionChange: PropTypes.func,
  /**
       * Element to which the tour step popover attaches when open
       */
  children: PropTypes.element,
  anchor: PropTypes.any,
  /**
       * Contents of the tour step popover
       */
  content: PropTypes.node.isRequired,
  /**
       * Step will display if set to `true`
       */
  isStepOpen: PropTypes.bool,
  /**
       * Change the default min width of the popover panel
       */
  minWidth: PropTypes.any,
  /**
       * Change the default max width of the popover panel
       */
  maxWidth: PropTypes.any,
  /**
       * Function to call for 'Skip tour' and 'End tour' actions
       */
  onFinish: PropTypes.func.isRequired,
  /**
       * The number of the step within the parent tour. 1-based indexing.
       */
  step: PropTypes.number.isRequired,
  /**
       * The total number of steps in the tour
       */
  stepsTotal: PropTypes.number.isRequired,
  /**
       * Smaller title text that appears atop each step in the tour. The subtitle gets wrapped in the appropriate heading level.
       */
  subtitle: PropTypes.node,
  /**
       * Larger title text specific to this step. The title gets wrapped in the appropriate heading level.
       */
  title: PropTypes.node.isRequired,
  /**
       * Extra visual indication of step location
       */
  decoration: PropTypes.oneOf(["none", "beacon"]),
  /**
       * Accepts any `ReactNode` to replace the 'Skip tour' link in the footer.
       * Ideally, pass one button or an array of up to 2 buttons.
       */
  footerAction: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.arrayOf(PropTypes.node.isRequired).isRequired])
};
try {
  EuiTourStep.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/tour/tour_step.tsx',
    description: '',
    displayName: 'EuiTourStep',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Provide a name to the popover panel\n' + 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      'aria-labelledby': {
        defaultValue: null,
        description: 'Alternative option to `aria-label` that takes an `id`.\n' + 'Usually takes the `id` of the popover title\n' + 'Identifies the element (or elements) that labels the current element.\n' + '@see aria-describedby.',
        name: 'aria-labelledby',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: 'Element to which the tour step popover attaches when open',
        name: 'children',
        parent: undefined,
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }, {
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      container: {
        defaultValue: null,
        description: "Restrict the popover's position within this element",
        name: 'container',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'HTMLElement'
        }
      },
      display: {
        defaultValue: null,
        description: 'CSS display type for both the popover and anchor',
        name: 'display',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Display'
        }
      },
      offset: {
        defaultValue: null,
        description: 'Distance away from the anchor that the popover will render',
        name: 'offset',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      panelRef: {
        defaultValue: null,
        description: '',
        name: 'panelRef',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: '(instance: HTMLElement) => void'
        }
      },
      ownFocus: {
        defaultValue: null,
        description: 'Traps tab focus within the popover contents',
        name: 'ownFocus',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      focusTrapProps: {
        defaultValue: null,
        description: 'Object of props passed to EuiFocusTrap',
        name: 'focusTrapProps',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Partial<EuiFocusTrapProps>'
        }
      },
      isOpen: {
        defaultValue: null,
        description: 'Visibility state of the popover',
        name: 'isOpen',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      repositionOnScroll: {
        defaultValue: null,
        description: "When `true`, the popover's position is re-calculated when the user\n" + 'scrolls, this supports having fixed-position popover anchors. When nesting\n' + 'an `EuiPopover` in a scrollable container, `repositionOnScroll` should be `true`',
        name: 'repositionOnScroll',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      anchorPosition: {
        defaultValue: {
          value: 'leftUp'
        },
        description: 'Alignment of the popover and arrow relative to the button',
        name: 'anchorPosition',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"upCenter" | "upLeft" | "upRight" | "downCenter" | "downLeft" | "downRight" | "leftCenter" | "leftUp" | "leftDown" | "rightCenter" | "rightUp" | "rightDown"',
          value: [{
            value: '"upCenter"'
          }, {
            value: '"upLeft"'
          }, {
            value: '"upRight"'
          }, {
            value: '"downCenter"'
          }, {
            value: '"downLeft"'
          }, {
            value: '"downRight"'
          }, {
            value: '"leftCenter"'
          }, {
            value: '"leftUp"'
          }, {
            value: '"leftDown"'
          }, {
            value: '"rightCenter"'
          }, {
            value: '"rightUp"'
          }, {
            value: '"rightDown"'
          }]
        }
      },
      attachToAnchor: {
        defaultValue: null,
        description: 'Style and position alteration for arrow-less attachment.\n' + 'Intended for use with inputs as anchors, e.g. EuiInputPopover',
        name: 'attachToAnchor',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      hasArrow: {
        defaultValue: null,
        description: 'Show arrow indicating to originating button',
        name: 'hasArrow',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      initialFocus: {
        defaultValue: null,
        description: 'Specifies what element should initially have focus; Can be a DOM\n' + 'node, or a selector string (which will be passed to\n' + 'document.querySelector() to find the DOM node), or a function that\n' + 'returns a DOM node.\n' + '\n' + 'If not passed, initial focus defaults to the popover panel.',
        name: 'initialFocus',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'ElementTarget'
        }
      },
      insert: {
        defaultValue: null,
        description: 'Passed directly to EuiPortal for DOM positioning. Both properties are\n' + 'required if prop is specified',
        name: 'insert',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: '{ sibling: HTMLElement; position: "before" | "after"; }'
        }
      },
      panelClassName: {
        defaultValue: null,
        description: 'Custom class added to the EuiPanel containing the popover contents',
        name: 'panelClassName',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      panelPaddingSize: {
        defaultValue: null,
        description: 'EuiPanel padding on all sides',
        name: 'panelPaddingSize',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      panelStyle: {
        defaultValue: null,
        description: 'Standard DOM `style` attribute. Passed to the EuiPanel',
        name: 'panelStyle',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      panelProps: {
        defaultValue: null,
        description: 'Object of props passed to EuiPanel. See #EuiPopoverPanelProps',
        name: 'panelProps',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Omit<_EuiPanelDivlike, "color" | "style" | "hasBorder" | "hasShadow">'
        }
      },
      popoverScreenReaderText: {
        defaultValue: null,
        description: 'Optional screen reader instructions to announce upon popover open,\n' + "in addition to EUI's default popover instructions for Escape on close.\n" + 'Useful for popovers that may have additional keyboard capabilities such as\n' + 'arrow navigation.',
        name: 'popoverScreenReaderText',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      popoverRef: {
        defaultValue: null,
        description: '',
        name: 'popoverRef',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      },
      repositionToCrossAxis: {
        defaultValue: {
          value: 'true'
        },
        description: 'By default, popovers will attempt to position themselves along the initial\n' + 'axis specified. If there is not enough room either vertically or horizontally\n' + 'however, the popover will attempt to reposition itself along the secondary\n' + 'cross axis if there is room there instead.\n' + '\n' + 'If you do not not want this repositioning to occur (and it is acceptable for\n' + 'the popover to appear offscreen), set this to false to disable this behavior.',
        name: 'repositionToCrossAxis',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      zIndex: {
        defaultValue: null,
        description: 'By default, popover content inherits the z-index of the anchor\n' + 'component; pass `zIndex` to override',
        name: 'zIndex',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      buffer: {
        defaultValue: {
          value: '16'
        },
        description: 'Minimum distance between the popover and the bounding container;\n' + 'Pass an array of 4 values to adjust each side differently: `[top, right, bottom, left]`',
        name: 'buffer',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number | [number, number, number, number]'
        }
      },
      arrowChildren: {
        defaultValue: null,
        description: 'Element to pass as the child element of the arrow;\n' + 'Use case is typically limited to an accompanying `EuiBeacon`',
        name: 'arrowChildren',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      onPositionChange: {
        defaultValue: null,
        description: 'Function callback for when the popover positon changes',
        name: 'onPositionChange',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: '(position: EuiPopoverPosition) => void'
        }
      },
      closePopover: {
        defaultValue: {
          value: '() => {}'
        },
        description: 'Callback to handle hiding of the popover',
        name: 'closePopover',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      anchor: {
        defaultValue: null,
        description: 'Selector or reference to the element to which the tour step popover attaches when open',
        name: 'anchor',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ElementTarget'
        }
      },
      content: {
        defaultValue: null,
        description: 'Contents of the tour step popover',
        name: 'content',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      isStepOpen: {
        defaultValue: {
          value: 'false'
        },
        description: 'Step will display if set to `true`',
        name: 'isStepOpen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      minWidth: {
        defaultValue: {
          value: '300'
        },
        description: 'Change the default min width of the popover panel',
        name: 'minWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'MinWidth<string | number>'
        }
      },
      maxWidth: {
        defaultValue: {
          value: '600'
        },
        description: 'Change the default max width of the popover panel',
        name: 'maxWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'MaxWidth<string | number>'
        }
      },
      onFinish: {
        defaultValue: null,
        description: "Function to call for 'Skip tour' and 'End tour' actions",
        name: 'onFinish',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      step: {
        defaultValue: {
          value: '1'
        },
        description: 'The number of the step within the parent tour. 1-based indexing.',
        name: 'step',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      stepsTotal: {
        defaultValue: null,
        description: 'The total number of steps in the tour',
        name: 'stepsTotal',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'number'
        }
      },
      subtitle: {
        defaultValue: null,
        description: 'Smaller title text that appears atop each step in the tour. The subtitle gets wrapped in the appropriate heading level.',
        name: 'subtitle',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      title: {
        defaultValue: null,
        description: 'Larger title text specific to this step. The title gets wrapped in the appropriate heading level.',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      decoration: {
        defaultValue: {
          value: 'beacon'
        },
        description: 'Extra visual indication of step location',
        name: 'decoration',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"none" | "beacon"',
          value: [{
            value: '"none"'
          }, {
            value: '"beacon"'
          }]
        }
      },
      footerAction: {
        defaultValue: null,
        description: "Accepts any `ReactNode` to replace the 'Skip tour' link in the footer.\n" + 'Ideally, pass one button or an array of up to 2 buttons.',
        name: 'footerAction',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/tour/tour_step.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode | ReactNode[]'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'EuiPopoverProps', 'DOMAttributes']
  };
} catch (e) {}