/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { forwardRef } from 'react';
import PropTypes from "prop-types";
import { useEuiMemoizedStyles } from '../../services';
import { euiImageCaptionStyles } from './image_caption.styles';
export var EuiImageCaption = forwardRef(({
  caption,
  isOnOverlayMask = false
}, ref) => {
  const styles = useEuiMemoizedStyles(euiImageCaptionStyles);
  const cssStyles = [styles.euiImageCaption, isOnOverlayMask && styles.isOnOverlayMask];
  return caption ? <figcaption ref={ref} css={cssStyles}>
        {caption}
      </figcaption> : null;
});
EuiImageCaption.propTypes = {
  isOnOverlayMask: PropTypes.bool
};
EuiImageCaption.displayName = 'EuiImageCaption';
try {
  EuiImageCaption.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/image/image_caption.tsx',
    description: '',
    displayName: 'EuiImageCaption',
    methods: [],
    props: {
      caption: {
        defaultValue: null,
        description: 'Provides a visible caption to the image',
        name: 'caption',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      isOnOverlayMask: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isOnOverlayMask',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<HTMLDivElement>'
        }
      }
    },
    extendedInterfaces: ['Attributes']
  };
} catch (e) {}