function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from "prop-types";
import { tabbable } from 'tabbable';
import classNames from 'classnames';
import { keys, useGeneratedHtmlId } from '../../../../services';
import { isDOMNode } from '../../../../utils';
import { EuiFocusTrap } from '../../../focus_trap';
import { EuiI18n } from '../../../i18n';

/**
 * This internal utility component is used by all cells, both header and body/footer cells.
 * It always handles:
 *   1. Removing any interactive children from keyboard tab order on cell mount
 *   2. Listening for focus on any interactive children and updating the cell focus context
 *
 * It should *only* render focus traps for:
 *   1. Header cells that are `actions: false` but still have interactive children
 *   2. Body cells that are `isExpandable: false` but still have interactive children
 */
export var HandleInteractiveChildren = function HandleInteractiveChildren(_ref) {
  var cellEl = _ref.cellEl,
    children = _ref.children,
    updateCellFocusContext = _ref.updateCellFocusContext,
    renderFocusTrap = _ref.renderFocusTrap,
    onInteractiveChildrenFound = _ref.onInteractiveChildrenFound;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    hasInteractiveChildren = _useState2[0],
    setHasInteractiveChildren = _useState2[1];

  // On mount, disable all interactive children
  useEffect(function () {
    if (cellEl) {
      var interactives = disableInteractives(cellEl);
      onInteractiveChildrenFound === null || onInteractiveChildrenFound === void 0 || onInteractiveChildrenFound(interactives);
      setHasInteractiveChildren(interactives.length > 0);
    }
  }, [cellEl, onInteractiveChildrenFound]);

  // Ensure that any interactive children that are clicked update the latest cell focus context
  useEffect(function () {
    if (cellEl) {
      var onFocus = function onFocus() {
        return updateCellFocusContext();
      };
      cellEl.addEventListener('focus', onFocus, true); // useCapture listens for focus on children
      return function () {
        cellEl.removeEventListener('focus', onFocus, true);
      };
    }
  }, [cellEl, updateCellFocusContext]);
  var _children = useMemo(function () {
    return ___EmotionJSX(React.Fragment, null, children);
  }, [children]);
  if (!cellEl) return _children; // Do nothing if cell has yet to mount or is unmounting
  if (!renderFocusTrap) return _children; // Cells with default actions / expansion popovers do not need to trap
  if (!hasInteractiveChildren) return _children; // No need to focus trap if no children are interactive

  return ___EmotionJSX(FocusTrappedChildren, {
    cellEl: cellEl
  }, children);
};

/**
 * Cells with interactive children but no cell popover expansion should render a
 * focus trap that can be entered with the Enter key, which cycles keyboard tabs
 * through the cell contents only, and exited with the Escape key
 */
HandleInteractiveChildren.propTypes = {
  cellEl: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.oneOf([null])]),
  updateCellFocusContext: PropTypes.func.isRequired,
  renderFocusTrap: PropTypes.bool,
  onInteractiveChildrenFound: PropTypes.func
};
export var FocusTrappedChildren = ({
  cellEl,
  children
}) => {
  const [isCellEntered, setIsCellEntered] = useState(false);
  const [isExited, setExited] = useState(false);
  const ariaDescribedById = useGeneratedHtmlId({
    suffix: 'focusTrapHint'
  });

  // direct DOM manipulation as workaround to attach required hints
  useEffect(() => {
    const currentAriaDescribedbyId = cellEl.getAttribute('aria-describedby');
    // A11y: splitting ids to be able to append the first hint (sorting)
    // while other hints should follow the keyboard navigation hints
    const [sortingId, ...rest] = currentAriaDescribedbyId?.split(' ') ?? [];
    const remainingIds = rest.join(' ');
    cellEl.setAttribute('aria-describedby', classNames(sortingId, ariaDescribedById, !isCellEntered && remainingIds));
    return () => {
      if (currentAriaDescribedbyId) {
        cellEl.setAttribute('aria-describedby', currentAriaDescribedbyId);
      } else {
        cellEl.removeAttribute('aria-describedby');
      }
    };
  }, [cellEl, ariaDescribedById, isCellEntered]);
  useEffect(() => {
    if (isCellEntered) {
      enableAndFocusInteractives(cellEl);
    } else {
      disableInteractives(cellEl);
    }
  }, [isCellEntered, cellEl]);
  useEffect(() => {
    const onKeyUp = event => {
      switch (event.key) {
        case keys.ENTER:
        case keys.F2:
          event.preventDefault();
          setIsCellEntered(true);
          break;
        case keys.ESCAPE:
          event.preventDefault();
          setIsCellEntered(isCellEntered => {
            if (isCellEntered === true) {
              setExited(true);
              requestAnimationFrame(() => cellEl.focus()); // move focus to cell
              return false;
            } else if (
            // when opened content is closed, we don't want Escape to return to the cell
            // immediately but instead return focus to a trigger as expected
            isCellEntered === false && isDOMNode(event.target) && isDOMNode(event.currentTarget) && event.currentTarget !== event.target && event.currentTarget.contains(event.target)) {
              return true;
            }
            return isCellEntered;
          });
          break;
      }
    };

    // ensures the SR text is reset when navigating to a different cell
    const onBlur = () => setExited(false);
    cellEl.addEventListener('keyup', onKeyUp);
    cellEl.addEventListener('blur', onBlur);
    return () => {
      cellEl.removeEventListener('keyup', onKeyUp);
      cellEl.removeEventListener('blur', onBlur);
    };
  }, [cellEl]);
  return <EuiFocusTrap disabled={!isCellEntered} clickOutsideDisables={true} onDeactivation={() => setIsCellEntered(false)}>
      {children}

      {/**
       * Hints use `hidden` to prevent them from being read by screen readers as regular content.
       * They are still read in JAWS and NVDA via the linking with aria-describedby.
       * VoiceOver does generally not read the column on re-focus after exiting a cell,
       * which mean the exited hint is not read.
       * VoiceOver does react to aria-live (without aria-hidden) but that would causes
       * duplicate output in JAWS/NVDA (reading content & live announcement).
       * Optimizing for Windows screen readers as they have a larger usages.
       */}
      <p id={ariaDescribedById} hidden>
        {isExited && <EuiI18n
      // eslint-disable-next-line local/i18n
      token="euiDataGridCell.focusTrapExitPrompt" default="Exited cell content." />}
        {!isCellEntered && <EuiI18n
      // eslint-disable-next-line local/i18n
      token="euiDataGridCell.focusTrapEnterPrompt" default="Press the Enter key to interact with this cell's contents." />}
      </p>
    </EuiFocusTrap>;
};

/**
 * Utility fns for managing child interactive tabIndex state
 */
FocusTrappedChildren.propTypes = {
  cellEl: PropTypes.any.isRequired
};
const disableInteractives = cell => {
  const interactives = tabbable(cell);
  interactives.forEach(element => {
    element.setAttribute('data-euigrid-tab-managed', 'true');
    element.setAttribute('tabIndex', '-1');
  });
  return interactives;
};
const enableAndFocusInteractives = cell => {
  const interactives = cell.querySelectorAll('[data-euigrid-tab-managed]');
  interactives.forEach((element, i) => {
    element.setAttribute('tabIndex', '0');
    // focus the first element only if we're on the cell and not inside of it
    if (i === 0 && !cell.contains(document.activeElement)) {
      element.focus();
    }
  });
  return interactives;
};
try {
  HandleInteractiveChildren.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/datagrid/body/cell/focus_utils.tsx',
    description: 'This internal utility component is used by all cells, both header and body/footer cells.\n' + 'It always handles:\n' + '  1. Removing any interactive children from keyboard tab order on cell mount\n' + '  2. Listening for focus on any interactive children and updating the cell focus context\n' + '\n' + 'It should *only* render focus traps for:\n' + '  1. Header cells that are `actions: false` but still have interactive children\n' + '  2. Body cells that are `isExpandable: false` but still have interactive children',
    displayName: 'HandleInteractiveChildren',
    methods: [],
    props: {
      cellEl: {
        defaultValue: null,
        description: '',
        name: 'cellEl',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/datagrid/body/cell/focus_utils.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'HTMLElement'
        }
      },
      updateCellFocusContext: {
        defaultValue: null,
        description: '',
        name: 'updateCellFocusContext',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/datagrid/body/cell/focus_utils.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'Function'
        }
      },
      renderFocusTrap: {
        defaultValue: null,
        description: '',
        name: 'renderFocusTrap',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/datagrid/body/cell/focus_utils.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onInteractiveChildrenFound: {
        defaultValue: null,
        description: '',
        name: 'onInteractiveChildrenFound',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/datagrid/body/cell/focus_utils.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(interactiveChildren: FocusableElement[]) => void'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  FocusTrappedChildren.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/datagrid/body/cell/focus_utils.tsx',
    description: 'Cells with interactive children but no cell popover expansion should render a\n' + 'focus trap that can be entered with the Enter key, which cycles keyboard tabs\n' + 'through the cell contents only, and exited with the Escape key',
    displayName: 'FocusTrappedChildren',
    methods: [],
    props: {
      cellEl: {
        defaultValue: null,
        description: '',
        name: 'cellEl',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/datagrid/body/cell/focus_utils.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'HTMLElement'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}