/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { cx } from '@emotion/css';
import { Global } from '@emotion/react';
import { keysOf } from '../common';
import { useCombinedRefs, useEuiMemoizedStyles } from '../../services';
import { EuiPortal } from '../portal';
import { euiOverlayMaskStyles } from './overlay_mask.styles';
import { euiOverlayMaskBodyStyles } from './overlay_mask_body.styles';
export var EuiOverlayMask = ({
  className,
  children,
  headerZindexLocation = 'above',
  maskRef,
  ...rest
}) => {
  const [overlayMaskNode, setOverlayMaskNode] = useState(null);
  const combinedMaskRef = useCombinedRefs([setOverlayMaskNode, maskRef]);
  const styles = useEuiMemoizedStyles(euiOverlayMaskStyles);
  const cssStyles = cx([styles.euiOverlayMask, styles[`${headerZindexLocation}Header`]]);
  useEffect(() => {
    if (!overlayMaskNode) return;
    keysOf(rest).forEach(key => {
      if (typeof rest[key] !== 'string') {
        throw new Error(`Unhandled property type. EuiOverlayMask property ${key} is not a string.`);
      }
      if (overlayMaskNode) {
        overlayMaskNode.setAttribute(key, rest[key]);
      }
    });
  }, [overlayMaskNode]); // eslint-disable-line react-hooks/exhaustive-deps

  // Note: Use `classList.add/remove` instead of setting the entire `className`
  // so as not to override any existing classes set by `EuiPortal`
  useEffect(() => {
    if (overlayMaskNode) {
      overlayMaskNode.classList.add('euiOverlayMask', cssStyles);
      overlayMaskNode.dataset.relativeToHeader = headerZindexLocation;
      return () => overlayMaskNode.classList.remove(cssStyles);
    }
  }, [overlayMaskNode, cssStyles, headerZindexLocation]);
  useEffect(() => {
    if (className && overlayMaskNode) {
      const classNameArgs = className.split(' '); // The `classList` API doesn't support multiple classes in the same string
      overlayMaskNode.classList.add(...classNameArgs);
      return () => overlayMaskNode.classList.remove(...classNameArgs);
    }
  }, [overlayMaskNode, className]);
  return <EuiPortal portalRef={combinedMaskRef}>
      <Global styles={euiOverlayMaskBodyStyles} />
      {children}
    </EuiPortal>;
};
EuiOverlayMask.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node,
  /**
     * Should the mask visually sit above or below the EuiHeader (controlled by z-index)
     */
  headerZindexLocation: PropTypes.oneOf(["above", "below"]),
  /**
     * React ref to be passed to the wrapping container
     */
  maskRef: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired])
};
try {
  EuiOverlayMask.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/overlay_mask/overlay_mask.tsx',
    description: '',
    displayName: 'EuiOverlayMask',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      color: {
        defaultValue: null,
        description: '',
        name: 'color',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      defaultChecked: {
        defaultValue: null,
        description: '',
        name: 'defaultChecked',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      defaultValue: {
        defaultValue: null,
        description: '',
        name: 'defaultValue',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      suppressContentEditableWarning: {
        defaultValue: null,
        description: '',
        name: 'suppressContentEditableWarning',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      suppressHydrationWarning: {
        defaultValue: null,
        description: '',
        name: 'suppressHydrationWarning',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      accessKey: {
        defaultValue: null,
        description: '',
        name: 'accessKey',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      autoFocus: {
        defaultValue: null,
        description: '',
        name: 'autoFocus',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      contentEditable: {
        defaultValue: null,
        description: '',
        name: 'contentEditable',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      contextMenu: {
        defaultValue: null,
        description: '',
        name: 'contextMenu',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      dir: {
        defaultValue: null,
        description: '',
        name: 'dir',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      draggable: {
        defaultValue: null,
        description: '',
        name: 'draggable',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      hidden: {
        defaultValue: null,
        description: '',
        name: 'hidden',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      lang: {
        defaultValue: null,
        description: '',
        name: 'lang',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      nonce: {
        defaultValue: null,
        description: '',
        name: 'nonce',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      slot: {
        defaultValue: null,
        description: '',
        name: 'slot',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      spellCheck: {
        defaultValue: null,
        description: '',
        name: 'spellCheck',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      style: {
        defaultValue: null,
        description: '',
        name: 'style',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      tabIndex: {
        defaultValue: null,
        description: '',
        name: 'tabIndex',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      translate: {
        defaultValue: null,
        description: '',
        name: 'translate',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      radioGroup: {
        defaultValue: null,
        description: '',
        name: 'radioGroup',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      role: {
        defaultValue: null,
        description: '',
        name: 'role',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      about: {
        defaultValue: null,
        description: '',
        name: 'about',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      content: {
        defaultValue: null,
        description: '',
        name: 'content',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      datatype: {
        defaultValue: null,
        description: '',
        name: 'datatype',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      inlist: {
        defaultValue: null,
        description: '',
        name: 'inlist',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      prefix: {
        defaultValue: null,
        description: '',
        name: 'prefix',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      property: {
        defaultValue: null,
        description: '',
        name: 'property',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      rel: {
        defaultValue: null,
        description: '',
        name: 'rel',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      resource: {
        defaultValue: null,
        description: '',
        name: 'resource',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      rev: {
        defaultValue: null,
        description: '',
        name: 'rev',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      typeof: {
        defaultValue: null,
        description: '',
        name: 'typeof',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      vocab: {
        defaultValue: null,
        description: '',
        name: 'vocab',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      autoCapitalize: {
        defaultValue: null,
        description: '',
        name: 'autoCapitalize',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      autoCorrect: {
        defaultValue: null,
        description: '',
        name: 'autoCorrect',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      autoSave: {
        defaultValue: null,
        description: '',
        name: 'autoSave',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      itemProp: {
        defaultValue: null,
        description: '',
        name: 'itemProp',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      itemScope: {
        defaultValue: null,
        description: '',
        name: 'itemScope',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      itemType: {
        defaultValue: null,
        description: '',
        name: 'itemType',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      itemID: {
        defaultValue: null,
        description: '',
        name: 'itemID',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      itemRef: {
        defaultValue: null,
        description: '',
        name: 'itemRef',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      results: {
        defaultValue: null,
        description: '',
        name: 'results',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      security: {
        defaultValue: null,
        description: '',
        name: 'security',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      unselectable: {
        defaultValue: null,
        description: '',
        name: 'unselectable',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      inputMode: {
        defaultValue: null,
        description: '',
        name: 'inputMode',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      is: {
        defaultValue: null,
        description: '',
        name: 'is',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-activedescendant': {
        defaultValue: null,
        description: '',
        name: 'aria-activedescendant',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-atomic': {
        defaultValue: null,
        description: '',
        name: 'aria-atomic',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-autocomplete': {
        defaultValue: null,
        description: '',
        name: 'aria-autocomplete',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-braillelabel': {
        defaultValue: null,
        description: '',
        name: 'aria-braillelabel',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-brailleroledescription': {
        defaultValue: null,
        description: '',
        name: 'aria-brailleroledescription',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-busy': {
        defaultValue: null,
        description: '',
        name: 'aria-busy',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-checked': {
        defaultValue: null,
        description: '',
        name: 'aria-checked',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-colcount': {
        defaultValue: null,
        description: '',
        name: 'aria-colcount',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-colindex': {
        defaultValue: null,
        description: '',
        name: 'aria-colindex',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-colindextext': {
        defaultValue: null,
        description: '',
        name: 'aria-colindextext',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-colspan': {
        defaultValue: null,
        description: '',
        name: 'aria-colspan',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-controls': {
        defaultValue: null,
        description: '',
        name: 'aria-controls',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-current': {
        defaultValue: null,
        description: '',
        name: 'aria-current',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-describedby': {
        defaultValue: null,
        description: '',
        name: 'aria-describedby',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-description': {
        defaultValue: null,
        description: '',
        name: 'aria-description',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-details': {
        defaultValue: null,
        description: '',
        name: 'aria-details',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-disabled': {
        defaultValue: null,
        description: '',
        name: 'aria-disabled',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-dropeffect': {
        defaultValue: null,
        description: '',
        name: 'aria-dropeffect',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-errormessage': {
        defaultValue: null,
        description: '',
        name: 'aria-errormessage',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-expanded': {
        defaultValue: null,
        description: '',
        name: 'aria-expanded',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-flowto': {
        defaultValue: null,
        description: '',
        name: 'aria-flowto',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-grabbed': {
        defaultValue: null,
        description: '',
        name: 'aria-grabbed',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-haspopup': {
        defaultValue: null,
        description: '',
        name: 'aria-haspopup',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-hidden': {
        defaultValue: null,
        description: '',
        name: 'aria-hidden',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-invalid': {
        defaultValue: null,
        description: '',
        name: 'aria-invalid',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-keyshortcuts': {
        defaultValue: null,
        description: '',
        name: 'aria-keyshortcuts',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-labelledby': {
        defaultValue: null,
        description: '',
        name: 'aria-labelledby',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-level': {
        defaultValue: null,
        description: '',
        name: 'aria-level',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-live': {
        defaultValue: null,
        description: '',
        name: 'aria-live',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-modal': {
        defaultValue: null,
        description: '',
        name: 'aria-modal',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-multiline': {
        defaultValue: null,
        description: '',
        name: 'aria-multiline',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-multiselectable': {
        defaultValue: null,
        description: '',
        name: 'aria-multiselectable',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-orientation': {
        defaultValue: null,
        description: '',
        name: 'aria-orientation',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-owns': {
        defaultValue: null,
        description: '',
        name: 'aria-owns',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-placeholder': {
        defaultValue: null,
        description: '',
        name: 'aria-placeholder',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-posinset': {
        defaultValue: null,
        description: '',
        name: 'aria-posinset',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-pressed': {
        defaultValue: null,
        description: '',
        name: 'aria-pressed',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-readonly': {
        defaultValue: null,
        description: '',
        name: 'aria-readonly',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-relevant': {
        defaultValue: null,
        description: '',
        name: 'aria-relevant',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-required': {
        defaultValue: null,
        description: '',
        name: 'aria-required',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-roledescription': {
        defaultValue: null,
        description: '',
        name: 'aria-roledescription',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-rowcount': {
        defaultValue: null,
        description: '',
        name: 'aria-rowcount',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-rowindex': {
        defaultValue: null,
        description: '',
        name: 'aria-rowindex',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-rowindextext': {
        defaultValue: null,
        description: '',
        name: 'aria-rowindextext',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-rowspan': {
        defaultValue: null,
        description: '',
        name: 'aria-rowspan',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-selected': {
        defaultValue: null,
        description: '',
        name: 'aria-selected',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-setsize': {
        defaultValue: null,
        description: '',
        name: 'aria-setsize',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-sort': {
        defaultValue: null,
        description: '',
        name: 'aria-sort',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-valuemax': {
        defaultValue: null,
        description: '',
        name: 'aria-valuemax',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-valuemin': {
        defaultValue: null,
        description: '',
        name: 'aria-valuemin',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-valuenow': {
        defaultValue: null,
        description: '',
        name: 'aria-valuenow',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-valuetext': {
        defaultValue: null,
        description: '',
        name: 'aria-valuetext',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      dangerouslySetInnerHTML: {
        defaultValue: null,
        description: '',
        name: 'dangerouslySetInnerHTML',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onCopy: {
        defaultValue: null,
        description: '',
        name: 'onCopy',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onCopyCapture: {
        defaultValue: null,
        description: '',
        name: 'onCopyCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onCut: {
        defaultValue: null,
        description: '',
        name: 'onCut',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onCutCapture: {
        defaultValue: null,
        description: '',
        name: 'onCutCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPaste: {
        defaultValue: null,
        description: '',
        name: 'onPaste',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPasteCapture: {
        defaultValue: null,
        description: '',
        name: 'onPasteCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onCompositionEnd: {
        defaultValue: null,
        description: '',
        name: 'onCompositionEnd',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onCompositionEndCapture: {
        defaultValue: null,
        description: '',
        name: 'onCompositionEndCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onCompositionStart: {
        defaultValue: null,
        description: '',
        name: 'onCompositionStart',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onCompositionStartCapture: {
        defaultValue: null,
        description: '',
        name: 'onCompositionStartCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onCompositionUpdate: {
        defaultValue: null,
        description: '',
        name: 'onCompositionUpdate',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onCompositionUpdateCapture: {
        defaultValue: null,
        description: '',
        name: 'onCompositionUpdateCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onFocus: {
        defaultValue: null,
        description: '',
        name: 'onFocus',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onFocusCapture: {
        defaultValue: null,
        description: '',
        name: 'onFocusCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onBlur: {
        defaultValue: null,
        description: '',
        name: 'onBlur',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onBlurCapture: {
        defaultValue: null,
        description: '',
        name: 'onBlurCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onChangeCapture: {
        defaultValue: null,
        description: '',
        name: 'onChangeCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onBeforeInput: {
        defaultValue: null,
        description: '',
        name: 'onBeforeInput',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onBeforeInputCapture: {
        defaultValue: null,
        description: '',
        name: 'onBeforeInputCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onInput: {
        defaultValue: null,
        description: '',
        name: 'onInput',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onInputCapture: {
        defaultValue: null,
        description: '',
        name: 'onInputCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onReset: {
        defaultValue: null,
        description: '',
        name: 'onReset',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onResetCapture: {
        defaultValue: null,
        description: '',
        name: 'onResetCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onSubmit: {
        defaultValue: null,
        description: '',
        name: 'onSubmit',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onSubmitCapture: {
        defaultValue: null,
        description: '',
        name: 'onSubmitCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onInvalid: {
        defaultValue: null,
        description: '',
        name: 'onInvalid',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onInvalidCapture: {
        defaultValue: null,
        description: '',
        name: 'onInvalidCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onLoad: {
        defaultValue: null,
        description: '',
        name: 'onLoad',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onLoadCapture: {
        defaultValue: null,
        description: '',
        name: 'onLoadCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onError: {
        defaultValue: null,
        description: '',
        name: 'onError',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onErrorCapture: {
        defaultValue: null,
        description: '',
        name: 'onErrorCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onKeyDown: {
        defaultValue: null,
        description: '',
        name: 'onKeyDown',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onKeyDownCapture: {
        defaultValue: null,
        description: '',
        name: 'onKeyDownCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onKeyPress: {
        defaultValue: null,
        description: '',
        name: 'onKeyPress',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onKeyPressCapture: {
        defaultValue: null,
        description: '',
        name: 'onKeyPressCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onKeyUp: {
        defaultValue: null,
        description: '',
        name: 'onKeyUp',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onKeyUpCapture: {
        defaultValue: null,
        description: '',
        name: 'onKeyUpCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onAbort: {
        defaultValue: null,
        description: '',
        name: 'onAbort',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onAbortCapture: {
        defaultValue: null,
        description: '',
        name: 'onAbortCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onCanPlay: {
        defaultValue: null,
        description: '',
        name: 'onCanPlay',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onCanPlayCapture: {
        defaultValue: null,
        description: '',
        name: 'onCanPlayCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onCanPlayThrough: {
        defaultValue: null,
        description: '',
        name: 'onCanPlayThrough',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onCanPlayThroughCapture: {
        defaultValue: null,
        description: '',
        name: 'onCanPlayThroughCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDurationChange: {
        defaultValue: null,
        description: '',
        name: 'onDurationChange',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDurationChangeCapture: {
        defaultValue: null,
        description: '',
        name: 'onDurationChangeCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onEmptied: {
        defaultValue: null,
        description: '',
        name: 'onEmptied',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onEmptiedCapture: {
        defaultValue: null,
        description: '',
        name: 'onEmptiedCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onEncrypted: {
        defaultValue: null,
        description: '',
        name: 'onEncrypted',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onEncryptedCapture: {
        defaultValue: null,
        description: '',
        name: 'onEncryptedCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onEnded: {
        defaultValue: null,
        description: '',
        name: 'onEnded',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onEndedCapture: {
        defaultValue: null,
        description: '',
        name: 'onEndedCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onLoadedData: {
        defaultValue: null,
        description: '',
        name: 'onLoadedData',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onLoadedDataCapture: {
        defaultValue: null,
        description: '',
        name: 'onLoadedDataCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onLoadedMetadata: {
        defaultValue: null,
        description: '',
        name: 'onLoadedMetadata',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onLoadedMetadataCapture: {
        defaultValue: null,
        description: '',
        name: 'onLoadedMetadataCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onLoadStart: {
        defaultValue: null,
        description: '',
        name: 'onLoadStart',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onLoadStartCapture: {
        defaultValue: null,
        description: '',
        name: 'onLoadStartCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPause: {
        defaultValue: null,
        description: '',
        name: 'onPause',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPauseCapture: {
        defaultValue: null,
        description: '',
        name: 'onPauseCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPlay: {
        defaultValue: null,
        description: '',
        name: 'onPlay',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPlayCapture: {
        defaultValue: null,
        description: '',
        name: 'onPlayCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPlaying: {
        defaultValue: null,
        description: '',
        name: 'onPlaying',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPlayingCapture: {
        defaultValue: null,
        description: '',
        name: 'onPlayingCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onProgress: {
        defaultValue: null,
        description: '',
        name: 'onProgress',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onProgressCapture: {
        defaultValue: null,
        description: '',
        name: 'onProgressCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onRateChange: {
        defaultValue: null,
        description: '',
        name: 'onRateChange',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onRateChangeCapture: {
        defaultValue: null,
        description: '',
        name: 'onRateChangeCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onResize: {
        defaultValue: null,
        description: '',
        name: 'onResize',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onResizeCapture: {
        defaultValue: null,
        description: '',
        name: 'onResizeCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onSeeked: {
        defaultValue: null,
        description: '',
        name: 'onSeeked',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onSeekedCapture: {
        defaultValue: null,
        description: '',
        name: 'onSeekedCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onSeeking: {
        defaultValue: null,
        description: '',
        name: 'onSeeking',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onSeekingCapture: {
        defaultValue: null,
        description: '',
        name: 'onSeekingCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onStalled: {
        defaultValue: null,
        description: '',
        name: 'onStalled',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onStalledCapture: {
        defaultValue: null,
        description: '',
        name: 'onStalledCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onSuspend: {
        defaultValue: null,
        description: '',
        name: 'onSuspend',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onSuspendCapture: {
        defaultValue: null,
        description: '',
        name: 'onSuspendCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onTimeUpdate: {
        defaultValue: null,
        description: '',
        name: 'onTimeUpdate',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onTimeUpdateCapture: {
        defaultValue: null,
        description: '',
        name: 'onTimeUpdateCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onVolumeChange: {
        defaultValue: null,
        description: '',
        name: 'onVolumeChange',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onVolumeChangeCapture: {
        defaultValue: null,
        description: '',
        name: 'onVolumeChangeCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onWaiting: {
        defaultValue: null,
        description: '',
        name: 'onWaiting',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onWaitingCapture: {
        defaultValue: null,
        description: '',
        name: 'onWaitingCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onAuxClick: {
        defaultValue: null,
        description: '',
        name: 'onAuxClick',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onAuxClickCapture: {
        defaultValue: null,
        description: '',
        name: 'onAuxClickCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onClickCapture: {
        defaultValue: null,
        description: '',
        name: 'onClickCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onContextMenu: {
        defaultValue: null,
        description: '',
        name: 'onContextMenu',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onContextMenuCapture: {
        defaultValue: null,
        description: '',
        name: 'onContextMenuCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDoubleClick: {
        defaultValue: null,
        description: '',
        name: 'onDoubleClick',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDoubleClickCapture: {
        defaultValue: null,
        description: '',
        name: 'onDoubleClickCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDrag: {
        defaultValue: null,
        description: '',
        name: 'onDrag',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDragCapture: {
        defaultValue: null,
        description: '',
        name: 'onDragCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDragEnd: {
        defaultValue: null,
        description: '',
        name: 'onDragEnd',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDragEndCapture: {
        defaultValue: null,
        description: '',
        name: 'onDragEndCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDragEnter: {
        defaultValue: null,
        description: '',
        name: 'onDragEnter',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDragEnterCapture: {
        defaultValue: null,
        description: '',
        name: 'onDragEnterCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDragExit: {
        defaultValue: null,
        description: '',
        name: 'onDragExit',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDragExitCapture: {
        defaultValue: null,
        description: '',
        name: 'onDragExitCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDragLeave: {
        defaultValue: null,
        description: '',
        name: 'onDragLeave',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDragLeaveCapture: {
        defaultValue: null,
        description: '',
        name: 'onDragLeaveCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDragOver: {
        defaultValue: null,
        description: '',
        name: 'onDragOver',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDragOverCapture: {
        defaultValue: null,
        description: '',
        name: 'onDragOverCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDragStart: {
        defaultValue: null,
        description: '',
        name: 'onDragStart',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDragStartCapture: {
        defaultValue: null,
        description: '',
        name: 'onDragStartCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDrop: {
        defaultValue: null,
        description: '',
        name: 'onDrop',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onDropCapture: {
        defaultValue: null,
        description: '',
        name: 'onDropCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onMouseDown: {
        defaultValue: null,
        description: '',
        name: 'onMouseDown',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onMouseDownCapture: {
        defaultValue: null,
        description: '',
        name: 'onMouseDownCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onMouseEnter: {
        defaultValue: null,
        description: '',
        name: 'onMouseEnter',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onMouseLeave: {
        defaultValue: null,
        description: '',
        name: 'onMouseLeave',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onMouseMove: {
        defaultValue: null,
        description: '',
        name: 'onMouseMove',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onMouseMoveCapture: {
        defaultValue: null,
        description: '',
        name: 'onMouseMoveCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onMouseOut: {
        defaultValue: null,
        description: '',
        name: 'onMouseOut',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onMouseOutCapture: {
        defaultValue: null,
        description: '',
        name: 'onMouseOutCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onMouseOver: {
        defaultValue: null,
        description: '',
        name: 'onMouseOver',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onMouseOverCapture: {
        defaultValue: null,
        description: '',
        name: 'onMouseOverCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onMouseUp: {
        defaultValue: null,
        description: '',
        name: 'onMouseUp',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onMouseUpCapture: {
        defaultValue: null,
        description: '',
        name: 'onMouseUpCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onSelect: {
        defaultValue: null,
        description: '',
        name: 'onSelect',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onSelectCapture: {
        defaultValue: null,
        description: '',
        name: 'onSelectCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onTouchCancel: {
        defaultValue: null,
        description: '',
        name: 'onTouchCancel',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onTouchCancelCapture: {
        defaultValue: null,
        description: '',
        name: 'onTouchCancelCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onTouchEnd: {
        defaultValue: null,
        description: '',
        name: 'onTouchEnd',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onTouchEndCapture: {
        defaultValue: null,
        description: '',
        name: 'onTouchEndCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onTouchMove: {
        defaultValue: null,
        description: '',
        name: 'onTouchMove',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onTouchMoveCapture: {
        defaultValue: null,
        description: '',
        name: 'onTouchMoveCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onTouchStart: {
        defaultValue: null,
        description: '',
        name: 'onTouchStart',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onTouchStartCapture: {
        defaultValue: null,
        description: '',
        name: 'onTouchStartCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPointerDown: {
        defaultValue: null,
        description: '',
        name: 'onPointerDown',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPointerDownCapture: {
        defaultValue: null,
        description: '',
        name: 'onPointerDownCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPointerMove: {
        defaultValue: null,
        description: '',
        name: 'onPointerMove',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPointerMoveCapture: {
        defaultValue: null,
        description: '',
        name: 'onPointerMoveCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPointerUp: {
        defaultValue: null,
        description: '',
        name: 'onPointerUp',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPointerUpCapture: {
        defaultValue: null,
        description: '',
        name: 'onPointerUpCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPointerCancel: {
        defaultValue: null,
        description: '',
        name: 'onPointerCancel',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPointerCancelCapture: {
        defaultValue: null,
        description: '',
        name: 'onPointerCancelCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPointerEnter: {
        defaultValue: null,
        description: '',
        name: 'onPointerEnter',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPointerLeave: {
        defaultValue: null,
        description: '',
        name: 'onPointerLeave',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPointerOver: {
        defaultValue: null,
        description: '',
        name: 'onPointerOver',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPointerOverCapture: {
        defaultValue: null,
        description: '',
        name: 'onPointerOverCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPointerOut: {
        defaultValue: null,
        description: '',
        name: 'onPointerOut',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onPointerOutCapture: {
        defaultValue: null,
        description: '',
        name: 'onPointerOutCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onGotPointerCapture: {
        defaultValue: null,
        description: '',
        name: 'onGotPointerCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onGotPointerCaptureCapture: {
        defaultValue: null,
        description: '',
        name: 'onGotPointerCaptureCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onLostPointerCapture: {
        defaultValue: null,
        description: '',
        name: 'onLostPointerCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onLostPointerCaptureCapture: {
        defaultValue: null,
        description: '',
        name: 'onLostPointerCaptureCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onScroll: {
        defaultValue: null,
        description: '',
        name: 'onScroll',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onScrollCapture: {
        defaultValue: null,
        description: '',
        name: 'onScrollCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onWheel: {
        defaultValue: null,
        description: '',
        name: 'onWheel',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onWheelCapture: {
        defaultValue: null,
        description: '',
        name: 'onWheelCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onAnimationStart: {
        defaultValue: null,
        description: '',
        name: 'onAnimationStart',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onAnimationStartCapture: {
        defaultValue: null,
        description: '',
        name: 'onAnimationStartCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onAnimationEnd: {
        defaultValue: null,
        description: '',
        name: 'onAnimationEnd',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onAnimationEndCapture: {
        defaultValue: null,
        description: '',
        name: 'onAnimationEndCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onAnimationIteration: {
        defaultValue: null,
        description: '',
        name: 'onAnimationIteration',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onAnimationIterationCapture: {
        defaultValue: null,
        description: '',
        name: 'onAnimationIterationCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onTransitionEnd: {
        defaultValue: null,
        description: '',
        name: 'onTransitionEnd',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      onTransitionEndCapture: {
        defaultValue: null,
        description: '',
        name: 'onTransitionEndCapture',
        parent: undefined,
        declarations: undefined,
        required: false,
        type: {
          name: 'string'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/src/components/overlay_mask/overlay_mask.tsx',
          name: 'EuiOverlayMaskInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/overlay_mask/overlay_mask.tsx',
          name: 'EuiOverlayMaskInterface'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      headerZindexLocation: {
        defaultValue: {
          value: 'above'
        },
        description: 'Should the mask visually sit above or below the EuiHeader (controlled by z-index)',
        name: 'headerZindexLocation',
        parent: {
          fileName: 'eui/src/components/overlay_mask/overlay_mask.tsx',
          name: 'EuiOverlayMaskInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/overlay_mask/overlay_mask.tsx',
          name: 'EuiOverlayMaskInterface'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"above" | "below"',
          value: [{
            value: '"above"'
          }, {
            value: '"below"'
          }]
        }
      },
      maskRef: {
        defaultValue: null,
        description: 'React ref to be passed to the wrapping container',
        name: 'maskRef',
        parent: {
          fileName: 'eui/src/components/overlay_mask/overlay_mask.tsx',
          name: 'EuiOverlayMaskInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/overlay_mask/overlay_mask.tsx',
          name: 'EuiOverlayMaskInterface'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement> | MutableRefObject<HTMLDivElement>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'EuiOverlayMaskInterface']
  };
} catch (e) {}