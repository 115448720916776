import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import React from 'react';
import PropTypes from "prop-types";
import { EuiCode, EuiColorPickerSwatch } from '../../../../../src';

// @ts-ignore Importing from JS
import { useJsonVars } from '../_json/_get_json_vars';
import { ThemeExample } from '../_components/_theme_example';
import { ThemeValuesTable } from '../_components/_theme_values_table';
var euiBorders = ['euiBorderThin', 'euiBorderThick', 'euiBorderEditable'];
export var TypesSass = function TypesSass(_ref) {
  var description = _ref.description;
  var values = useJsonVars();
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeExample, {
    title: ___EmotionJSX("code", null, "$euiBorder[Type]"),
    description: description,
    example: ___EmotionJSX("div", {
      className: 'guideSass__border guideSass__border--euiBorderThin'
    }, ___EmotionJSX("strong", null, "border: ".concat(values.euiBorderThin, ";"))),
    snippet: 'border: $euiBorderThin;',
    snippetLanguage: "scss"
  }));
};
TypesSass.propTypes = {
  description: PropTypes.node
};
export var TypesValuesSass = function TypesValuesSass() {
  var values = useJsonVars();
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeValuesTable, {
    items: euiBorders.map(function (type) {
      return {
        id: type,
        token: "$".concat(type),
        value: values[type]
      };
    }),
    render: function render(item) {
      return ___EmotionJSX(EuiColorPickerSwatch, {
        showToolTip: false,
        color: 'transparent',
        disabled: true,
        className: "guideSass__border--".concat(item.id)
      });
    }
  }));
};
export var ColorSass = function ColorSass(_ref2) {
  var description = _ref2.description;
  var token = 'euiBorderColor';
  var color = useJsonVars()[token];
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeExample, {
    title: ___EmotionJSX("code", null, "$", token),
    description: description,
    example: ___EmotionJSX("div", {
      className: 'guideSass__border guideSass__border--euiBorderThin'
    }, ___EmotionJSX("strong", null, "border-color: ".concat(color, ";"))),
    snippet: "border-color: $".concat(token, ";"),
    snippetLanguage: "scss"
  }));
};
ColorSass.propTypes = {
  description: PropTypes.node
};
export var ColorValuesSass = function ColorValuesSass() {
  var token = 'euiBorderColor';
  var color = useJsonVars()[token];
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeValuesTable, {
    items: [token].map(function (type) {
      return {
        id: type,
        token: "$".concat(type),
        value: color
      };
    }),
    render: function render() {
      return ___EmotionJSX(EuiColorPickerSwatch, {
        showToolTip: false,
        color: color,
        disabled: true
      });
    }
  }));
};
var euiBorderWidths = ['euiBorderWidthThin', 'euiBorderWidthThick'];
export var WidthSass = function WidthSass(_ref3) {
  var description = _ref3.description;
  var values = useJsonVars();
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeExample, {
    title: ___EmotionJSX("code", null, "$euiBorderWidth[Size]"),
    description: ___EmotionJSX("p", null, description, ___EmotionJSX(EuiCode, {
      language: "sass"
    }, "$euiBorderColor"), "."),
    example: ___EmotionJSX("div", {
      className: 'guideSass__border guideSass__border--thickDashed'
    }, ___EmotionJSX("strong", null, "border: ".concat(values.euiBorderWidthThick, " dashed ").concat(values.euiBorderColor, ";"))),
    snippet: 'border: $euiBorderWidthThick dashed $euiBorderColor;',
    snippetLanguage: "scss"
  }));
};
WidthSass.propTypes = {
  description: PropTypes.node
};
export var WidthValuesSass = function WidthValuesSass() {
  var values = useJsonVars();
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ThemeValuesTable, {
    items: euiBorderWidths.map(function (type) {
      return {
        id: type,
        token: "$".concat(type),
        value: values[type]
      };
    }),
    render: function render(item) {
      return ___EmotionJSX(EuiColorPickerSwatch, {
        showToolTip: false,
        color: 'transparent',
        disabled: true,
        className: "guideSass__border--".concat(item.id)
      });
    }
  }));
};
var euiBorderRadii = ['euiBorderRadius', 'euiBorderRadiusSmall'];
export var RadiusSass = ({
  description
}) => {
  const values = useJsonVars();
  return <>
      <ThemeExample title={<code>$euiBorderRadius[Size?]</code>} description={description} example={<div className={'guideSass__border guideSass__border--euiBorderRadius'}>
            <strong>{`border-radius: ${values.euiBorderRadius};`}</strong>
          </div>} snippet={'border-radius: $euiBorderRadius;'} snippetLanguage="scss" />
    </>;
};
RadiusSass.propTypes = {
  description: PropTypes.node
};
export const RadiusValuesSass = () => {
  const values = useJsonVars();
  return <>
      <ThemeValuesTable items={euiBorderRadii.map(type => {
      return {
        id: type,
        token: `$${type}`,
        value: values[type]
      };
    })} render={item => <EuiColorPickerSwatch showToolTip={false} color={'transparent'} disabled className={`guideSass__border--${item.id}`} />} />
    </>;
};
try {
  TypesSass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/borders/_border_sass.tsx',
    description: '',
    displayName: 'TypesSass',
    methods: [],
    props: {
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'React.ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  ColorSass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/borders/_border_sass.tsx',
    description: '',
    displayName: 'ColorSass',
    methods: [],
    props: {
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'React.ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  WidthSass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/borders/_border_sass.tsx',
    description: '',
    displayName: 'WidthSass',
    methods: [],
    props: {
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'React.ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}
try {
  RadiusSass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/views/theme/borders/_border_sass.tsx',
    description: '',
    displayName: 'RadiusSass',
    methods: [],
    props: {
      description: {
        defaultValue: null,
        description: '',
        name: 'description',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/views/theme/_props.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'React.ReactNode'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}