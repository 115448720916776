/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { useEuiMemoizedStyles } from '../../services';
import { useEuiI18n } from '../i18n';
import { EuiIcon } from '../icon';
import { EuiScreenReaderOnly } from '../accessibility';
import { euiImageButtonStyles, euiImageButtonIconStyles } from './image_button.styles';
var fullScreenIconColorMap = {
  light: 'ghost',
  dark: 'default'
};
export var EuiImageButton = ({
  hasAlt,
  hasShadow,
  children,
  onClick,
  onKeyDown,
  isFullScreen,
  isFullWidth,
  fullScreenIconColor = 'light',
  ...rest
}) => {
  const buttonStyles = useEuiMemoizedStyles(euiImageButtonStyles);
  const cssButtonStyles = [buttonStyles.euiImageButton, hasShadow ? buttonStyles.hasShadowHover : buttonStyles.shadowHover, !isFullScreen && isFullWidth && buttonStyles.fullWidth];
  const iconStyles = useEuiMemoizedStyles(euiImageButtonIconStyles);
  const cssIconStyles = [iconStyles.euiImageButton__icon, iconStyles.openFullScreen];
  const openFullScreenInstructions = useEuiI18n('euiImageButton.openFullScreen', 'Click to open this image in fullscreen mode');
  const closeFullScreenInstructions = useEuiI18n('euiImageButton.closeFullScreen', 'Press Escape or click to close image fullscreen mode');
  const iconColor = fullScreenIconColorMap[fullScreenIconColor];
  return <button type="button" css={cssButtonStyles} onClick={onClick} onKeyDown={onKeyDown} {...rest}>
      {isFullScreen &&
    // In fullscreen mode, instructions should come first to allow screen reader
    // users to quickly exit vs. potentially reading out long/unskippable alt text
    <EuiScreenReaderOnly>
          <p>
            {closeFullScreenInstructions}
            {hasAlt && ' — '}
          </p>
        </EuiScreenReaderOnly>}

      {children}

      {!isFullScreen && <div css={cssIconStyles}>
          <EuiScreenReaderOnly>
            <p>
              {hasAlt && ' — '}
              {openFullScreenInstructions}
            </p>
          </EuiScreenReaderOnly>
          <EuiIcon type="fullScreen" color={iconColor} />
        </div>}
    </button>;
};
EuiImageButton.propTypes = {
  hasAlt: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  isFullWidth: PropTypes.bool.isRequired,
  isFullScreen: PropTypes.bool
};
try {
  EuiImageButton.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/image/image_button.tsx',
    description: '',
    displayName: 'EuiImageButton',
    methods: [],
    props: {
      hasShadow: {
        defaultValue: null,
        description: 'When set to `true` (default) will apply a slight shadow to the image',
        name: 'hasShadow',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullScreenIconColor: {
        defaultValue: {
          value: 'light'
        },
        description: 'Changes the color of the icon that floats above the image when it can be clicked to fullscreen.\n' + 'The default value of `light` is fine unless your image has a white background, in which case you should change it to `dark`.',
        name: 'fullScreenIconColor',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'EuiImageButtonIconColor',
          value: [{
            value: '"light"'
          }, {
            value: '"dark"'
          }]
        }
      },
      hasAlt: {
        defaultValue: null,
        description: '',
        name: 'hasAlt',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '() => void'
        }
      },
      onKeyDown: {
        defaultValue: null,
        description: '',
        name: 'onKeyDown',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(e: KeyboardEvent<Element>) => void'
        }
      },
      isFullWidth: {
        defaultValue: null,
        description: '',
        name: 'isFullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      isFullScreen: {
        defaultValue: null,
        description: '',
        name: 'isFullScreen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}