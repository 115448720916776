/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import { EuiPopover } from './popover';
import { EuiPortal } from '../portal';
/**
 * Injects the EuiPopover next to the button via EuiPortal
 * then the button element is moved into the popover dom.
 * On unmount, the button is moved back to its original location.
 */
export class EuiWrappingPopover extends Component {
  portal = null;
  componentWillUnmount() {
    if (this.props.button.parentNode) {
      if (this.portal) {
        this.portal.insertAdjacentElement('beforebegin', this.props.button);
      }
    }
  }
  setPortalRef = node => {
    this.portal = node;
  };
  setAnchorRef = node => {
    node?.insertAdjacentElement('beforebegin', this.props.button);
  };
  render() {
    const {
      button,
      ...rest
    } = this.props;
    return <EuiPortal portalRef={this.setPortalRef} insert={{
      sibling: this.props.button,
      position: 'after'
    }}>
        <EuiPopover {...rest} button={<div ref={this.setAnchorRef} className="euiWrappingPopover__anchor" />} />
      </EuiPortal>;
  }
}
EuiWrappingPopover.propTypes = {
  button: PropTypes.any.isRequired
};
try {
  EuiWrappingPopover.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/popover/wrapping_popover.tsx',
    description: 'Injects the EuiPopover next to the button via EuiPortal\n' + 'then the button element is moved into the popover dom.\n' + 'On unmount, the button is moved back to its original location.',
    displayName: 'EuiWrappingPopover',
    methods: [],
    props: {
      button: {
        defaultValue: null,
        description: '',
        name: 'button',
        parent: {
          fileName: 'eui/src/components/popover/wrapping_popover.tsx',
          name: 'EuiWrappingPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/wrapping_popover.tsx',
          name: 'EuiWrappingPopoverProps'
        }],
        required: true,
        type: {
          name: 'HTMLElement'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Provide a name to the popover panel\n' + 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-labelledby': {
        defaultValue: null,
        description: 'Alternative option to `aria-label` that takes an `id`.\n' + 'Usually takes the `id` of the popover title\n' + 'Identifies the element (or elements) that labels the current element.\n' + '@see aria-describedby.',
        name: 'aria-labelledby',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      container: {
        defaultValue: null,
        description: "Restrict the popover's position within this element",
        name: 'container',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'HTMLElement'
        }
      },
      display: {
        defaultValue: null,
        description: 'CSS display type for both the popover and anchor',
        name: 'display',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Display'
        }
      },
      offset: {
        defaultValue: null,
        description: 'Distance away from the anchor that the popover will render',
        name: 'offset',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      panelRef: {
        defaultValue: null,
        description: '',
        name: 'panelRef',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: '(instance: HTMLElement) => void'
        }
      },
      ownFocus: {
        defaultValue: null,
        description: 'Traps tab focus within the popover contents',
        name: 'ownFocus',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      focusTrapProps: {
        defaultValue: null,
        description: 'Object of props passed to EuiFocusTrap',
        name: 'focusTrapProps',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Partial<EuiFocusTrapProps>'
        }
      },
      isOpen: {
        defaultValue: null,
        description: 'Visibility state of the popover',
        name: 'isOpen',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      anchorPosition: {
        defaultValue: null,
        description: 'Alignment of the popover and arrow relative to the button',
        name: 'anchorPosition',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"upCenter" | "upLeft" | "upRight" | "downCenter" | "downLeft" | "downRight" | "leftCenter" | "leftUp" | "leftDown" | "rightCenter" | "rightUp" | "rightDown"',
          value: [{
            value: '"upCenter"'
          }, {
            value: '"upLeft"'
          }, {
            value: '"upRight"'
          }, {
            value: '"downCenter"'
          }, {
            value: '"downLeft"'
          }, {
            value: '"downRight"'
          }, {
            value: '"leftCenter"'
          }, {
            value: '"leftUp"'
          }, {
            value: '"leftDown"'
          }, {
            value: '"rightCenter"'
          }, {
            value: '"rightUp"'
          }, {
            value: '"rightDown"'
          }]
        }
      },
      attachToAnchor: {
        defaultValue: null,
        description: 'Style and position alteration for arrow-less attachment.\n' + 'Intended for use with inputs as anchors, e.g. EuiInputPopover',
        name: 'attachToAnchor',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      closePopover: {
        defaultValue: null,
        description: 'Callback to handle hiding of the popover',
        name: 'closePopover',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: true,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      hasArrow: {
        defaultValue: null,
        description: 'Show arrow indicating to originating button',
        name: 'hasArrow',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      initialFocus: {
        defaultValue: null,
        description: 'Specifies what element should initially have focus; Can be a DOM\n' + 'node, or a selector string (which will be passed to\n' + 'document.querySelector() to find the DOM node), or a function that\n' + 'returns a DOM node.\n' + '\n' + 'If not passed, initial focus defaults to the popover panel.',
        name: 'initialFocus',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'ElementTarget'
        }
      },
      insert: {
        defaultValue: null,
        description: 'Passed directly to EuiPortal for DOM positioning. Both properties are\n' + 'required if prop is specified',
        name: 'insert',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: '{ sibling: HTMLElement; position: "before" | "after"; }'
        }
      },
      panelClassName: {
        defaultValue: null,
        description: 'Custom class added to the EuiPanel containing the popover contents',
        name: 'panelClassName',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      panelPaddingSize: {
        defaultValue: null,
        description: 'EuiPanel padding on all sides',
        name: 'panelPaddingSize',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      panelStyle: {
        defaultValue: null,
        description: 'Standard DOM `style` attribute. Passed to the EuiPanel',
        name: 'panelStyle',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      panelProps: {
        defaultValue: null,
        description: 'Object of props passed to EuiPanel. See #EuiPopoverPanelProps',
        name: 'panelProps',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Omit<_EuiPanelDivlike, "color" | "style" | "hasBorder" | "hasShadow">'
        }
      },
      popoverScreenReaderText: {
        defaultValue: null,
        description: 'Optional screen reader instructions to announce upon popover open,\n' + "in addition to EUI's default popover instructions for Escape on close.\n" + 'Useful for popovers that may have additional keyboard capabilities such as\n' + 'arrow navigation.',
        name: 'popoverScreenReaderText',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      popoverRef: {
        defaultValue: null,
        description: '',
        name: 'popoverRef',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      },
      repositionOnScroll: {
        defaultValue: null,
        description: "When `true`, the popover's position is re-calculated when the user\n" + 'scrolls, this supports having fixed-position popover anchors. When nesting\n' + 'an `EuiPopover` in a scrollable container, `repositionOnScroll` should be `true`',
        name: 'repositionOnScroll',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      repositionToCrossAxis: {
        defaultValue: {
          value: 'true'
        },
        description: 'By default, popovers will attempt to position themselves along the initial\n' + 'axis specified. If there is not enough room either vertically or horizontally\n' + 'however, the popover will attempt to reposition itself along the secondary\n' + 'cross axis if there is room there instead.\n' + '\n' + 'If you do not not want this repositioning to occur (and it is acceptable for\n' + 'the popover to appear offscreen), set this to false to disable this behavior.',
        name: 'repositionToCrossAxis',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      zIndex: {
        defaultValue: null,
        description: 'By default, popover content inherits the z-index of the anchor\n' + 'component; pass `zIndex` to override',
        name: 'zIndex',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      buffer: {
        defaultValue: {
          value: '16'
        },
        description: 'Minimum distance between the popover and the bounding container;\n' + 'Pass an array of 4 values to adjust each side differently: `[top, right, bottom, left]`',
        name: 'buffer',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number | [number, number, number, number]'
        }
      },
      arrowChildren: {
        defaultValue: null,
        description: 'Element to pass as the child element of the arrow;\n' + 'Use case is typically limited to an accompanying `EuiBeacon`',
        name: 'arrowChildren',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      onPositionChange: {
        defaultValue: null,
        description: 'Function callback for when the popover positon changes',
        name: 'onPositionChange',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: '(position: EuiPopoverPosition) => void'
        }
      }
    },
    extendedInterfaces: ['EuiWrappingPopoverProps', 'HTMLAttributes', 'AriaAttributes', 'EuiPopoverProps', 'DOMAttributes', 'CommonProps']
  };
} catch (e) {}