function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
import PropTypes from "prop-types";
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import React, { useState, useContext, useCallback, useMemo } from 'react';
import { getTokenName } from '@elastic/eui-theme-common';
import { ThemeContext } from '../../../components/with_theme';
import { EuiText, EuiSpacer, EuiLink, EuiTitle, EuiPanel, EuiCode, EuiDescribedFormGroup, EuiButtonGroup, useEuiTheme } from '../../../../../src';
import { ColorSectionSass, coreColors as sassCoreColors, grayColors as sassGrayColors, backgroundBaseColors as sassBackgroundBaseColors, backgroundLightColors as sassBackgroundLightColors, backgroundFilledColors as sassBackgroundFilledColors } from './_contrast_sass';
import { ColorSectionJS } from './_contrast_js';
import { brandKeys, shadeKeys } from './_color_js';
import { ContrastSlider } from './_contrast_slider';
import { ratingAA } from './_contrast_utilities';
import { BACKGROUND_COLORS } from '../../../../../src/global_styling';
import { BUTTON_COLORS, euiButtonColor } from '../../../../../src/global_styling/mixins/_button';
import { GuideSection } from '../../../components/guide_section/guide_section';

// This array is used inside routes.js to create the sidenav sub-sections
export var contrastSections = [{
  title: 'Body background color',
  id: 'body-background-color'
}, {
  title: 'Brand colors',
  id: 'brand-colors'
}, {
  title: 'Shades',
  id: 'shades'
}, {
  title: 'Background colors',
  id: 'background-colors'
}];
var background_colors = BACKGROUND_COLORS.filter(function (color) {
  return color !== 'transparent';
});
var brand_background_colors = [].concat(_toConsumableArray(BACKGROUND_COLORS), ['text']).filter(function (color) {
  return !['transparent', 'plain', 'subdued', 'disabled', 'highlighted'].includes(color);
});
var backgroundButtons = ['container',
// 'hover', Commenting out for now since contrast can't be calculated on transparent values
'button'].map(function (m) {
  return {
    id: m,
    label: m
  };
});
export default (function () {
  var _useState = useState(true),
    _useState2 = _slicedToArray(_useState, 2),
    showTextVariants = _useState2[0],
    setShowTextVariants = _useState2[1];
  var _useState3 = useState(4.5),
    _useState4 = _slicedToArray(_useState3, 2),
    contrastValue = _useState4[0],
    setContrastValue = _useState4[1];
  var _useState5 = useState(background_colors),
    _useState6 = _slicedToArray(_useState5, 2),
    backgroundColors = _useState6[0],
    setBackgroundColors = _useState6[1];
  var _useState7 = useState(undefined),
    _useState8 = _slicedToArray(_useState7, 2),
    backgroundFunction = _useState8[0],
    setBackgroundFunction = _useState8[1];
  var _useState9 = useState(backgroundButtons[0].id),
    _useState10 = _slicedToArray(_useState9, 2),
    backgroundSelected = _useState10[0],
    setBackgroundSelected = _useState10[1];
  var switchBackgroundColors = useCallback(function (id) {
    switch (id) {
      case 'container':
        setBackgroundSelected(id);
        setBackgroundColors(background_colors);
        setBackgroundFunction(undefined);
        break;
      case 'button':
        setBackgroundSelected(id);
        setBackgroundColors(BUTTON_COLORS);
        setBackgroundFunction('euiButtonColor(color)');
        break;
    }
  }, []);
  var showSass = useContext(ThemeContext).themeLanguage.includes('sass');
  var backgroundBaseColors = useMemo(function () {
    return backgroundColors.map(function (color) {
      return getTokenName('backgroundBase', color);
    });
  }, [backgroundColors]);
  var backgroundLightColors = brand_background_colors.map(function (color) {
    return getTokenName('backgroundLight', color);
  });
  var backgroundFilledColors = brand_background_colors.map(function (color) {
    return getTokenName('backgroundFilled', color);
  });
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(GuideSection, {
    color: "transparent"
  }, ___EmotionJSX(EuiText, {
    grow: false
  }, ___EmotionJSX("h2", null, "Accessible text contrast"), ___EmotionJSX("p", null, ___EmotionJSX(EuiLink, {
    href: "https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html"
  }, "WCAG specifications"), ' ', "defines specific contrast ratios between foreground text and a background color. The grids below display which color combinations pass that rating. In general you should try to use a color combination that is ", ratingAA, " or above with the exception of using large text."))), ___EmotionJSX("div", null, ___EmotionJSX(GuideSection, {
    color: "warning",
    className: "guideColorsPage__stickySlider",
    css: function css(_ref) {
      var euiTheme = _ref.euiTheme;
      return {
        borderBlock: euiTheme.border.thin
      };
    }
  }, ___EmotionJSX(EuiTitle, {
    size: "xs"
  }, ___EmotionJSX("h3", null, "Use the slider and toggle to adjust the color combinations shown in the sections below.")), ___EmotionJSX(EuiSpacer, {
    size: "m"
  }), ___EmotionJSX(ContrastSlider, {
    contrastValue: contrastValue,
    showTextVariants: showTextVariants,
    onChange: function onChange(sliderValue, toggleChecked) {
      setContrastValue(Number(sliderValue));
      setShowTextVariants(toggleChecked);
    }
  })), ___EmotionJSX(GuideSection, {
    color: "subdued"
  }, ___EmotionJSX(EuiText, {
    grow: false
  }, ___EmotionJSX("h2", {
    id: "".concat(contrastSections[0].id)
  }, "".concat(contrastSections[0].title)), ___EmotionJSX("p", null, "In order to meet contrast minimums, the text variants use the page body color as the denominator for calculating the altered color. Text placed on backgrounds darker than this cannot guarantee contrast acceptance.")), ___EmotionJSX(EuiSpacer, null), showSass ? ___EmotionJSX(ColorSectionSass, {
    color: 'euiPageBackgroundColor',
    minimumContrast: contrastValue,
    showTextVariants: showTextVariants
  }) : ___EmotionJSX(ColorSectionJS, {
    color: 'body',
    minimumContrast: contrastValue,
    showTextVariants: showTextVariants
  })), ___EmotionJSX(GuideSection, {
    color: "transparent"
  }, ___EmotionJSX(EuiText, {
    grow: false
  }, ___EmotionJSX("h2", {
    id: "".concat(contrastSections[1].id)
  }, "".concat(contrastSections[1].title)), ___EmotionJSX("p", null, "We typically only recommend using full black or white on top of brand colors. This can be in the form of full, empty, ink, or ghost shades. ", ___EmotionJSX("strong", null, "Never combine two brand colors."))), ___EmotionJSX(EuiSpacer, null), showSass ? sassCoreColors.map(function (color) {
    return ___EmotionJSX(React.Fragment, {
      key: color
    }, ___EmotionJSX(ColorSectionSass, {
      color: color,
      minimumContrast: contrastValue,
      showTextVariants: false
    }), ___EmotionJSX(EuiSpacer, null));
  }) : brandKeys.map(function (color) {
    return ___EmotionJSX(React.Fragment, {
      key: color
    }, ___EmotionJSX(ColorSectionJS, {
      color: color,
      minimumContrast: contrastValue,
      showTextVariants: false
    }), ___EmotionJSX(EuiSpacer, null));
  })), ___EmotionJSX(GuideSection, {
    color: "subdued"
  }, ___EmotionJSX(EuiText, {
    grow: false
  }, ___EmotionJSX("h2", {
    id: "".concat(contrastSections[2].id)
  }, "".concat(contrastSections[2].title)), ___EmotionJSX("p", null, "Again we recommend sticking with the text variant of brand colors when possible. The opposite may be true when using darker shades as backgrounds.")), ___EmotionJSX(EuiSpacer, null), showSass ? sassGrayColors.map(function (color) {
    return ___EmotionJSX(React.Fragment, {
      key: color
    }, ___EmotionJSX(ColorSectionSass, {
      key: color,
      color: color,
      minimumContrast: contrastValue,
      showTextVariants: showTextVariants
    }), ___EmotionJSX(EuiSpacer, null));
  }) : shadeKeys.map(function (color) {
    return ___EmotionJSX(React.Fragment, {
      key: color
    }, ___EmotionJSX(ColorSectionJS, {
      key: color,
      color: color,
      minimumContrast: contrastValue,
      showTextVariants: showTextVariants
    }), ___EmotionJSX(EuiSpacer, null));
  })), ___EmotionJSX(GuideSection, {
    color: "transparent"
  }, ___EmotionJSX(EuiText, {
    grow: false
  }, ___EmotionJSX("h2", {
    id: "".concat(contrastSections[3].id)
  }, "".concat(contrastSections[3].title))), ___EmotionJSX(EuiSpacer, null), !showSass && ___EmotionJSX(React.Fragment, null, ___EmotionJSX(EuiPanel, {
    color: "accent"
  }, ___EmotionJSX(EuiDescribedFormGroup, {
    fullWidth: true,
    title: ___EmotionJSX("h3", null, "Different colors for different contexts"),
    description: ___EmotionJSX("p", null, "These background colors are pre-defined shades of the brand colors.", ' ', backgroundFunction && ___EmotionJSX(React.Fragment, null, "They are recalled by using the hook", ' ', ___EmotionJSX(EuiCode, null, backgroundFunction)))
  }, ___EmotionJSX(EuiSpacer, null), ___EmotionJSX(EuiButtonGroup, {
    buttonSize: "m",
    legend: "Value measurement to show in table",
    options: backgroundButtons,
    idSelected: backgroundSelected,
    onChange: function onChange(id) {
      return switchBackgroundColors(id);
    },
    color: "accent",
    isFullWidth: true
  }))), ___EmotionJSX(EuiSpacer, null)), ___EmotionJSX(React.Fragment, null, backgroundSelected === 'button' ? !showSass && ___EmotionJSX(BackgroundColorSection, {
    title: "Button background colors",
    backgroundColors: _toConsumableArray(BUTTON_COLORS),
    type: backgroundSelected,
    contrastValue: contrastValue,
    showTextVariants: showTextVariants
  }) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX(BackgroundColorSection, {
    title: "Base background colors",
    backgroundColors: showSass ? sassBackgroundBaseColors : backgroundBaseColors,
    language: showSass ? 'scss' : 'js',
    type: backgroundSelected,
    contrastValue: contrastValue,
    showTextVariants: showTextVariants
  }), ___EmotionJSX(BackgroundColorSection, {
    title: "Light background colors",
    backgroundColors: showSass ? sassBackgroundLightColors : backgroundLightColors,
    language: showSass ? 'scss' : 'js',
    type: backgroundSelected,
    contrastValue: contrastValue,
    showTextVariants: showTextVariants
  }), ___EmotionJSX(BackgroundColorSection, {
    title: "Filled background colors",
    backgroundColors: showSass ? sassBackgroundFilledColors : backgroundFilledColors,
    language: showSass ? 'scss' : 'js',
    type: backgroundSelected,
    contrastValue: contrastValue,
    showTextVariants: showTextVariants
  }))))));
});
var BackgroundColorSection = ({
  title,
  backgroundColors,
  type,
  language = 'js',
  contrastValue,
  showTextVariants
}) => {
  const euiThemeContext = useEuiTheme();
  const {
    euiTheme
  } = euiThemeContext;
  const content = backgroundColors.map(color => {
    switch (type) {
      case 'container':
        return <React.Fragment key={color}>
            {language === 'scss' ? <ColorSectionSass key={color} color={color} minimumContrast={contrastValue} showTextVariants={showTextVariants} /> : <ColorSectionJS key={color} color={color} colorValue={euiTheme.colors[color]} minimumContrast={contrastValue} showTextVariants={showTextVariants} tokenName={`euiTheme.colors.${color}`} />}

            <EuiSpacer />
          </React.Fragment>;
      case 'button':
        return color !== 'disabled' && <React.Fragment key={color}>
              <ColorSectionJS key={color} color={color} colorValue={euiButtonColor(euiThemeContext, color).backgroundColor} hookName="useEuiButtonColorCSS" minimumContrast={contrastValue} showTextVariants={showTextVariants} />
              <EuiSpacer />
            </React.Fragment>;
    }
  });
  return <>
      <EuiTitle size="s">
        <h3>{title}</h3>
      </EuiTitle>
      <EuiSpacer />
      {content}
    </>;
};
BackgroundColorSection.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundColors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  type: PropTypes.string.isRequired,
  language: PropTypes.oneOf(["js", "scss"]),
  contrastValue: PropTypes.number.isRequired,
  showTextVariants: PropTypes.bool.isRequired
};