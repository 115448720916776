import "core-js/modules/es.array.iterator.js";
import PropTypes from "prop-types";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import { EuiI18nConsumer } from '../context';
var defaultFormatter = new Intl.NumberFormat('en');
function defaultFormatNumber(value) {
  return defaultFormatter.format(value);
}
function hasValues(x) {
  return x.values != null;
}
var EuiI18nNumber = props => <EuiI18nConsumer>
    {i18nConfig => {
    const formatNumber = i18nConfig.formatNumber || defaultFormatNumber;
    if (hasValues(props)) {
      return props.children(props.values.map(value => formatNumber(value)));
    }
    const formattedValue = (formatNumber || defaultFormatNumber)(props.value);
    if (props.children) {
      return props.children(formattedValue);
    } else {
      return formattedValue;
    }
  }}
  </EuiI18nConsumer>;
EuiI18nNumber.propTypes = {
  value: PropTypes.number,
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.func.isRequired]),
  values: PropTypes.arrayOf(PropTypes.number.isRequired)
};
export { EuiI18nNumber };
try {
  EuiI18nNumber.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/i18n/i18n_number.tsx',
    description: '',
    displayName: 'EuiI18nNumber',
    methods: [],
    props: {
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/i18n/i18n_number.tsx',
          name: 'EuiI18nNumberValueShape'
        },
        declarations: [{
          fileName: 'eui/src/components/i18n/i18n_number.tsx',
          name: 'EuiI18nNumberValueShape'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      values: {
        defaultValue: null,
        description: '',
        name: 'values',
        parent: {
          fileName: 'eui/src/components/i18n/i18n_number.tsx',
          name: 'EuiI18nNumberValuesShape'
        },
        declarations: [{
          fileName: 'eui/src/components/i18n/i18n_number.tsx',
          name: 'EuiI18nNumberValuesShape'
        }],
        required: false,
        type: {
          name: 'number[]'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactNode to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/src/components/i18n/i18n_number.tsx',
          name: 'EuiI18nNumberValuesShape'
        },
        declarations: [{
          fileName: 'eui/src/components/i18n/i18n_number.tsx',
          name: 'EuiI18nNumberValuesShape'
        }, {
          fileName: 'eui/src/components/i18n/i18n_number.tsx',
          name: 'EuiI18nNumberValueShape'
        }],
        required: true,
        type: {
          name: '(x: ReactNode[]) => ReactElement'
        }
      }
    },
    extendedInterfaces: ['EuiI18nNumberValueShape', 'EuiI18nNumberValuesShape']
  };
} catch (e) {}