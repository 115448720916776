import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useEuiTheme, isColorDark, hexToRgb } from '../../services';
import { EuiIcon } from '../icon';
import { TOKEN_MAP_AMSTERDAM, TOKEN_MAP_BOREALIS } from './token_map';
import { COLORS } from './token_types';
import { euiTokenStyles } from './token.styles';
var isTokenColor = function isTokenColor(color) {
  return COLORS.includes(color);
};
export var EuiToken = ({
  iconType,
  color,
  fill,
  shape,
  size = 's',
  style = {},
  className,
  title,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledby,
  'aria-describedby': ariaDescribedby,
  ...rest
}) => {
  // Set the icon size to the same as the passed size
  // unless they passed `xs` which IconSize doesn't support
  let finalSize = size === 'xs' ? 's' : size;

  // When displaying at the small size, the token specific icons
  // should actually be displayed at medium size
  if (typeof iconType === 'string' && iconType.indexOf('token') === 0 && size === 's') {
    finalSize = 'm';
  }
  const euiTheme = useEuiTheme();
  const {
    hasVisColorAdjustment
  } = euiTheme.euiTheme.flags;
  const TOKEN_MAP = hasVisColorAdjustment ? TOKEN_MAP_AMSTERDAM : TOKEN_MAP_BOREALIS;

  // If the iconType passed is one of the prefab token types,
  // grab its properties
  const tokenDefaults = typeof iconType === 'string' && iconType in TOKEN_MAP ? TOKEN_MAP[iconType] : {};
  const finalColor = color || tokenDefaults.color || 'gray';
  const finalShape = shape || tokenDefaults.shape || 'circle';
  let finalFill = fill || 'light';

  // memoize styles to reduce executing contained color calculations
  const styles = useMemo(() => {
    return euiTokenStyles(euiTheme, finalFill);
  }, [euiTheme, finalFill]);
  let cssStyles = [styles.euiToken, styles[finalShape], styles[finalFill], styles[size]];
  let finalStyle = style;
  if (isTokenColor(finalColor)) {
    cssStyles = [...cssStyles, styles[finalColor]];
  } else if (finalFill === 'none') {
    // When a custom HEX color is passed and the token doesn't have any fill (no background),
    // the icon gets that passed color
    cssStyles = [...cssStyles, styles.customColor];
    finalStyle = {
      color: finalColor,
      ...style
    };
  } else {
    // When a custom HEX color is passed and the token has a fill (light or dark),
    // the background gets the custom color and the icon gets white or black based on the passed color
    // The fill='light' (lightened background) will always be overridden by fill='dark' (opaque background)
    // to better handle custom colors
    const isFinalColorDark = isColorDark(...hexToRgb(finalColor));
    const lightOrDarkColor = isFinalColorDark ? '#FFFFFF' : '#000000';
    cssStyles = [...cssStyles, styles.customColor];
    finalFill = 'dark';
    finalStyle = {
      color: lightOrDarkColor,
      backgroundColor: finalColor,
      ...style
    };
  }
  const classes = classNames('euiToken', className);
  return <span className={classes} css={cssStyles} style={finalStyle} {...rest}>
      <EuiIcon type={iconType} size={finalSize} title={title} aria-label={ariaLabel} aria-labelledby={ariaLabelledby} aria-describedby={ariaDescribedby} />
    </span>;
};
try {
  EuiToken.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/token/token.tsx',
    description: '',
    displayName: 'EuiToken',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      iconType: {
        defaultValue: null,
        description: 'An EUI icon type',
        name: 'iconType',
        parent: {
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        },
        declarations: [{
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        }],
        required: true,
        type: {
          name: 'IconType'
        }
      },
      color: {
        defaultValue: null,
        description: "For best results use one of the vis color names (or 'gray').\n" + "Or supply your own HEX color. The `fill='light'` (lightened background) will always be overridden by `fill='dark'` (solid background).\n" + 'Default: `gray` for glyphs or one of the vis colors for prefab token types',
        name: 'color',
        parent: {
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        },
        declarations: [{
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      shape: {
        defaultValue: null,
        description: 'Outer shape surrounding the icon\nDefault: `circle`',
        name: 'shape',
        parent: {
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        },
        declarations: [{
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"circle" | "square" | "rectangle"',
          value: [{
            value: '"circle"'
          }, {
            value: '"square"'
          }, {
            value: '"rectangle"'
          }]
        }
      },
      fill: {
        defaultValue: null,
        description: '`light` for lightened color with border, `dark` for solid, or `none`\n' + 'Default: `light`',
        name: 'fill',
        parent: {
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        },
        declarations: [{
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"light" | "dark" | "none"',
          value: [{
            value: '"light"'
          }, {
            value: '"dark"'
          }, {
            value: '"none"'
          }]
        }
      },
      size: {
        defaultValue: {
          value: 's'
        },
        description: 'Size of the token',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        },
        declarations: [{
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs" | "l"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }]
        }
      },
      title: {
        defaultValue: null,
        description: "The icon's title. Required for accessibility",
        name: 'title',
        parent: {
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        },
        declarations: [{
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-labelledby': {
        defaultValue: null,
        description: 'Identifies the element (or elements) that labels the current element.\n' + '@see aria-describedby.',
        name: 'aria-labelledby',
        parent: {
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        },
        declarations: [{
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-describedby': {
        defaultValue: null,
        description: 'Identifies the element (or elements) that describes the object.\n' + '@see aria-labelledby',
        name: 'aria-describedby',
        parent: {
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        },
        declarations: [{
          fileName: 'eui/src/components/token/token_types.ts',
          name: 'TokenProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'TokenProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}