/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiI18n } from '../i18n';
import { EuiInnerText } from '../inner_text';
import { isNil } from '../../services/predicate';
import { useEuiTheme, makeHighContrastColor } from '../../services';
import { euiProgressStyles, euiProgressDataStyles, euiProgressLabelStyles, euiProgressValueTextStyles } from './progress.styles';
export var SIZES = ['xs', 's', 'm', 'l'];
export var COLORS = ['primary', 'success', 'warning', 'danger', 'subdued', 'accent', 'accentSecondary', 'vis0', 'vis1', 'vis2', 'vis3', 'vis4', 'vis5', 'vis6', 'vis7', 'vis8', 'vis9'];
export var POSITIONS = ['fixed', 'absolute', 'static'];
export var EuiProgress = ({
  className,
  color = 'success',
  size = 'm',
  position = 'static',
  max,
  valueText = false,
  label,
  value,
  labelProps,
  ...rest
}) => {
  const determinate = !isNil(max);
  const isNamedColor = COLORS.includes(color);
  const euiTheme = useEuiTheme();
  const customColorStyles = !isNamedColor ? {
    color
  } : {};
  const customTextColorStyles = !isNamedColor ? {
    color: makeHighContrastColor(color)(euiTheme.euiTheme)
  } : {};
  const styles = euiProgressStyles(euiTheme, determinate);
  const cssStyles = [styles.euiProgress, determinate && styles.native, !determinate && styles.indeterminate, styles[size], styles[position], isNamedColor ? styles[color] : styles.customColor];
  const dataStyles = euiProgressDataStyles(euiTheme);
  const dataCssStyles = [dataStyles.euiProgress__data, size === 'l' && dataStyles[size]];
  const labelCssStyles = [euiProgressLabelStyles.euiProgress__label, labelProps?.css];
  const valueTextStyles = euiProgressValueTextStyles(euiTheme);
  const valueTextCssStyles = [valueTextStyles.euiProgress__valueText, isNamedColor ? valueTextStyles[color] : styles.customColor];
  const classes = classNames('euiProgress', className);
  const labelClasses = classNames('euiProgress__label', labelProps?.className);
  let valueRender;
  if (valueText === true) {
    // valueText is true
    valueRender = <EuiI18n token="euiProgress.valueText" default="{value}%" values={{
      value
    }} />;
  } else if (valueText) {
    // valueText exists
    valueRender = valueText;
  }

  // Because of a Firefox animation issue, indeterminate progress needs to not use <progress />.
  // See https://css-tricks.com/html5-progress-element/

  if (determinate) {
    return <>
        {label || valueText ? <div css={dataCssStyles} className="euiProgress__data">
            {label && <EuiInnerText>
                {(ref, innerText) => <span title={innerText} ref={ref} {...labelProps} className={labelClasses} css={labelCssStyles}>
                    {label}
                  </span>}
              </EuiInnerText>}
            {valueRender && <EuiInnerText>
                {(ref, innerText) => <span title={innerText} ref={ref} style={customTextColorStyles} css={valueTextCssStyles} className="euiProgress__valueText">
                    {valueRender}
                  </span>}
              </EuiInnerText>}
          </div> : undefined}
        <progress css={cssStyles} className={classes} style={customColorStyles} max={max} value={value} aria-hidden={label && valueText ? true : false} {...rest} />
      </>;
  } else {
    return <div css={cssStyles} style={customColorStyles} className={classes} {...rest} />;
  }
};
EuiProgress.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  size: PropTypes.any,
  /**
     * One of EUI's color palette, vis colors or a valid CSS color value https://developer.mozilla.org/en-US/docs/Web/CSS/color_value
     */
  /**
     * One of EUI's color palette, vis colors or a valid CSS color value https://developer.mozilla.org/en-US/docs/Web/CSS/color_value
     */
  color: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired]),
  position: PropTypes.any,
  max: PropTypes.number,
  /*
       * If true, will render the percentage, otherwise pass a custom node
       */
  valueText: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.node.isRequired]),
  label: PropTypes.node,
  /**
       * Object of props passed to the <span/> wrapping the determinate progress's label
       */
  labelProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  })
};
try {
  EuiProgress.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/progress/progress.tsx',
    description: '',
    displayName: 'EuiProgress',
    methods: [],
    props: {
      label: {
        defaultValue: null,
        description: '',
        name: 'label',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      max: {
        defaultValue: null,
        description: '',
        name: 'max',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      labelProps: {
        defaultValue: null,
        description: "Object of props passed to the <span/> wrapping the determinate progress's label",
        name: 'labelProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLSpanElement>'
        }
      },
      valueText: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'valueText',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      size: {
        defaultValue: {
          value: 'm'
        },
        description: '',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs" | "l"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }]
        }
      },
      color: {
        defaultValue: {
          value: 'success'
        },
        description: "One of EUI's color palette, vis colors or a valid CSS color value https://developer.mozilla.org/en-US/docs/Web/CSS/color_value",
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: '(Color | "accent" | "accentSecondary" | "primary" | "success" | "warning" | "danger" | "subdued" | "vis0" | "vis1" | "vis2" | "vis3" | "vis4" | "vis5" | "vis6" | "vis7" | "vis8" | "vis9") & string'
        }
      },
      position: {
        defaultValue: {
          value: 'static'
        },
        description: '',
        name: 'position',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/progress/progress.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"fixed" | "static" | "absolute"',
          value: [{
            value: '"fixed"'
          }, {
            value: '"static"'
          }, {
            value: '"absolute"'
          }]
        }
      }
    },
    extendedInterfaces: ['ProgressHTMLAttributes', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}