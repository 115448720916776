/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
// eslint-disable-line import/named

import { useEuiMemoizedStyles } from '../../../../services';
import { useEuiI18n } from '../../../i18n';
import { EuiPopover } from '../../../popover';
import { useFormatTimeString } from '../pretty_duration';
import { EuiDatePopoverContent } from './date_popover_content';
import { euiDatePopoverButtonStyles } from './date_popover_button.styles';
export var EuiDatePopoverButton = props => {
  const {
    position,
    isDisabled,
    isInvalid,
    needsUpdating,
    value,
    buttonProps,
    canRoundRelativeUnits,
    roundUp,
    onChange,
    locale,
    dateFormat,
    utcOffset,
    minDate,
    maxDate,
    timeFormat,
    isOpen,
    onPopoverToggle,
    onPopoverClose,
    compressed,
    timeOptions,
    ...rest
  } = props;
  const classes = classNames(['euiDatePopoverButton', `euiDatePopoverButton--${position}`, {
    'euiDatePopoverButton--compressed': compressed,
    'euiDatePopoverButton-isSelected': isOpen,
    'euiDatePopoverButton-isInvalid': isInvalid,
    'euiDatePopoverButton-needsUpdating': needsUpdating,
    'euiDatePopoverButton-disabled': isDisabled
  }]);
  const styles = useEuiMemoizedStyles(euiDatePopoverButtonStyles);
  const cssStyles = [styles.euiDatePopoverButton, buttonProps?.css];
  const formattedValue = useFormatTimeString(value, dateFormat, {
    roundUp,
    locale,
    canRoundRelativeUnits
  });
  let title = formattedValue;
  const invalidTitle = useEuiI18n('euiDatePopoverButton.invalidTitle', 'Invalid date: {title}', {
    title
  });
  const outdatedTitle = useEuiI18n('euiDatePopoverButton.outdatedTitle', 'Update needed: {title}', {
    title
  });
  if (isInvalid) {
    title = invalidTitle;
  } else if (needsUpdating) {
    title = outdatedTitle;
  }
  const button = <button type="button" onClick={onPopoverToggle} className={classes} title={title} disabled={isDisabled} data-test-subj={`superDatePicker${position}DatePopoverButton`} {...buttonProps} css={cssStyles}>
      {formattedValue}
    </button>;
  return <EuiPopover button={button} isOpen={isOpen} closePopover={onPopoverClose} anchorPosition={position === 'start' ? 'downLeft' : 'downRight'} display="block" panelPaddingSize="none" {...rest} css={value === 'now' && styles.now}>
      <EuiDatePopoverContent value={value} roundUp={roundUp} canRoundRelativeUnits={canRoundRelativeUnits} onChange={onChange} dateFormat={dateFormat} timeFormat={timeFormat} locale={locale} position={position} utcOffset={utcOffset} timeOptions={timeOptions} minDate={minDate} maxDate={maxDate} />
    </EuiPopover>;
};
EuiDatePopoverButton.propTypes = {
  className: PropTypes.string,
  buttonProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  dateFormat: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  needsUpdating: PropTypes.bool,
  locale: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onPopoverClose: PropTypes.any.isRequired,
  onPopoverToggle: PropTypes.func.isRequired,
  position: PropTypes.oneOf(["start", "end"]).isRequired,
  canRoundRelativeUnits: PropTypes.bool,
  roundUp: PropTypes.bool,
  timeFormat: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  utcOffset: PropTypes.number,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  compressed: PropTypes.bool,
  timeOptions: PropTypes.shape({
    timeTenseOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    timeUnitsOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    relativeOptions: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.oneOf(["s", "m", "h", "d", "w", "M", "y"]).isRequired, PropTypes.oneOf(["s+", "m+", "h+", "d+", "w+", "M+", "y+"]).isRequired]).isRequired
    }).isRequired).isRequired,
    relativeRoundingLabels: PropTypes.any.isRequired,
    refreshUnitsOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    commonDurationRanges: PropTypes.arrayOf(PropTypes.shape({
      end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
      label: PropTypes.string,
      start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
    }).isRequired).isRequired
  }).isRequired
};
EuiDatePopoverButton.displayName = 'EuiDatePopoverButton';
try {
  EuiDatePopoverButton.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
    description: '',
    displayName: 'EuiDatePopoverButton',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      buttonProps: {
        defaultValue: null,
        description: '',
        name: 'buttonProps',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'CommonProps & ButtonHTMLAttributes<HTMLButtonElement>'
        }
      },
      dateFormat: {
        defaultValue: null,
        description: '',
        name: 'dateFormat',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '',
        name: 'isDisabled',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: '',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isOpen: {
        defaultValue: null,
        description: '',
        name: 'isOpen',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: 'boolean'
        }
      },
      needsUpdating: {
        defaultValue: null,
        description: '',
        name: 'needsUpdating',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      locale: {
        defaultValue: null,
        description: '',
        name: 'locale',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'LocaleSpecifier'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: '(date: string) => void'
        }
      },
      onPopoverClose: {
        defaultValue: null,
        description: '',
        name: 'onPopoverClose',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      onPopoverToggle: {
        defaultValue: null,
        description: '',
        name: 'onPopoverToggle',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: 'MouseEventHandler<HTMLButtonElement>'
        }
      },
      position: {
        defaultValue: null,
        description: '',
        name: 'position',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: '"end" | "start"',
          value: [{
            value: '"end"'
          }, {
            value: '"start"'
          }]
        }
      },
      canRoundRelativeUnits: {
        defaultValue: null,
        description: '',
        name: 'canRoundRelativeUnits',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      roundUp: {
        defaultValue: null,
        description: '',
        name: 'roundUp',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      timeFormat: {
        defaultValue: null,
        description: '',
        name: 'timeFormat',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      utcOffset: {
        defaultValue: null,
        description: '',
        name: 'utcOffset',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      minDate: {
        defaultValue: null,
        description: '',
        name: 'minDate',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'Moment'
        }
      },
      maxDate: {
        defaultValue: null,
        description: '',
        name: 'maxDate',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'Moment'
        }
      },
      compressed: {
        defaultValue: null,
        description: '',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      timeOptions: {
        defaultValue: null,
        description: '',
        name: 'timeOptions',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_button.tsx',
          name: 'EuiDatePopoverButtonProps'
        }],
        required: true,
        type: {
          name: 'TimeOptions'
        }
      }
    },
    extendedInterfaces: ['EuiDatePopoverButtonProps']
  };
} catch (e) {}