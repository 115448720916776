/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useState } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { EuiImageWrapper } from './image_wrapper';
import { euiImageStyles } from './image.styles';
import { EuiImageFullScreenWrapper } from './image_fullscreen_wrapper';
import { SIZES } from './image_types';
export var EuiImage = ({
  className,
  alt,
  url,
  src,
  size = 'original',
  hasShadow,
  style,
  wrapperProps,
  fullScreenIconColor,
  allowFullScreen,
  caption,
  float,
  margin,
  onFullScreen,
  ...rest
}) => {
  const isFullWidth = size === 'fullWidth';
  const isNamedSize = typeof size === 'string' && SIZES.includes(size);
  const isCustomSize = !isNamedSize && size !== 'original';
  const classes = classNames('euiImage', className);
  const styles = useEuiMemoizedStyles(euiImageStyles);
  const cssStyles = [styles.euiImage, isNamedSize && styles[size], !isNamedSize && styles.customSize, hasShadow && styles.hasShadow];
  const [isFullScreen, setIsFullScreen] = useState(false);
  const cssIsFullScreenStyles = [styles.euiImage, styles.isFullScreen];
  const customSize = typeof size === 'string' ? size : `${size}px`;
  const imageStyleWithCustomSize = isCustomSize ? {
    ...style,
    maxWidth: customSize,
    maxHeight: customSize
  } : style;
  const commonWrapperProps = {
    hasShadow,
    wrapperProps,
    setIsFullScreen,
    fullScreenIconColor,
    isFullWidth,
    allowFullScreen,
    alt,
    caption,
    float,
    margin,
    onFullScreen
  };
  const commonImgProps = {
    className: classes,
    src: src || url,
    ...rest
  };
  return <>
      <EuiImageWrapper {...commonWrapperProps}>
        <img alt={alt} css={cssStyles} style={imageStyleWithCustomSize} {...commonImgProps} />
      </EuiImageWrapper>

      {allowFullScreen && isFullScreen && <EuiImageFullScreenWrapper {...commonWrapperProps}>
          <img alt={alt} css={cssIsFullScreenStyles} style={style} {...commonImgProps} />
        </EuiImageFullScreenWrapper>}
    </>;
};
EuiImage.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Requires either `src` or `url` but defaults to using `src` if both are provided
       */
  src: PropTypes.string,
  url: PropTypes.string,
  /**
       * Alt text should describe the image to aid screen reader users. See
       * https://webaim.org/techniques/alttext/ for a guide on writing
       * effective alt text.
       *
       * If no meaningful description exists, or if the image is adequately
       * described by the surrounding text, pass an empty string.
       */
  alt: PropTypes.string.isRequired,
  /**
       * Provides a visible caption to the image
       */
  caption: PropTypes.node,
  /**
       * Accepts `s` / `m` / `l` / `xl` / `original` / `fullWidth` / or a CSS size of `number` or `string`.
       * `fullWidth` will set the figure to stretch to 100% of its container.
       * `string` and `number` types will max both the width or height, whichever is greater.
       */
  size: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.number.isRequired, PropTypes.string.isRequired]),
  /**
       * Float the image to the left or right. Useful in large text blocks.
       */
  float: PropTypes.any,
  /**
       * Margin around the image.
       */
  margin: PropTypes.any,
  /**
       * When set to `true` (default) will apply a slight shadow to the image
       */
  hasShadow: PropTypes.bool,
  /**
       * When set to `true` will make the image clickable to a larger version
       */
  allowFullScreen: PropTypes.bool,
  /**
       * Callback when the image is clicked and `allowFullScreen` is `true`
       */
  onFullScreen: PropTypes.func,
  /**
       * Changes the color of the icon that floats above the image when it can be clicked to fullscreen.
       * The default value of `light` is fine unless your image has a white background, in which case you should change it to `dark`.
       */
  fullScreenIconColor: PropTypes.oneOf(["light", "dark"]),
  /**
       * Props to add to the wrapping figure element
       */
  wrapperProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  })
};
try {
  EuiImage.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/image/image.tsx',
    description: '',
    displayName: 'EuiImage',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      src: {
        defaultValue: null,
        description: 'Requires either `src` or `url` but defaults to using `src` if both are provided',
        name: 'src',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      url: {
        defaultValue: null,
        description: '',
        name: 'url',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      alt: {
        defaultValue: null,
        description: 'Alt text should describe the image to aid screen reader users. See\n' + 'https://webaim.org/techniques/alttext/ for a guide on writing\n' + 'effective alt text.\n' + '\n' + 'If no meaningful description exists, or if the image is adequately\n' + 'described by the surrounding text, pass an empty string.',
        name: 'alt',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      caption: {
        defaultValue: null,
        description: 'Provides a visible caption to the image',
        name: 'caption',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      size: {
        defaultValue: {
          value: 'original'
        },
        description: 'Accepts `s` / `m` / `l` / `xl` / `original` / `fullWidth` / or a CSS size of `number` or `string`.\n' + '`fullWidth` will set the figure to stretch to 100% of its container.\n' + '`string` and `number` types will max both the width or height, whichever is greater.',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string | number'
        }
      },
      float: {
        defaultValue: null,
        description: 'Float the image to the left or right. Useful in large text blocks.',
        name: 'float',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"left" | "right"',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      margin: {
        defaultValue: null,
        description: 'Margin around the image.',
        name: 'margin',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      hasShadow: {
        defaultValue: null,
        description: 'When set to `true` (default) will apply a slight shadow to the image',
        name: 'hasShadow',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      allowFullScreen: {
        defaultValue: null,
        description: 'When set to `true` will make the image clickable to a larger version',
        name: 'allowFullScreen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onFullScreen: {
        defaultValue: null,
        description: 'Callback when the image is clicked and `allowFullScreen` is `true`',
        name: 'onFullScreen',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(isFullScreen: boolean) => void'
        }
      },
      fullScreenIconColor: {
        defaultValue: null,
        description: 'Changes the color of the icon that floats above the image when it can be clicked to fullscreen.\n' + 'The default value of `light` is fine unless your image has a white background, in which case you should change it to `dark`.',
        name: 'fullScreenIconColor',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'EuiImageButtonIconColor',
          value: [{
            value: '"light"'
          }, {
            value: '"dark"'
          }]
        }
      },
      wrapperProps: {
        defaultValue: null,
        description: 'Props to add to the wrapping figure element',
        name: 'wrapperProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/image/image_types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLDivElement>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'ImgHTMLAttributes']
  };
} catch (e) {}