/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../../services';
import { EuiIcon } from '../../icon';
import { euiRadioStyles } from './radio.styles';
export var EuiRadio = ({
  className,
  id,
  name,
  checked,
  label,
  value,
  onChange,
  disabled,
  autoFocus,
  labelProps,
  ...rest
}) => {
  const classes = classNames('euiRadio', className);
  const styles = useEuiMemoizedStyles(euiRadioStyles);
  const inputStyles = [styles.input.euiRadio__circle, !!label && styles.input.hasLabel, disabled ? checked ? styles.input.disabled.selected : styles.input.disabled.unselected : checked ? styles.input.enabled.selected : styles.input.enabled.unselected];
  const labelClasses = classNames('euiRadio__label', labelProps?.className);
  const labelStyles = [styles.label.euiRadio__label, disabled ? styles.label.disabled : styles.label.enabled, labelProps?.css];
  return <div css={styles.euiRadio} className={classes} {...rest}>
      <div css={inputStyles} className="euiRadio__circle">
        <EuiIcon css={styles.input.euiRadio__icon} type={checked ? 'dot' : 'empty'} // Note that the icon does explicitly need to be empty for Windows high contrast themes
      />
        <input css={styles.input.euiRadio__input} className="euiRadio__input" type="radio" id={id} name={name} value={value} checked={checked} onChange={onChange} disabled={disabled} autoFocus={autoFocus} />
      </div>

      {label && <label {...labelProps} css={labelStyles} className={labelClasses} htmlFor={id}>
          {label}
        </label>}
    </div>;
};
EuiRadio.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  autoFocus: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.any.isRequired,
  /**
     * Object of props passed to the <label/>
     */
  /**
     * Object of props passed to the <label/>
     */
  /**
     * Object of props passed to the <label/>
     */
  labelProps: PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  label: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.string.isRequired])
};
try {
  EuiRadio.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/radio/radio.tsx',
    description: '',
    displayName: 'EuiRadio',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }, {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      label: {
        defaultValue: null,
        description: '',
        name: 'label',
        parent: {
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'idWithLabel'
        },
        declarations: [{
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'idWithLabel'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'withId'
        },
        declarations: [{
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'withId'
        }, {
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'idWithLabel'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      name: {
        defaultValue: null,
        description: '',
        name: 'name',
        parent: {
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      checked: {
        defaultValue: null,
        description: '',
        name: 'checked',
        parent: {
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: true,
        type: {
          name: 'ChangeEventHandler<HTMLInputElement>'
        }
      },
      labelProps: {
        defaultValue: null,
        description: 'Object of props passed to the <label/>',
        name: 'labelProps',
        parent: {
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        },
        declarations: [{
          fileName: 'eui/src/components/form/radio/radio.tsx',
          name: 'RadioProps'
        }],
        required: false,
        type: {
          name: 'CommonProps & LabelHTMLAttributes<HTMLLabelElement>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'idWithLabel', 'withId', 'RadioProps']
  };
} catch (e) {}