/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { cloneElement, Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { tabbable } from 'tabbable';
import { withEuiStylesMemoizer, keys } from '../../services';
import { EuiResizeObserver } from '../observer/resize_observer';
import { EuiContextMenuItem } from './context_menu_item';
import { euiContextMenuPanelStyles } from './context_menu_panel.styles';
export var SIZES = ['s', 'm'];
export class EuiContextMenuPanelClass extends Component {
  static defaultProps = {
    items: []
  };
  _isMounted = false;
  backButton = null;
  panel = null;
  initialPopoverParent = null;
  constructor(props) {
    super(props);
    this.state = {
      prevProps: {
        items: this.props.items
      },
      menuItems: [],
      focusedItemIndex: props.onClose && props.initialFocusedItemIndex != null && props.initialFocusedItemIndex !== -1 ? props.initialFocusedItemIndex + 1 // Account for panel title back button
      : props.initialFocusedItemIndex,
      currentHeight: undefined,
      waitingForInitialPopover: false,
      tookInitialFocus: false
    };
  }

  // Find all tabbable menu items on both panel init and
  // whenever `menuItems` resets when `props.items` changes
  findMenuItems = () => {
    if (!this.panel) return;
    if (!this.props.items?.length) return; // We only need menu items/arrow key navigation for the `items` API
    if (this.state.menuItems.length) return; // If we already have menu items, no need to continue

    const tabbableItems = tabbable(this.panel);
    if (tabbableItems.length) {
      this.setState({
        menuItems: tabbableItems
      });
    }
  };
  focusMenuItem = direction => {
    const indexOffset = direction === 'up' ? -1 : 1;
    let nextFocusedItemIndex;
    if (this.state.focusedItemIndex === undefined) {
      // If this is the beginning of the user's keyboard navigation of the menu, then we'll focus
      // either the first or last item.
      nextFocusedItemIndex = direction === 'up' ? this.state.menuItems.length - 1 : 0;
    } else {
      nextFocusedItemIndex = this.state.focusedItemIndex + indexOffset;
      if (nextFocusedItemIndex < 0) {
        nextFocusedItemIndex = this.state.menuItems.length - 1;
      } else if (nextFocusedItemIndex === this.state.menuItems.length) {
        nextFocusedItemIndex = 0;
      }
    }
    this.setState({
      focusedItemIndex: nextFocusedItemIndex
    });
    this.state.menuItems[nextFocusedItemIndex]?.focus();
  };
  onKeyDown = event => {
    // If this panel contains items you can use the left arrow key to go back at any time.
    // But if it doesn't contain items, then you have to focus on the back button specifically,
    // since there could be content inside the panel which requires use of the left arrow key,
    // e.g. text inputs.
    const {
      items,
      onClose,
      showPreviousPanel
    } = this.props;
    if (onClose && (items?.length || document.activeElement === this.backButton || document.activeElement === this.panel)) {
      if (event.key === keys.ARROW_LEFT) {
        if (showPreviousPanel) {
          event.preventDefault();
          event.stopPropagation();
          showPreviousPanel();
          if (this.props.onUseKeyboardToNavigate) {
            this.props.onUseKeyboardToNavigate();
          }
        }
      }
    }
    if (items?.length) {
      switch (event.key) {
        case keys.TAB:
          requestAnimationFrame(() => {
            // NOTE: document.activeElement is stale if not wrapped in requestAnimationFrame
            const focusedItemIndex = this.state.menuItems.indexOf(document.activeElement);

            // We need to sync our internal state with the user tabbing through items
            this.setState({
              focusedItemIndex: focusedItemIndex >= 0 && focusedItemIndex < this.state.menuItems.length ? focusedItemIndex : undefined
            });
          });
          break;
        case keys.ARROW_UP:
          event.preventDefault();
          this.focusMenuItem('up');
          if (this.props.onUseKeyboardToNavigate) {
            this.props.onUseKeyboardToNavigate();
          }
          break;
        case keys.ARROW_DOWN:
          event.preventDefault();
          this.focusMenuItem('down');
          if (this.props.onUseKeyboardToNavigate) {
            this.props.onUseKeyboardToNavigate();
          }
          break;
        case keys.ARROW_RIGHT:
          if (this.props.showNextPanel) {
            event.preventDefault();
            this.props.showNextPanel(onClose && this.state.focusedItemIndex ? this.state.focusedItemIndex - 1 // Account for panel title back button
            : this.state.focusedItemIndex);
            if (this.props.onUseKeyboardToNavigate) {
              this.props.onUseKeyboardToNavigate();
            }
          }
          break;
        default:
          break;
      }
    }
  };
  takeInitialFocus() {
    // Give positioning time to render before focus is applied. Otherwise page jumps.
    requestAnimationFrame(() => {
      if (!this._isMounted) {
        return;
      }

      // Don't take focus yet if EuiContextMenu is in a popover
      // and the popover is initially opening/transitioning in
      if (this.initialPopoverParent && this.state.waitingForInitialPopover) {
        return;
      }

      // Setting focus while transitioning causes the animation to glitch, so we have to wait
      // until it's finished before we focus anything.
      if (this.props.transitionType) {
        // If the panel is transitioning, set focus to the panel so that users using
        // arrow keys that are fast clickers don't accidentally get stranded focus
        // or trigger keystrokes when it shouldn't
        this.panel?.focus({
          preventScroll: true
        });
        return;
      }

      // Initial focus has already been handled, no need to continue and potentially hijack/focus fight
      if (this.state.tookInitialFocus) {
        return;
      }

      // `initialFocusedItemIndex={-1}` should only be used when preventing initial item focus is desired
      if (this.state.focusedItemIndex === -1) {
        // Resetting the focusedItemIndex to 0 allows keyboard up/down behavior to
        // still work correctly later if the panel is manually tabbed into
        return this.setState({
          tookInitialFocus: true,
          focusedItemIndex: 0
        });
      }

      // If an item should be focused, focus it (if it exists)
      if (this.state.focusedItemIndex != null && this.state.menuItems.length) {
        const focusedItem = this.state.menuItems[this.state.focusedItemIndex];
        if (focusedItem) {
          focusedItem.focus();
          return this.setState({
            tookInitialFocus: true
          });
        }
      }

      // Otherwise, if the back button panel title is present, focus it
      if (this.backButton) {
        // Focus the back button for both `items` and `children` APIs
        this.backButton.focus();
        // If `items`, ensure our focused item index is correct
        if (this.state.menuItems.length) {
          this.setState({
            focusedItemIndex: 0
          });
        }
        return this.setState({
          tookInitialFocus: true
        });
      }

      // Focus on the panel as a last resort.
      if (this.panel && !this.panel.contains(document.activeElement)) {
        this.panel.focus();
        this.setState({
          tookInitialFocus: true
        });
      }
    });
  }
  reclaimPopoverFocus = () => {
    this.setState({
      waitingForInitialPopover: false
    });
    this.takeInitialFocus();
  };
  onTransitionComplete = () => {
    if (this.props.onTransitionComplete) {
      this.props.onTransitionComplete();
    }
  };
  componentDidUpdate(_, prevState) {
    if (prevState.menuItems !== this.state.menuItems) {
      this.findMenuItems();
    }
    // Focus isn't always ready to be taken on mount, so we need to call it
    // on update as well just in case
    this.takeInitialFocus();
  }
  componentDidMount() {
    // If EuiContextMenu is used within an EuiPopover, we need to wait for EuiPopover to:
    // 1. Correctly set its `returnFocus` to the toggling button,
    //    so focus is correctly restored to the popover toggle on close
    // 2. Finish its react-focus-on `autoFocus` behavior after transitioning in,
    //    so the panel can handle its own focus without focus fighting
    if (this.initialPopoverParent) {
      this.initialPopoverParent.addEventListener('focus', this.reclaimPopoverFocus, {
        once: true
      });
    } else {
      this.takeInitialFocus();
    }
    this._isMounted = true;
  }
  componentWillUnmount() {
    this.initialPopoverParent?.removeEventListener('focus', this.reclaimPopoverFocus);
    this._isMounted = false;
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    let needsUpdate = false;
    const nextState = {};

    // Clear refs to menuItems if we're getting new ones.
    if (nextProps.items !== prevState.prevProps.items) {
      needsUpdate = true;
      nextState.menuItems = [];
      nextState.prevProps = {
        items: nextProps.items
      };
    }
    if (needsUpdate) {
      return nextState;
    }
    return null;
  }
  updateHeight() {
    const currentHeight = this.panel ? this.panel.clientHeight : 0;
    if (this.state.height !== currentHeight) {
      if (this.props.onHeightChange) {
        this.props.onHeightChange(currentHeight);
        this.setState({
          height: currentHeight
        });
      }
    }
  }
  getInitialPopoverParent() {
    // If `transitionType` exists, that means we're navigating between panels
    // and the initial popover has already loaded, so we shouldn't need this logic
    if (this.props.transitionType) return;
    if (!this.panel) return;
    const parent = this.panel.parentNode;
    if (!parent) return;
    const hasEuiContextMenuParent = parent.classList.contains('euiContextMenu');

    // It's possible to use an EuiContextMenuPanel directly in a popover without
    // an EuiContextMenu, so we need to account for that when searching parent nodes
    const popoverParent = hasEuiContextMenuParent ? parent?.parentNode?.parentNode : parent?.parentNode;
    if (!popoverParent) return;
    const hasPopoverParent = !!popoverParent.dataset.popoverPanel;
    if (!hasPopoverParent) return;
    this.initialPopoverParent = popoverParent;
    this.setState({
      waitingForInitialPopover: true
    });
  }
  panelRef = node => {
    this.panel = node;
    this.updateHeight();
    this.getInitialPopoverParent();
    this.findMenuItems();
  };
  render() {
    const {
      stylesMemoizer,
      children,
      className,
      onClose,
      title,
      onHeightChange,
      transitionType,
      transitionDirection,
      onTransitionComplete,
      onUseKeyboardToNavigate,
      items,
      initialFocusedItemIndex,
      showNextPanel,
      showPreviousPanel,
      size,
      ...rest
    } = this.props;
    const classes = classNames('euiContextMenuPanel', className);
    const styles = stylesMemoizer(euiContextMenuPanelStyles);
    const cssStyles = [styles.euiContextMenuPanel, transitionDirection && transitionType && styles[transitionDirection][transitionType]];
    const panelTitle = title && <EuiContextMenuItem css={styles.euiContextMenuPanel__title} className="euiContextMenuPanel__title" onClick={onClose} buttonRef={node => {
      if (onClose) this.backButton = node;
    }} data-test-subj={onClose ? 'contextMenuPanelTitleButton' : 'contextMenuPanelTitle'} icon={onClose && 'arrowLeft'}>
        {title}
      </EuiContextMenuItem>;
    const content = items && items.length ? items.map(MenuItem => {
      const cloneProps = {};
      if (size) {
        cloneProps.size = size;
      }
      return MenuItem.type === EuiContextMenuItem ? cloneElement(MenuItem, cloneProps) : MenuItem;
    }) : children;
    return <div ref={this.panelRef} css={cssStyles} className={classes} onKeyDown={this.onKeyDown} tabIndex={-1} onAnimationEnd={this.onTransitionComplete} {...rest}>
        {panelTitle}

        <EuiResizeObserver onResize={() => this.updateHeight()}>
          {resizeRef => <div ref={resizeRef}>{content}</div>}
        </EuiResizeObserver>
      </div>;
  }
}
EuiContextMenuPanelClass.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Determines the initially focused menu item for keyboard and screen reader users.
       *
       * Can be set to `-1` to prevent autofocus (an uncommon case that must have
       * keyboard accessibility accounted for manually if used)
       */
  initialFocusedItemIndex: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.element.isRequired),
  onClose: PropTypes.func,
  onHeightChange: PropTypes.func,
  onTransitionComplete: PropTypes.func,
  onUseKeyboardToNavigate: PropTypes.func,
  showNextPanel: PropTypes.func,
  showPreviousPanel: PropTypes.func,
  title: PropTypes.node,
  transitionDirection: PropTypes.oneOf(["next", "previous"]),
  transitionType: PropTypes.oneOf(["in", "out"]),
  /**
       * Alters the size of the items and the title
       */
  size: PropTypes.any
};
export const EuiContextMenuPanel = withEuiStylesMemoizer(EuiContextMenuPanelClass);
try {
  EuiContextMenuPanelClass.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/context_menu/context_menu_panel.tsx',
    description: '',
    displayName: 'EuiContextMenuPanelClass',
    methods: [],
    props: {
      stylesMemoizer: {
        defaultValue: null,
        description: '',
        name: 'stylesMemoizer',
        parent: {
          fileName: 'eui/src/services/theme/style_memoization.tsx',
          name: 'WithEuiStylesMemoizerProps'
        },
        declarations: [{
          fileName: 'eui/src/services/theme/style_memoization.tsx',
          name: 'WithEuiStylesMemoizerProps'
        }],
        required: true,
        type: {
          name: '<T extends (theme: UseEuiTheme<{}>) => StylesMap>(stylesGenerator: T) => ReturnType<T>'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      initialFocusedItemIndex: {
        defaultValue: null,
        description: 'Determines the initially focused menu item for keyboard and screen reader users.\n' + '\n' + 'Can be set to `-1` to prevent autofocus (an uncommon case that must have\n' + 'keyboard accessibility accounted for manually if used)',
        name: 'initialFocusedItemIndex',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      items: {
        defaultValue: {
          value: '[]'
        },
        description: '',
        name: 'items',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactElement[]'
        }
      },
      onClose: {
        defaultValue: null,
        description: '',
        name: 'onClose',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      onHeightChange: {
        defaultValue: null,
        description: '',
        name: 'onHeightChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiContextMenuPanelHeightChangeHandler'
        }
      },
      onTransitionComplete: {
        defaultValue: null,
        description: '',
        name: 'onTransitionComplete',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      onUseKeyboardToNavigate: {
        defaultValue: null,
        description: '',
        name: 'onUseKeyboardToNavigate',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      showNextPanel: {
        defaultValue: null,
        description: '',
        name: 'showNextPanel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiContextMenuPanelShowPanelCallback'
        }
      },
      showPreviousPanel: {
        defaultValue: null,
        description: '',
        name: 'showPreviousPanel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      transitionDirection: {
        defaultValue: null,
        description: '',
        name: 'transitionDirection',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'EuiContextMenuPanelTransitionDirection',
          value: [{
            value: '"next"'
          }, {
            value: '"previous"'
          }]
        }
      },
      transitionType: {
        defaultValue: null,
        description: '',
        name: 'transitionType',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'EuiContextMenuPanelTransitionType',
          value: [{
            value: '"in"'
          }, {
            value: '"out"'
          }]
        }
      },
      size: {
        defaultValue: null,
        description: 'Alters the size of the items and the title',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      }
    },
    extendedInterfaces: ['WithEuiStylesMemoizerProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'Attributes']
  };
} catch (e) {}
try {
  EuiContextMenuPanel.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/context_menu/context_menu_panel.tsx',
    description: '',
    displayName: 'EuiContextMenuPanel',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      title: {
        defaultValue: null,
        description: '',
        name: 'title',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      size: {
        defaultValue: null,
        description: 'Alters the size of the items and the title',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      onClose: {
        defaultValue: null,
        description: '',
        name: 'onClose',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      items: {
        defaultValue: null,
        description: '',
        name: 'items',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'ReactElement[]'
        }
      },
      initialFocusedItemIndex: {
        defaultValue: null,
        description: 'Determines the initially focused menu item for keyboard and screen reader users.\n' + '\n' + 'Can be set to `-1` to prevent autofocus (an uncommon case that must have\n' + 'keyboard accessibility accounted for manually if used)',
        name: 'initialFocusedItemIndex',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      onHeightChange: {
        defaultValue: null,
        description: '',
        name: 'onHeightChange',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiContextMenuPanelHeightChangeHandler'
        }
      },
      onTransitionComplete: {
        defaultValue: null,
        description: '',
        name: 'onTransitionComplete',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      onUseKeyboardToNavigate: {
        defaultValue: null,
        description: '',
        name: 'onUseKeyboardToNavigate',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      showNextPanel: {
        defaultValue: null,
        description: '',
        name: 'showNextPanel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'EuiContextMenuPanelShowPanelCallback'
        }
      },
      showPreviousPanel: {
        defaultValue: null,
        description: '',
        name: 'showPreviousPanel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      transitionDirection: {
        defaultValue: null,
        description: '',
        name: 'transitionDirection',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'EuiContextMenuPanelTransitionDirection',
          value: [{
            value: '"next"'
          }, {
            value: '"previous"'
          }]
        }
      },
      transitionType: {
        defaultValue: null,
        description: '',
        name: 'transitionType',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/context_menu/context_menu_panel.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'EuiContextMenuPanelTransitionType',
          value: [{
            value: '"in"'
          }, {
            value: '"out"'
          }]
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<unknown>'
        }
      }
    },
    extendedInterfaces: ['WithEuiStylesMemoizerProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'Attributes']
  };
} catch (e) {}