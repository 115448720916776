/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import { useEuiMemoizedStyles } from '../../../../services';
import { useEuiPaddingCSS } from '../../../../global_styling';
import { EuiI18n, useEuiI18n } from '../../../i18n';
import { EuiTabbedContent } from '../../../tabs';
import { EuiText } from '../../../text';
import { EuiButton } from '../../../button';
import { getDateMode, DATE_MODES, toAbsoluteString, toRelativeString } from '../date_modes';
import { EuiAbsoluteTab } from './absolute_tab';
import { EuiRelativeTab } from './relative_tab';
import { euiDatePopoverContentStyles } from './date_popover_content.styles';
export var EuiDatePopoverContent = ({
  value,
  canRoundRelativeUnits = true,
  roundUp = false,
  onChange,
  dateFormat,
  timeFormat,
  locale,
  position,
  utcOffset,
  timeOptions,
  minDate,
  maxDate
}) => {
  const styles = useEuiMemoizedStyles(euiDatePopoverContentStyles);
  const onTabClick = selectedTab => {
    switch (selectedTab.id) {
      case DATE_MODES.ABSOLUTE:
        onChange(toAbsoluteString(value, roundUp));
        break;
      case DATE_MODES.RELATIVE:
        onChange(toRelativeString(value));
        break;
    }
  };
  const startDateLabel = useEuiI18n('euiDatePopoverContent.startDateLabel', 'Start date');
  const endDateLabel = useEuiI18n('euiDatePopoverContent.endDateLabel', 'End date');
  const labelPrefix = position === 'start' ? startDateLabel : endDateLabel;
  const absoluteLabel = useEuiI18n('euiDatePopoverContent.absoluteTabLabel', 'Absolute');
  const relativeLabel = useEuiI18n('euiDatePopoverContent.relativeTabLabel', 'Relative');
  const nowLabel = useEuiI18n('euiDatePopoverContent.nowTabLabel', 'Now');
  const renderTabs = [{
    id: DATE_MODES.ABSOLUTE,
    name: absoluteLabel,
    content: <EuiAbsoluteTab dateFormat={dateFormat} timeFormat={timeFormat} locale={locale} value={value} onChange={onChange} roundUp={roundUp} labelPrefix={labelPrefix} utcOffset={utcOffset} minDate={minDate} maxDate={maxDate} />,
    'data-test-subj': 'superDatePickerAbsoluteTab',
    'aria-label': `${labelPrefix}: ${absoluteLabel}`
  }, {
    id: DATE_MODES.RELATIVE,
    name: relativeLabel,
    content: <EuiRelativeTab dateFormat={dateFormat} locale={locale} value={canRoundRelativeUnits ? toAbsoluteString(value, roundUp) : value} onChange={onChange} roundUp={roundUp} labelPrefix={labelPrefix} timeOptions={timeOptions} />,
    'data-test-subj': 'superDatePickerRelativeTab',
    'aria-label': `${labelPrefix}: ${relativeLabel}`
  }, {
    id: DATE_MODES.NOW,
    name: nowLabel,
    content: <EuiText size="s" color="subdued" css={useEuiPaddingCSS().m}>
          <p>
            <EuiI18n token="euiDatePopoverContent.nowTabContent" default='Setting the time to "now" means that on every refresh this time will be set to the time of the refresh.' />
          </p>
          <EuiButton data-test-subj="superDatePickerNowButton" onClick={() => {
        onChange('now');
      }} fullWidth size="s" fill>
            {position === 'start' ? <EuiI18n token="euiDatePopoverContent.nowTabButtonStart" default="Set start date and time to now" /> : <EuiI18n token="euiDatePopoverContent.nowTabButtonEnd" default="Set end date and time to now" />}
          </EuiButton>
        </EuiText>,
    'data-test-subj': 'superDatePickerNowTab',
    'aria-label': `${labelPrefix}: ${nowLabel}`
  }];
  const initialSelectedTab = renderTabs.find(tab => tab.id === getDateMode(value));
  return <EuiTabbedContent css={styles.euiDatePopoverContent} className="euiDatePopoverContent" tabs={renderTabs} autoFocus="selected" initialSelectedTab={initialSelectedTab} onTabClick={onTabClick} size="s" expand />;
};
EuiDatePopoverContent.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  canRoundRelativeUnits: PropTypes.bool,
  roundUp: PropTypes.bool,
  dateFormat: PropTypes.string.isRequired,
  timeFormat: PropTypes.string.isRequired,
  locale: PropTypes.any,
  position: PropTypes.oneOf(["start", "end"]).isRequired,
  utcOffset: PropTypes.number,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  timeOptions: PropTypes.shape({
    timeTenseOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    timeUnitsOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    relativeOptions: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.oneOf(["s", "m", "h", "d", "w", "M", "y"]).isRequired, PropTypes.oneOf(["s+", "m+", "h+", "d+", "w+", "M+", "y+"]).isRequired]).isRequired
    }).isRequired).isRequired,
    relativeRoundingLabels: PropTypes.any.isRequired,
    refreshUnitsOptions: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
    commonDurationRanges: PropTypes.arrayOf(PropTypes.shape({
      end: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired,
      label: PropTypes.string,
      start: PropTypes.oneOfType([PropTypes.oneOf(["now"]), PropTypes.string.isRequired]).isRequired
    }).isRequired).isRequired
  }).isRequired
};
EuiDatePopoverContent.displayName = 'EuiDatePopoverContent';
try {
  EuiDatePopoverContent.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
    description: '',
    displayName: 'EuiDatePopoverContent',
    methods: [],
    props: {
      value: {
        defaultValue: null,
        description: '',
        name: 'value',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      onChange: {
        defaultValue: null,
        description: '',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        }],
        required: true,
        type: {
          name: '(date: string) => void'
        }
      },
      canRoundRelativeUnits: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'canRoundRelativeUnits',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      roundUp: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'roundUp',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      dateFormat: {
        defaultValue: null,
        description: '',
        name: 'dateFormat',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      timeFormat: {
        defaultValue: null,
        description: '',
        name: 'timeFormat',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      locale: {
        defaultValue: null,
        description: '',
        name: 'locale',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        }],
        required: false,
        type: {
          name: 'LocaleSpecifier'
        }
      },
      position: {
        defaultValue: null,
        description: '',
        name: 'position',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        }],
        required: true,
        type: {
          name: 'enum',
          raw: '"end" | "start"',
          value: [{
            value: '"end"'
          }, {
            value: '"start"'
          }]
        }
      },
      utcOffset: {
        defaultValue: null,
        description: '',
        name: 'utcOffset',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      minDate: {
        defaultValue: null,
        description: '',
        name: 'minDate',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        }],
        required: false,
        type: {
          name: 'Moment'
        }
      },
      maxDate: {
        defaultValue: null,
        description: '',
        name: 'maxDate',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        }],
        required: false,
        type: {
          name: 'Moment'
        }
      },
      timeOptions: {
        defaultValue: null,
        description: '',
        name: 'timeOptions',
        parent: {
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/date_picker/super_date_picker/date_popover/date_popover_content.tsx',
          name: 'EuiDatePopoverContentProps'
        }],
        required: true,
        type: {
          name: 'TimeOptions'
        }
      }
    },
    extendedInterfaces: ['EuiDatePopoverContentProps']
  };
} catch (e) {}