import "core-js/modules/es.array.includes.js";
import PropTypes from "prop-types";
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { isValidElement } from 'react';
import { useEuiMemoizedStyles, cloneElementWithCss } from '../../services';
import { euiTextColorStyles } from './text_color.styles';
export var COLORS = ['default', 'subdued', 'success', 'accent', 'accentSecondary', 'danger', 'warning', 'ghost', 'inherit'];
export var _isNamedColor = function _isNamedColor(color) {
  return COLORS.includes(color);
};
export var EuiTextColor = ({
  children,
  color = 'default',
  component: Component = 'span',
  cloneElement = false,
  style,
  ...rest
}) => {
  const isNamedColor = _isNamedColor(color);
  const styles = useEuiMemoizedStyles(euiTextColorStyles);
  const cssStyles = [styles.euiTextColor, isNamedColor ? styles[color] : styles.customColor];

  // We're checking if is a custom color.
  // If it is a custom color we set the `color` of the `.euiTextColor` div to that custom color.
  // This way the children text elements can `inherit` that color and border and backgrounds can get that `currentColor`.
  const euiTextStyle = !isNamedColor ? {
    color: color,
    ...style
  } : {
    ...style
  };
  const props = {
    css: cssStyles,
    style: euiTextStyle,
    ...rest
  };
  if (isValidElement(children) && cloneElement) {
    const childrenStyle = {
      ...children.props.style,
      ...euiTextStyle
    };
    return cloneElementWithCss(children, {
      ...props,
      style: childrenStyle
    });
  } else {
    return <Component {...props}>{children}</Component>;
  }
};
EuiTextColor.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * The HTML element/tag to render.
       * Use with care when nesting multiple components to ensure valid XHTML:
       * - `<div>` and other block tags are not valid to use inside `<p>`. If using the `<p>` tag, we recommend passing strings/text only.
       * - `<span>` is valid to be nested in any tag, and can have any tag nested within it.
       */
  component: PropTypes.oneOf(["div", "span", "p"]),
  /**
     * Applies text styling to the child element instead of rendering a parent wrapper.
     * Can only be used when wrapping a *single* child element/tag, and not raw text.
     */
  cloneElement: PropTypes.bool,
  /**
     * Any of our named colors or a `hex`, `rgb` or `rgba` value.
     * @default inherit
     */
  color: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired])
};
try {
  _isNamedColor.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/text/text_color.tsx',
    description: '',
    displayName: '_isNamedColor',
    methods: [],
    props: {},
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}
try {
  EuiTextColor.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/text/text_color.tsx',
    description: '',
    displayName: 'EuiTextColor',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      component: {
        defaultValue: null,
        description: 'The HTML element/tag to render.\n' + 'Use with care when nesting multiple components to ensure valid XHTML:\n' + '- `<div>` and other block tags are not valid to use inside `<p>`. If using the `<p>` tag, we recommend passing strings/text only.\n' + '- `<span>` is valid to be nested in any tag, and can have any tag nested within it.',
        name: 'component',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"div" | "p" | "span"',
          value: [{
            value: '"div"'
          }, {
            value: '"p"'
          }, {
            value: '"span"'
          }]
        }
      },
      cloneElement: {
        defaultValue: {
          value: 'false'
        },
        description: 'Applies text styling to the child element instead of rendering a parent wrapper.\n' + 'Can only be used when wrapping a *single* child element/tag, and not raw text.',
        name: 'cloneElement',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      color: {
        defaultValue: {
          value: 'default'
        },
        description: 'Any of our named colors or a `hex`, `rgb` or `rgba` value.',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Color | "default" | "accent" | "accentSecondary" | "success" | "warning" | "danger" | "subdued" | "ghost"'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}