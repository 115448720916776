/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import classNames from 'classnames';
import PropTypes from "prop-types";
import { useEuiMemoizedStyles, cloneElementWithCss } from '../../services';
import { euiGlobalToastListItemStyles } from './global_toast_list.styles';
export var EuiGlobalToastListItem = ({
  children,
  className,
  isDismissed
}) => {
  const styles = useEuiMemoizedStyles(euiGlobalToastListItemStyles);
  if (!children) return null;
  const cssStyles = [styles.euiGlobalToastListItem, isDismissed && styles.dismissed];
  const classes = classNames('euiGlobalToastListItem', children.props.className, className);
  return cloneElementWithCss(children, {
    ...children.props,
    ...{
      className: classes,
      css: cssStyles
    }
  });
};
EuiGlobalToastListItem.propTypes = {
  isDismissed: PropTypes.bool,
  /**
     * ReactElement to render as this component's content
     */
  children: PropTypes.element,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  EuiGlobalToastListItem.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/toast/global_toast_list_item.tsx',
    description: '',
    displayName: 'EuiGlobalToastListItem',
    methods: [],
    props: {
      isDismissed: {
        defaultValue: null,
        description: '',
        name: 'isDismissed',
        parent: {
          fileName: 'eui/src/components/toast/global_toast_list_item.tsx',
          name: 'EuiGlobalToastListItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/toast/global_toast_list_item.tsx',
          name: 'EuiGlobalToastListItemProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      children: {
        defaultValue: null,
        description: "ReactElement to render as this component's content",
        name: 'children',
        parent: {
          fileName: 'eui/src/components/toast/global_toast_list_item.tsx',
          name: 'EuiGlobalToastListItemProps'
        },
        declarations: [{
          fileName: 'eui/src/components/toast/global_toast_list_item.tsx',
          name: 'EuiGlobalToastListItemProps'
        }],
        required: false,
        type: {
          name: 'ReactElement'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['EuiGlobalToastListItemProps', 'CommonProps']
  };
} catch (e) {}