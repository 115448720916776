import "core-js/modules/es.regexp.exec.js";
import PropTypes from "prop-types";
import "core-js/modules/es.string.match.js";
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import classNames from 'classnames';
import { focusable } from 'tabbable';
import { EuiFocusTrap } from '../focus_trap';
import { keys, getTransitionTimings, getWaitDuration, performOnFrame, htmlIdGenerator } from '../../services';
import { setMultipleRefs } from '../../services/hooks/useCombinedRefs';
import { EuiScreenReaderOnly } from '../accessibility';
import { EuiPortal } from '../portal';
import { EuiMutationObserver } from '../observer/mutation_observer';
import { findPopoverPosition, getElementZIndex } from '../../services/popover';
import { EuiI18n } from '../i18n';
import { EuiOutsideClickDetector } from '../outside_click_detector';
import { EuiPopoverArrow } from './popover_arrow';
import { euiPopoverStyles } from './popover.styles';
import { EuiPopoverPanel } from './popover_panel';
export var popoverAnchorPosition = ['upCenter', 'upLeft', 'upRight', 'downCenter', 'downLeft', 'downRight', 'leftCenter', 'leftUp', 'leftDown', 'rightCenter', 'rightUp', 'rightDown'];
var anchorPositionToPopoverPositionMap = {
  up: 'top',
  right: 'right',
  down: 'bottom',
  left: 'left'
};
export function getPopoverPositionFromAnchorPosition(anchorPosition) {
  // maps the anchor position to the matching popover position
  // e.g. "upLeft" -> "top", "downRight" -> "bottom"

  // extract the first positional word from anchorPosition:
  // starts at the beginning (" ^ ") of anchorPosition and
  // captures all of the characters (" (.*?) ") until the
  // first capital letter (" [A-Z] ") is encountered
  var _ref = anchorPosition.match(/^(.*?)[A-Z]/),
    _ref2 = _slicedToArray(_ref, 2),
    primaryPosition = _ref2[1];
  return anchorPositionToPopoverPositionMap[primaryPosition];
}
export function getPopoverAlignFromAnchorPosition(anchorPosition) {
  // maps the gravity to the matching popover position
  // e.g. "upLeft" -> "left", "rightDown" -> "bottom"

  // extract the second positional word from anchorPosition:
  // starts a capture group at the first capital letter
  // and includes everything after it
  var _ref3 = anchorPosition.match(/([A-Z].*)/),
    _ref4 = _slicedToArray(_ref3, 2),
    align = _ref4[1];

  // this performs two tasks:
  // 1. normalizes the align position by lowercasing it
  // 2. `center` doesn't exist in the lookup map which converts it to `undefined` meaning no align
  return anchorPositionToPopoverPositionMap[align.toLowerCase()];
}
var DEFAULT_POPOVER_STYLES = {
  top: 50,
  left: 50
};
var returnFocusConfig = {
  preventScroll: true
};
var closingTransitionTime = 250; // TODO: DRY out var when converting to CSS-in-JS

export class EuiPopover extends Component {
  static defaultProps = {
    isOpen: false,
    ownFocus: true,
    repositionToCrossAxis: true,
    anchorPosition: 'downCenter',
    panelPaddingSize: 'm',
    hasArrow: true,
    display: 'inline-block'
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.prevProps.isOpen && !nextProps.isOpen) {
      return {
        prevProps: {
          isOpen: nextProps.isOpen
        },
        isClosing: true,
        isOpening: false
      };
    }
    if (prevState.prevProps.isOpen !== nextProps.isOpen) {
      return {
        prevProps: {
          isOpen: nextProps.isOpen
        }
      };
    }
    return null;
  }
  respositionTimeout;
  strandedFocusTimeout;
  closingTransitionTimeout;
  closingTransitionAnimationFrame;
  button = null;
  panel = null;
  descriptionId = htmlIdGenerator()();
  constructor(props) {
    super(props);
    this.state = {
      prevProps: {
        isOpen: props.isOpen
      },
      suppressingPopover: props.isOpen,
      // only suppress if created with isOpen=true
      isClosing: false,
      isOpening: false,
      popoverStyles: DEFAULT_POPOVER_STYLES,
      arrowStyles: {},
      arrowPosition: null,
      openPosition: null,
      // once a stable position has been found, keep the contents on that side
      isOpenStable: false // wait for any initial opening transitions to finish before marking as stable
    };
  }
  closePopover = () => {
    if (this.props.isOpen) {
      this.props.closePopover();
    }
  };
  onEscapeKey = event => {
    if (this.props.isOpen) {
      event.preventDefault();
      event.stopPropagation();
      this.closePopover();
      this.handleStrandedFocus();
    }
  };
  handleStrandedFocus = () => {
    this.strandedFocusTimeout = window.setTimeout(() => {
      // If `returnFocus` failed and focus was stranded,
      // attempt to manually restore focus to the toggle button.
      // The stranded focus is either in most cases on body but
      // it will be on the panel instead on mount when isOpen=true
      if (document.activeElement === document.body || this.panel?.contains(document.activeElement) // if focus is on OR within this.panel
      ) {
        if (!this.button) return;
        const focusableItems = focusable(this.button);
        if (!focusableItems.length) return;
        const toggleButton = focusableItems[0];
        toggleButton.focus(returnFocusConfig);
      }
    }, closingTransitionTime);
  };
  onKeyDown = event => {
    if (event.key === keys.ESCAPE) {
      this.onEscapeKey(event);
    }
  };
  onClickOutside = event => {
    // only close the popover if the event source isn't the anchor button
    // otherwise, it is up to the anchor to toggle the popover's open status
    if (this.button && this.button.contains(event.target) === false) {
      this.closePopover();
    }
  };
  onOpenPopover = () => {
    clearTimeout(this.strandedFocusTimeout);
    clearTimeout(this.closingTransitionTimeout);
    if (this.closingTransitionAnimationFrame) {
      cancelAnimationFrame(this.closingTransitionAnimationFrame);
    }
    // We need to set this state a beat after the render takes place, so that the CSS
    // transition can take effect.
    this.closingTransitionAnimationFrame = window.requestAnimationFrame(() => {
      this.setState({
        isOpening: true
      });
    });

    // for each child element of `this.panel`, find any transition duration we should wait for before stabilizing
    const {
      durationMatch,
      delayMatch
    } = Array.prototype.slice.call(this.panel ? [this.panel, ...Array.from(this.panel.children)] : []).reduce(({
      durationMatch,
      delayMatch
    }, element) => {
      const transitionTimings = getTransitionTimings(element);
      return {
        durationMatch: Math.max(durationMatch, transitionTimings.durationMatch),
        delayMatch: Math.max(delayMatch, transitionTimings.delayMatch)
      };
    }, {
      durationMatch: 0,
      delayMatch: 0
    });
    clearTimeout(this.respositionTimeout);
    this.respositionTimeout = window.setTimeout(() => {
      this.setState({
        isOpenStable: true
      }, () => {
        this.positionPopoverFixed();
      });
    }, durationMatch + delayMatch);
  };
  componentDidMount() {
    if (this.state.suppressingPopover) {
      // component was created with isOpen=true; now that it's mounted
      // stop suppressing and start opening
      this.setState({
        suppressingPopover: false,
        isOpening: true
      }, () => {
        this.onOpenPopover();
      });
    }
    if (this.props.repositionOnScroll) {
      window.addEventListener('scroll', this.positionPopoverFixed, true);
    }
  }
  componentDidUpdate(prevProps) {
    // The popover is being opened.
    if (!prevProps.isOpen && this.props.isOpen) {
      this.onOpenPopover();
    }

    // ensure recalculation of panel position on prop updates
    if (this.props.isOpen && (prevProps.anchorPosition !== this.props.anchorPosition || prevProps.buffer !== this.props.buffer || prevProps.offset !== this.props.offset || prevProps.panelPaddingSize !== this.props.panelPaddingSize)) {
      this.positionPopoverFluid();
    }

    // update scroll listener
    if (prevProps.repositionOnScroll !== this.props.repositionOnScroll) {
      if (this.props.repositionOnScroll) {
        window.addEventListener('scroll', this.positionPopoverFixed, true);
      } else {
        window.removeEventListener('scroll', this.positionPopoverFixed, true);
      }
    }

    // The popover is being closed.
    if (prevProps.isOpen && !this.props.isOpen) {
      // If the user has just closed the popover, queue up the removal of the content after the
      // transition is complete.
      this.closingTransitionTimeout = window.setTimeout(() => {
        this.setState({
          isClosing: false
        });
      }, closingTransitionTime);
    }
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.positionPopoverFixed, true);
    clearTimeout(this.respositionTimeout);
    clearTimeout(this.strandedFocusTimeout);
    clearTimeout(this.closingTransitionTimeout);
    cancelAnimationFrame(this.closingTransitionAnimationFrame);
  }
  onMutation = records => {
    const waitDuration = getWaitDuration(records);
    this.positionPopoverFixed();
    performOnFrame(waitDuration, this.positionPopoverFixed);
  };
  positionPopover = allowEnforcePosition => {
    if (this.button == null || this.panel == null) return;
    const {
      anchorPosition,
      offset = 0
    } = this.props;
    let position = getPopoverPositionFromAnchorPosition(anchorPosition);
    let forcePosition = undefined;
    if (allowEnforcePosition && this.state.isOpenStable && this.state.openPosition != null) {
      position = this.state.openPosition;
      forcePosition = true;
    }
    const {
      top,
      left,
      position: foundPosition,
      arrow
    } = findPopoverPosition({
      container: this.props.container,
      position,
      forcePosition,
      align: getPopoverAlignFromAnchorPosition(anchorPosition),
      anchor: this.button,
      popover: this.panel,
      offset: this.props.attachToAnchor ? offset : this.props.hasArrow ? 16 + offset : 8 + offset,
      arrowConfig: this.props.hasArrow ? {
        arrowWidth: 16,
        arrowBuffer: 10
      } : {
        arrowWidth: 0,
        arrowBuffer: 0
      },
      returnBoundingBox: this.props.attachToAnchor,
      allowCrossAxis: this.props.repositionToCrossAxis,
      buffer: this.props.buffer
    });

    // the popover's z-index must inherit from the button
    // this keeps a button's popover under a flyout that would cover the button
    // but a popover triggered inside a flyout will appear over that flyout
    const {
      zIndex: zIndexProp
    } = this.props;
    const zIndex = zIndexProp == null ? getElementZIndex(this.button, this.panel) + 2000 : zIndexProp;
    const popoverStyles = {
      ...this.props.panelStyle,
      top,
      left,
      zIndex
    };
    const willRenderArrow = !this.props.attachToAnchor && this.props.hasArrow;
    const arrowStyles = willRenderArrow ? arrow : undefined;
    const arrowPosition = foundPosition;
    this.props.onPositionChange && this.props.onPositionChange(arrowPosition);
    this.setState({
      popoverStyles,
      arrowStyles,
      arrowPosition,
      openPosition: foundPosition
    });
  };
  positionPopoverFixed = () => {
    this.positionPopover(true);
  };
  positionPopoverFluid = () => {
    this.positionPopover(false);
  };
  panelRef = node => {
    this.panel = node;
    this.props.panelRef && this.props.panelRef(node);
    if (node == null) {
      // panel has unmounted, restore the state defaults
      this.setState({
        popoverStyles: DEFAULT_POPOVER_STYLES,
        arrowStyles: {},
        arrowPosition: null,
        openPosition: null,
        isOpenStable: false
      });
      window.removeEventListener('resize', this.positionPopoverFluid);
    } else {
      // panel is coming into existence
      this.positionPopoverFluid();
      window.addEventListener('resize', this.positionPopoverFluid);
    }
  };
  popoverRef = node => {
    this.button = node;
    setMultipleRefs([this.props.popoverRef], node);
  };
  render() {
    const {
      anchorPosition,
      button,
      insert,
      isOpen,
      ownFocus,
      children,
      className,
      closePopover,
      panelClassName,
      panelPaddingSize,
      panelProps,
      panelRef,
      panelStyle,
      popoverScreenReaderText,
      popoverRef,
      hasArrow,
      arrowChildren,
      repositionOnScroll,
      repositionToCrossAxis,
      zIndex,
      attachToAnchor,
      display,
      offset,
      onPositionChange,
      buffer,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      'aria-live': ariaLiveProp,
      container,
      focusTrapProps,
      initialFocus: initialFocusProp,
      tabIndex: _tabIndexProp,
      ...rest
    } = this.props;
    const tabIndexProp = panelProps?.tabIndex ?? _tabIndexProp;
    const styles = euiPopoverStyles();
    const popoverStyles = [styles.euiPopover, {
      display,
      label: display
    }];
    const classes = classNames('euiPopover', {
      'euiPopover-isOpen': this.state.isOpening
    }, className);
    const showArrow = hasArrow && !attachToAnchor;
    let panel;
    if (!this.state.suppressingPopover && (isOpen || this.state.isClosing)) {
      let tabIndex = tabIndexProp;
      let initialFocus = initialFocusProp;
      let ariaDescribedby;
      let ariaLive;
      const panelAriaModal = panelProps?.hasOwnProperty('aria-modal') ? panelProps['aria-modal'] : 'true';
      const panelRole = panelProps?.hasOwnProperty('role') ? panelProps.role : 'dialog';
      if (ownFocus || panelAriaModal !== 'true') {
        tabIndex = tabIndexProp ?? 0;
        ariaLive = 'off';
        if (!initialFocus) {
          initialFocus = () => this.panel;
        }
      } else {
        ariaLive = ariaLiveProp ?? 'assertive';
      }
      let focusTrapScreenReaderText;
      if (ownFocus || popoverScreenReaderText) {
        ariaDescribedby = this.descriptionId;
        focusTrapScreenReaderText = <EuiScreenReaderOnly>
            <p id={this.descriptionId}>
              {ownFocus && <EuiI18n token="euiPopover.screenReaderAnnouncement" default="You are in a dialog. Press Escape, or tap/click outside the dialog to close." />}
              {popoverScreenReaderText}
            </p>
          </EuiScreenReaderOnly>;
      }
      const returnFocus = this.state.isOpenStable ? returnFocusConfig : false;
      panel = <EuiPortal {...insert && {
        insert
      }}>
          <EuiFocusTrap clickOutsideDisables={true} onClickOutside={this.onClickOutside} returnFocus={returnFocus} // Ignore temporary state of indecisive focus
        initialFocus={initialFocus} onEscapeKey={this.onEscapeKey} disabled={!ownFocus || !this.state.isOpenStable || this.state.isClosing} {...focusTrapProps}>
            <EuiPopoverPanel {...panelProps} panelRef={this.panelRef} isOpen={this.state.isOpening} position={this.state.arrowPosition} isAttached={attachToAnchor} className={classNames(panelClassName, panelProps?.className)} hasShadow={false} paddingSize={panelPaddingSize} tabIndex={tabIndex} aria-live={ariaLive} role={panelRole} aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} aria-modal={panelAriaModal} aria-describedby={ariaDescribedby} style={{
            ...this.state.popoverStyles,
            // Adding `will-change` to reduce risk of a blurry animation in Chrome 86+
            willChange: !this.state.isOpenStable ? 'transform, opacity' : undefined
          }}>
              {showArrow && this.state.arrowPosition && <EuiPopoverArrow position={this.state.arrowPosition} style={this.state.arrowStyles}>
                  {arrowChildren}
                </EuiPopoverArrow>}
              {focusTrapScreenReaderText}
              <EuiMutationObserver observerOptions={{
              attributes: true,
              // element attribute changes
              childList: true,
              // added/removed elements
              characterData: true,
              // text changes
              subtree: true // watch all child elements
            }} onMutation={this.onMutation}>
                {mutationRef => <div ref={mutationRef}>{children}</div>}
              </EuiMutationObserver>
            </EuiPopoverPanel>
          </EuiFocusTrap>
        </EuiPortal>;
    }

    // react-focus-on and related do not register outside click detection
    // when disabled, so we still need to conditionally check for that ourselves
    if (ownFocus) {
      return <div css={popoverStyles} className={classes} ref={this.popoverRef} {...rest}>
          {button instanceof HTMLElement ? null : button}
          {panel}
        </div>;
    } else {
      return <EuiOutsideClickDetector onOutsideClick={this.closePopover}>
          <div css={popoverStyles} className={classes} ref={this.popoverRef} onKeyDown={this.onKeyDown} {...rest}>
            {button instanceof HTMLElement ? null : button}
            {panel}
          </div>
        </EuiOutsideClickDetector>;
    }
  }
}
EuiPopover.propTypes = {
  /**
     * Alignment of the popover and arrow relative to the button
     */
  anchorPosition: PropTypes.any,
  /**
     * Style and position alteration for arrow-less attachment.
     * Intended for use with inputs as anchors, e.g. EuiInputPopover
     */
  attachToAnchor: PropTypes.bool,
  /**
     * Triggering element for which to align the popover to
     */
  button: PropTypes.any.isRequired,
  /**
     * Callback to handle hiding of the popover
     */
  closePopover: PropTypes.func.isRequired,
  /**
     * Restrict the popover's position within this element
     */
  container: PropTypes.any,
  /**
     * CSS display type for both the popover and anchor
     */
  display: PropTypes.any,
  /**
     * Object of props passed to EuiFocusTrap
     */
  focusTrapProps: PropTypes.any,
  /**
     * Show arrow indicating to originating button
     */
  hasArrow: PropTypes.bool,
  /**
     * Specifies what element should initially have focus; Can be a DOM
     * node, or a selector string (which will be passed to
     * document.querySelector() to find the DOM node), or a function that
     * returns a DOM node.
     *
     * If not passed, initial focus defaults to the popover panel.
     */
  initialFocus: PropTypes.any,
  /**
     * Passed directly to EuiPortal for DOM positioning. Both properties are
     * required if prop is specified
     */
  insert: PropTypes.shape({
    sibling: PropTypes.any.isRequired,
    position: PropTypes.oneOf(["before", "after"]).isRequired
  }),
  /**
     * Visibility state of the popover
     */
  isOpen: PropTypes.bool,
  /**
     * Traps tab focus within the popover contents
     */
  ownFocus: PropTypes.bool,
  /**
     * Custom class added to the EuiPanel containing the popover contents
     */
  panelClassName: PropTypes.string,
  /**
     * EuiPanel padding on all sides
     */
  panelPaddingSize: PropTypes.any,
  /**
     * Standard DOM `style` attribute. Passed to the EuiPanel
     */
  panelStyle: PropTypes.any,
  /**
     * Object of props passed to EuiPanel. See #EuiPopoverPanelProps
     */
  panelProps: PropTypes.shape({
    element: PropTypes.oneOf(["div"]),
    /**
       * Padding for all four sides
       */
    paddingSize: PropTypes.any,
    /**
       * Corner border radius
       */
    borderRadius: PropTypes.any,
    /**
       * When true the panel will grow in height to match `EuiFlexItem`
       */
    grow: PropTypes.bool,
    panelRef: PropTypes.any,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }),
  panelRef: PropTypes.any,
  /**
     * Optional screen reader instructions to announce upon popover open,
     * in addition to EUI's default popover instructions for Escape on close.
     * Useful for popovers that may have additional keyboard capabilities such as
     * arrow navigation.
     */
  popoverScreenReaderText: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
  popoverRef: PropTypes.any,
  /**
     * When `true`, the popover's position is re-calculated when the user
     * scrolls, this supports having fixed-position popover anchors. When nesting
     * an `EuiPopover` in a scrollable container, `repositionOnScroll` should be `true`
     */
  repositionOnScroll: PropTypes.bool,
  /**
     * By default, popovers will attempt to position themselves along the initial
     * axis specified. If there is not enough room either vertically or horizontally
     * however, the popover will attempt to reposition itself along the secondary
     * cross axis if there is room there instead.
     *
     * If you do not not want this repositioning to occur (and it is acceptable for
     * the popover to appear offscreen), set this to false to disable this behavior.
     *
     * @default true
     */
  repositionToCrossAxis: PropTypes.bool,
  /**
     * By default, popover content inherits the z-index of the anchor
     * component; pass `zIndex` to override
     */
  zIndex: PropTypes.number,
  /**
     * Distance away from the anchor that the popover will render
     */
  offset: PropTypes.number,
  /**
     * Minimum distance between the popover and the bounding container;
     * Pass an array of 4 values to adjust each side differently: `[top, right, bottom, left]`
     * @default 16
     */
  buffer: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.any.isRequired]),
  /**
     * Element to pass as the child element of the arrow;
     * Use case is typically limited to an accompanying `EuiBeacon`
     */
  arrowChildren: PropTypes.node,
  /**
     * Provide a name to the popover panel
     */
  "aria-label": PropTypes.string,
  /**
     * Alternative option to `aria-label` that takes an `id`.
     * Usually takes the `id` of the popover title
     */
  "aria-labelledby": PropTypes.string,
  /**
     * Function callback for when the popover positon changes
     */
  onPositionChange: PropTypes.func,
  className: PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any
};
try {
  getPopoverPositionFromAnchorPosition.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/popover/popover.tsx',
    description: '',
    displayName: 'getPopoverPositionFromAnchorPosition',
    methods: [],
    props: {},
    extendedInterfaces: ['String', 'RelativeIndexable', 'EuiPopoverProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}
try {
  getPopoverAlignFromAnchorPosition.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/popover/popover.tsx',
    description: '',
    displayName: 'getPopoverAlignFromAnchorPosition',
    methods: [],
    props: {},
    extendedInterfaces: ['String', 'RelativeIndexable', 'EuiPopoverProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}
try {
  EuiPopover.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/popover/popover.tsx',
    description: '',
    displayName: 'EuiPopover',
    methods: [],
    props: {
      anchorPosition: {
        defaultValue: {
          value: 'downCenter'
        },
        description: 'Alignment of the popover and arrow relative to the button',
        name: 'anchorPosition',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"upCenter" | "upLeft" | "upRight" | "downCenter" | "downLeft" | "downRight" | "leftCenter" | "leftUp" | "leftDown" | "rightCenter" | "rightUp" | "rightDown"',
          value: [{
            value: '"upCenter"'
          }, {
            value: '"upLeft"'
          }, {
            value: '"upRight"'
          }, {
            value: '"downCenter"'
          }, {
            value: '"downLeft"'
          }, {
            value: '"downRight"'
          }, {
            value: '"leftCenter"'
          }, {
            value: '"leftUp"'
          }, {
            value: '"leftDown"'
          }, {
            value: '"rightCenter"'
          }, {
            value: '"rightUp"'
          }, {
            value: '"rightDown"'
          }]
        }
      },
      attachToAnchor: {
        defaultValue: null,
        description: 'Style and position alteration for arrow-less attachment.\n' + 'Intended for use with inputs as anchors, e.g. EuiInputPopover',
        name: 'attachToAnchor',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      button: {
        defaultValue: null,
        description: 'Triggering element for which to align the popover to',
        name: 'button',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: true,
        type: {
          name: 'ReactNode'
        }
      },
      closePopover: {
        defaultValue: null,
        description: 'Callback to handle hiding of the popover',
        name: 'closePopover',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: true,
        type: {
          name: 'NoArgCallback<void>'
        }
      },
      container: {
        defaultValue: null,
        description: "Restrict the popover's position within this element",
        name: 'container',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'HTMLElement'
        }
      },
      display: {
        defaultValue: {
          value: 'inline-block'
        },
        description: 'CSS display type for both the popover and anchor',
        name: 'display',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Display'
        }
      },
      focusTrapProps: {
        defaultValue: null,
        description: 'Object of props passed to EuiFocusTrap',
        name: 'focusTrapProps',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Partial<EuiFocusTrapProps>'
        }
      },
      hasArrow: {
        defaultValue: {
          value: 'true'
        },
        description: 'Show arrow indicating to originating button',
        name: 'hasArrow',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      initialFocus: {
        defaultValue: null,
        description: 'Specifies what element should initially have focus; Can be a DOM\n' + 'node, or a selector string (which will be passed to\n' + 'document.querySelector() to find the DOM node), or a function that\n' + 'returns a DOM node.\n' + '\n' + 'If not passed, initial focus defaults to the popover panel.',
        name: 'initialFocus',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'ElementTarget'
        }
      },
      insert: {
        defaultValue: null,
        description: 'Passed directly to EuiPortal for DOM positioning. Both properties are\n' + 'required if prop is specified',
        name: 'insert',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: '{ sibling: HTMLElement; position: "before" | "after"; }'
        }
      },
      isOpen: {
        defaultValue: {
          value: 'false'
        },
        description: 'Visibility state of the popover',
        name: 'isOpen',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      ownFocus: {
        defaultValue: {
          value: 'true'
        },
        description: 'Traps tab focus within the popover contents',
        name: 'ownFocus',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      panelClassName: {
        defaultValue: null,
        description: 'Custom class added to the EuiPanel containing the popover contents',
        name: 'panelClassName',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      panelPaddingSize: {
        defaultValue: {
          value: 'm'
        },
        description: 'EuiPanel padding on all sides',
        name: 'panelPaddingSize',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "none" | "m" | "xs" | "l" | "xl"',
          value: [{
            value: '"s"'
          }, {
            value: '"none"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"l"'
          }, {
            value: '"xl"'
          }]
        }
      },
      panelStyle: {
        defaultValue: null,
        description: 'Standard DOM `style` attribute. Passed to the EuiPanel',
        name: 'panelStyle',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      panelProps: {
        defaultValue: null,
        description: 'Object of props passed to EuiPanel. See #EuiPopoverPanelProps',
        name: 'panelProps',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Omit<_EuiPanelDivlike, "color" | "style" | "hasBorder" | "hasShadow">'
        }
      },
      panelRef: {
        defaultValue: null,
        description: '',
        name: 'panelRef',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: '(instance: HTMLElement) => void'
        }
      },
      popoverScreenReaderText: {
        defaultValue: null,
        description: 'Optional screen reader instructions to announce upon popover open,\n' + "in addition to EUI's default popover instructions for Escape on close.\n" + 'Useful for popovers that may have additional keyboard capabilities such as\n' + 'arrow navigation.',
        name: 'popoverScreenReaderText',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      popoverRef: {
        defaultValue: null,
        description: '',
        name: 'popoverRef',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLDivElement>'
        }
      },
      repositionOnScroll: {
        defaultValue: null,
        description: "When `true`, the popover's position is re-calculated when the user\n" + 'scrolls, this supports having fixed-position popover anchors. When nesting\n' + 'an `EuiPopover` in a scrollable container, `repositionOnScroll` should be `true`',
        name: 'repositionOnScroll',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      repositionToCrossAxis: {
        defaultValue: {
          value: 'true'
        },
        description: 'By default, popovers will attempt to position themselves along the initial\n' + 'axis specified. If there is not enough room either vertically or horizontally\n' + 'however, the popover will attempt to reposition itself along the secondary\n' + 'cross axis if there is room there instead.\n' + '\n' + 'If you do not not want this repositioning to occur (and it is acceptable for\n' + 'the popover to appear offscreen), set this to false to disable this behavior.',
        name: 'repositionToCrossAxis',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      zIndex: {
        defaultValue: null,
        description: 'By default, popover content inherits the z-index of the anchor\n' + 'component; pass `zIndex` to override',
        name: 'zIndex',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      offset: {
        defaultValue: null,
        description: 'Distance away from the anchor that the popover will render',
        name: 'offset',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      buffer: {
        defaultValue: {
          value: '16'
        },
        description: 'Minimum distance between the popover and the bounding container;\n' + 'Pass an array of 4 values to adjust each side differently: `[top, right, bottom, left]`',
        name: 'buffer',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'number | [number, number, number, number]'
        }
      },
      arrowChildren: {
        defaultValue: null,
        description: 'Element to pass as the child element of the arrow;\n' + 'Use case is typically limited to an accompanying `EuiBeacon`',
        name: 'arrowChildren',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: 'ReactNode'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Provide a name to the popover panel\n' + 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-labelledby': {
        defaultValue: null,
        description: 'Alternative option to `aria-label` that takes an `id`.\n' + 'Usually takes the `id` of the popover title\n' + 'Identifies the element (or elements) that labels the current element.\n' + '@see aria-describedby.',
        name: 'aria-labelledby',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onPositionChange: {
        defaultValue: null,
        description: 'Function callback for when the popover positon changes',
        name: 'onPositionChange',
        parent: {
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        },
        declarations: [{
          fileName: 'eui/src/components/popover/popover.tsx',
          name: 'EuiPopoverProps'
        }],
        required: false,
        type: {
          name: '(position: EuiPopoverPosition) => void'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      }
    },
    extendedInterfaces: ['String', 'RelativeIndexable', 'EuiPopoverProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}