/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { FixedSizeList } from 'react-window';
import { RenderWithEuiStylesMemoizer } from '../../../services';
import { EuiFlexGroup, EuiFlexItem } from '../../flex';
import { EuiHighlight } from '../../highlight';
import { EuiMark } from '../../mark';
import { EuiText } from '../../text';
import { EuiLoadingSpinner } from '../../loading';
import { EuiI18n } from '../../i18n';
import { EuiFilterSelectItem } from '../../filter_group/filter_select_item';
import { EuiBadge } from '../../badge';
import { EuiTextTruncate } from '../../text_truncate';
import { EuiInputPopoverWidthContext } from '../../popover/input_popover';
import { EuiComboBoxOptionAppendPrepend } from '../utils';
import { EuiComboBoxTitle } from './combo_box_title';
import { euiComboBoxOptionListStyles, LIST_MAX_HEIGHT } from './combo_box_options_list.styles';
var hitEnterBadge = ___EmotionJSX(EuiBadge, {
  className: "euiComboBoxOption__enterBadge",
  color: "hollow",
  iconType: "returnKey",
  "aria-hidden": "true"
});
export class EuiComboBoxOptionsList extends Component {
  listRef = null;
  static contextType = EuiInputPopoverWidthContext;
  static defaultProps = {
    'data-test-subj': '',
    rowHeight: 29,
    // row height of default option renderer
    isCaseSensitive: false
  };
  componentDidUpdate(prevProps) {
    if (this.listRef && typeof this.props.activeOptionIndex !== 'undefined' && this.props.activeOptionIndex !== prevProps.activeOptionIndex) {
      this.listRef.scrollToItem(this.props.activeOptionIndex, 'auto');
    }
  }
  setListRef = ref => {
    this.listRef = ref;
  };
  ListInnerElement = ({
    children,
    ...rest
  }) => {
    return <div {...rest} aria-label={this.props.listboxAriaLabel} id={this.props.rootId('listbox')} role="listbox" tabIndex="0">
        {children}
      </div>;
  };
  ListRow = ({
    data,
    index,
    style
  }) => {
    const option = data[index];
    const {
      key,
      isGroupLabelOption,
      label,
      value,
      prepend,
      append,
      truncationProps: _truncationProps,
      ...rest
    } = option;
    const {
      singleSelection,
      selectedOptions,
      onOptionClick,
      activeOptionIndex,
      renderOption,
      searchValue,
      rootId,
      matchingOptions
    } = this.props;
    const optionIndex = matchingOptions.indexOf(option);
    const hasTruncationProps = this.props.truncationProps || _truncationProps;
    const truncationProps = hasTruncationProps ?
    // Individual truncation settings should override component prop
    {
      ...this.props.truncationProps,
      ..._truncationProps
    } : undefined;
    if (isGroupLabelOption) {
      return <div key={key ?? label} style={style}>
          <EuiComboBoxTitle>
            {prepend}
            {label}
            {append}
          </EuiComboBoxTitle>
        </div>;
    }
    let checked = undefined;
    if (singleSelection && selectedOptions.length && selectedOptions[0].label === label && selectedOptions[0].key === key) {
      checked = 'on';
    }
    const optionIsFocused = activeOptionIndex === index;
    const optionIsDisabled = option.hasOwnProperty('disabled') && option.disabled === true;
    return <EuiFilterSelectItem style={style} key={option.key ?? option.label} onClick={() => {
      if (onOptionClick) {
        onOptionClick(option);
      }
    }} isFocused={optionIsFocused} checked={checked} showIcons={singleSelection ? true : false} id={rootId(`_option-${index}`)} title={label} aria-setsize={matchingOptions.length} aria-posinset={optionIndex + 1} {...rest}>
        <span className="euiComboBoxOption__contentWrapper">
          <EuiComboBoxOptionAppendPrepend option={option} classNamePrefix="euiComboBoxOption" marginSize="s">
            <span className="euiComboBoxOption__content">
              {renderOption ? renderOption(option, searchValue, 'euiComboBoxOption__renderOption') : this.renderTruncatedOption(label, truncationProps)}
            </span>
          </EuiComboBoxOptionAppendPrepend>
          {optionIsFocused && !optionIsDisabled ? hitEnterBadge : null}
        </span>
      </EuiFilterSelectItem>;
  };
  optionWidth;
  setOptionWidth = width => {
    this.optionWidth = width;
  };
  renderTruncatedOption = (text, truncationProps) => {
    const searchValue = this.props.searchValue.trim();
    if (!truncationProps && !searchValue) {
      // Default to CSS text-overflow
      return text;
    }
    if (!searchValue) {
      return <EuiTextTruncate width={this.optionWidth} onResize={this.setOptionWidth} {...truncationProps} text={text}>
          {text => text}
        </EuiTextTruncate>;
    }
    const searchPositionStart = this.props.isCaseSensitive ? text.indexOf(searchValue) : text.toLowerCase().indexOf(searchValue.toLowerCase());
    const searchPositionCenter = searchPositionStart + Math.floor(searchValue.length / 2);
    return <EuiTextTruncate width={this.optionWidth} onResize={this.setOptionWidth} {...truncationProps}
    // When searching, don't allow overriding the truncation settings
    truncation="startEnd" truncationPosition={searchPositionCenter} text={text}>
        {text => <>
            {text.length >= searchValue.length ? <EuiHighlight search={searchValue} strict={this.props.isCaseSensitive}>
                {text}
              </EuiHighlight> :
        // If the available truncated text is shorter than the full search string,
        // just highlight the entire truncated text
        <EuiMark>{text}</EuiMark>}
          </>}
      </EuiTextTruncate>;
  };
  render() {
    const {
      'data-test-subj': dataTestSubj,
      activeOptionIndex,
      areAllOptionsSelected,
      customOptionText,
      fullWidth,
      getSelectedOptionForSearchValue,
      isCaseSensitive,
      isLoading,
      listRef,
      matchingOptions,
      onCloseList,
      onCreateOption,
      onOptionClick,
      onOptionEnterKey,
      onScroll,
      options,
      renderOption,
      rootId,
      rowHeight,
      scrollToIndex,
      searchValue,
      selectedOptions,
      singleSelection,
      delimiter,
      truncationProps,
      listboxAriaLabel,
      ...rest
    } = this.props;
    let emptyStateContent;
    if (isLoading) {
      emptyStateContent = <EuiFlexGroup gutterSize="s" justifyContent="center" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiLoadingSpinner size="m" />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiI18n token="euiComboBoxOptionsList.loadingOptions" default="Loading options" />
          </EuiFlexItem>
        </EuiFlexGroup>;
    } else if (searchValue && matchingOptions && matchingOptions.length === 0) {
      if (onCreateOption && getSelectedOptionForSearchValue) {
        if (delimiter && searchValue.includes(delimiter)) {
          emptyStateContent = <div className="euiComboBoxOption__contentWrapper">
              <p className="euiComboBoxOption__emptyStateText">
                <EuiI18n token="euiComboBoxOptionsList.delimiterMessage" default="Add each item separated by {delimiter}" values={{
                delimiter: <strong>{delimiter}</strong>
              }} />
              </p>
              {hitEnterBadge}
            </div>;
        } else {
          const selectedOptionForValue = getSelectedOptionForSearchValue({
            isCaseSensitive,
            searchValue,
            selectedOptions
          });
          if (selectedOptionForValue) {
            // Disallow duplicate custom options.
            emptyStateContent = <p>
                <EuiI18n token="euiComboBoxOptionsList.alreadyAdded" default="{label} has already been added" values={{
                label: <strong>{selectedOptionForValue.label}</strong>
              }} />
              </p>;
          } else {
            const highlightSearchValue = (text, searchValue) => {
              const reg = new RegExp(/(\{searchValue})/, 'gi');
              const parts = text.split(reg);
              return <p className="euiComboBoxOption__emptyStateText">
                  {parts.map((part, idx) => part.match(reg) ? <strong key={idx}>{searchValue}</strong> : part)}
                </p>;
            };
            emptyStateContent = <div className="euiComboBoxOption__contentWrapper">
                {customOptionText ? highlightSearchValue(customOptionText, searchValue) : <p className="euiComboBoxOption__emptyStateText">
                    <EuiI18n token="euiComboBoxOptionsList.createCustomOption" default="Add {searchValue} as a custom option" values={{
                  searchValue: <strong>{searchValue}</strong>
                }} />
                  </p>}
                {hitEnterBadge}
              </div>;
          }
        }
      } else {
        emptyStateContent = <p>
            <EuiI18n token="euiComboBoxOptionsList.noMatchingOptions" default="{searchValue} doesn't match any options" values={{
            searchValue: <strong>{searchValue}</strong>
          }} />
          </p>;
      }
    } else if (!options.length) {
      emptyStateContent = <p>
          <EuiI18n token="euiComboBoxOptionsList.noAvailableOptions" default="There aren't any options available" />
        </p>;
    } else if (areAllOptionsSelected) {
      emptyStateContent = <p>
          <EuiI18n token="euiComboBoxOptionsList.allOptionsSelected" default="You've selected all available options" />
        </p>;
    }
    const numVisibleOptions = matchingOptions.length < 7 ? matchingOptions.length : 7;
    const height = numVisibleOptions * (rowHeight + 1); // Add one for the border

    // bounded by max-height of .euiComboBoxOptionsList
    const boundedHeight = height > LIST_MAX_HEIGHT ? LIST_MAX_HEIGHT : height;
    return <RenderWithEuiStylesMemoizer>
        {stylesMemoizer => {
        const styles = stylesMemoizer(euiComboBoxOptionListStyles);
        return <div css={styles.euiComboBoxOptionList} className="euiComboBoxOptionsList" data-test-subj={classNames('comboBoxOptionsList', dataTestSubj)} ref={listRef} {...rest}>
              {emptyStateContent ? <EuiText size="xs" css={styles.euiComboBoxOptionsList__empty} className="euiComboBoxOptionsList__empty">
                  {emptyStateContent}
                </EuiText> : <FixedSizeList css={styles.euiComboBoxOptionList__virtualization} className="euiComboBoxOptionsList__virtualization" height={boundedHeight} onScroll={onScroll} itemCount={matchingOptions.length} itemSize={rowHeight} itemData={matchingOptions} ref={this.setListRef} innerElementType={this.ListInnerElement} width={this.context}>
                  {this.ListRow}
                </FixedSizeList>}
            </div>;
      }}
      </RenderWithEuiStylesMemoizer>;
  }
}
EuiComboBoxOptionsList.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  activeOptionIndex: PropTypes.number,
  areAllOptionsSelected: PropTypes.bool,
  listboxAriaLabel: PropTypes.string.isRequired,
  /**
     * Creates a custom text option. You can use `{searchValue}` inside your string to better customize your text.
     * It won't show if there's no onCreateOption.
     */
  customOptionText: PropTypes.string,
  fullWidth: PropTypes.bool,
  getSelectedOptionForSearchValue: PropTypes.func,
  isCaseSensitive: PropTypes.bool,
  isLoading: PropTypes.bool,
  listRef: PropTypes.any.isRequired,
  matchingOptions: PropTypes.arrayOf(PropTypes.shape({
    isGroupLabelOption: PropTypes.bool,
    label: PropTypes.string.isRequired,
    key: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      isGroupLabelOption: PropTypes.bool,
      label: PropTypes.string.isRequired,
      key: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.any.isRequired),
      value: PropTypes.any,
      prepend: PropTypes.node,
      append: PropTypes.node,
      truncationProps: PropTypes.any,
      /**
         * Optional custom tooltip content for the button
         */
      toolTipContent: PropTypes.node,
      /**
         * Optional props to pass to the underlying **[EuiToolTip](/#/display/tooltip)**
         */
      toolTipProps: PropTypes.any,
      className: PropTypes.string,
      "aria-label": PropTypes.string,
      "data-test-subj": PropTypes.string,
      css: PropTypes.any
    }).isRequired),
    value: PropTypes.any,
    prepend: PropTypes.node,
    append: PropTypes.node,
    truncationProps: PropTypes.any,
    /**
       * Optional custom tooltip content for the button
       */
    toolTipContent: PropTypes.node,
    /**
       * Optional props to pass to the underlying **[EuiToolTip](/#/display/tooltip)**
       */
    toolTipProps: PropTypes.any,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }).isRequired).isRequired,
  onCloseList: PropTypes.func.isRequired,
  onCreateOption: PropTypes.func,
  onOptionClick: PropTypes.func,
  onOptionEnterKey: PropTypes.func,
  onScroll: PropTypes.any,
  /**
     * Array of EuiComboBoxOptionOption objects. See #EuiComboBoxOptionOption
     */
  options: PropTypes.arrayOf(PropTypes.shape({
    isGroupLabelOption: PropTypes.bool,
    label: PropTypes.string.isRequired,
    key: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      isGroupLabelOption: PropTypes.bool,
      label: PropTypes.string.isRequired,
      key: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.any.isRequired),
      value: PropTypes.any,
      prepend: PropTypes.node,
      append: PropTypes.node,
      truncationProps: PropTypes.any,
      toolTipContent: PropTypes.node,
      toolTipProps: PropTypes.any,
      className: PropTypes.string,
      "aria-label": PropTypes.string,
      "data-test-subj": PropTypes.string,
      css: PropTypes.any
    }).isRequired),
    value: PropTypes.any,
    prepend: PropTypes.node,
    append: PropTypes.node,
    truncationProps: PropTypes.any,
    toolTipContent: PropTypes.node,
    toolTipProps: PropTypes.any,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }).isRequired).isRequired,
  renderOption: PropTypes.func,
  rootId: PropTypes.any.isRequired,
  rowHeight: PropTypes.number.isRequired,
  scrollToIndex: PropTypes.number,
  searchValue: PropTypes.string.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({
    isGroupLabelOption: PropTypes.bool,
    label: PropTypes.string.isRequired,
    key: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      isGroupLabelOption: PropTypes.bool,
      label: PropTypes.string.isRequired,
      key: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.any.isRequired),
      value: PropTypes.any,
      prepend: PropTypes.node,
      append: PropTypes.node,
      truncationProps: PropTypes.any,
      toolTipContent: PropTypes.node,
      toolTipProps: PropTypes.any,
      className: PropTypes.string,
      "aria-label": PropTypes.string,
      "data-test-subj": PropTypes.string,
      css: PropTypes.any
    }).isRequired),
    value: PropTypes.any,
    prepend: PropTypes.node,
    append: PropTypes.node,
    truncationProps: PropTypes.any,
    toolTipContent: PropTypes.node,
    toolTipProps: PropTypes.any,
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any
  }).isRequired).isRequired,
  singleSelection: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.shape({
    asPlainText: PropTypes.bool
  }).isRequired]),
  delimiter: PropTypes.string,
  truncationProps: PropTypes.any
};
try {
  EuiComboBoxOptionsList.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
    description: '',
    displayName: 'EuiComboBoxOptionsList',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: {
          value: ''
        },
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      activeOptionIndex: {
        defaultValue: null,
        description: '',
        name: 'activeOptionIndex',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      areAllOptionsSelected: {
        defaultValue: null,
        description: '',
        name: 'areAllOptionsSelected',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      listboxAriaLabel: {
        defaultValue: null,
        description: '',
        name: 'listboxAriaLabel',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      customOptionText: {
        defaultValue: null,
        description: 'Creates a custom text option. You can use `{searchValue}` inside your string to better customize your text.\n' + "It won't show if there's no onCreateOption.",
        name: 'customOptionText',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      fullWidth: {
        defaultValue: null,
        description: '',
        name: 'fullWidth',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      getSelectedOptionForSearchValue: {
        defaultValue: null,
        description: '',
        name: 'getSelectedOptionForSearchValue',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(params: { isCaseSensitive?: boolean; searchValue: string; selectedOptions: any[]; }) => EuiComboBoxOptionOption<T>'
        }
      },
      isCaseSensitive: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'isCaseSensitive',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      isLoading: {
        defaultValue: null,
        description: '',
        name: 'isLoading',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      listRef: {
        defaultValue: null,
        description: '',
        name: 'listRef',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(instance: HTMLDivElement) => void'
        }
      },
      matchingOptions: {
        defaultValue: null,
        description: '',
        name: 'matchingOptions',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'EuiComboBoxOptionOption<T>[]'
        }
      },
      onCloseList: {
        defaultValue: null,
        description: '',
        name: 'onCloseList',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(event: Event) => void'
        }
      },
      onCreateOption: {
        defaultValue: null,
        description: '',
        name: 'onCreateOption',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(searchValue: string, options: EuiComboBoxOptionOption<T>[]) => boolean | void'
        }
      },
      onOptionClick: {
        defaultValue: null,
        description: '',
        name: 'onOptionClick',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OptionHandler<T>'
        }
      },
      onOptionEnterKey: {
        defaultValue: null,
        description: '',
        name: 'onOptionEnterKey',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'OptionHandler<T>'
        }
      },
      onScroll: {
        defaultValue: null,
        description: '',
        name: 'onScroll',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(props: ListOnScrollProps) => any'
        }
      },
      options: {
        defaultValue: null,
        description: 'Array of EuiComboBoxOptionOption objects. See #EuiComboBoxOptionOption',
        name: 'options',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'EuiComboBoxOptionOption<T>[]'
        }
      },
      renderOption: {
        defaultValue: null,
        description: '',
        name: 'renderOption',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: '(option: EuiComboBoxOptionOption<T>, searchValue: string, OPTION_CONTENT_CLASSNAME: string) => ReactNode'
        }
      },
      rootId: {
        defaultValue: null,
        description: '',
        name: 'rootId',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: '(idSuffix?: string) => string'
        }
      },
      rowHeight: {
        defaultValue: {
          value: '29'
        },
        description: '',
        name: 'rowHeight',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      scrollToIndex: {
        defaultValue: null,
        description: '',
        name: 'scrollToIndex',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      searchValue: {
        defaultValue: null,
        description: '',
        name: 'searchValue',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      selectedOptions: {
        defaultValue: null,
        description: '',
        name: 'selectedOptions',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'EuiComboBoxOptionOption<T>[]'
        }
      },
      singleSelection: {
        defaultValue: null,
        description: '',
        name: 'singleSelection',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean | EuiComboBoxSingleSelectionShape'
        }
      },
      delimiter: {
        defaultValue: null,
        description: '',
        name: 'delimiter',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      truncationProps: {
        defaultValue: null,
        description: '',
        name: 'truncationProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Partial<Omit<EuiTextTruncateProps, "children" | "text">>'
        }
      }
    },
    extendedInterfaces: ['CommonProps']
  };
} catch (e) {}
try {
  EuiComboBoxOptionsList.contextType.__docgenInfo = {
    tags: {
      see: '{@link https ://react.dev/learn/passing-data-deeply-with-context React Docs}\n' + '{@link https ://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/ React TypeScript Cheatsheet}',
      example: '```tsx\n' + "import { createContext } from 'react';\n" + '\n' + "const ThemeContext = createContext('light');\n" + '```'
    },
    filePath: '/app/packages/eui/src/components/combo_box/combo_box_options_list/combo_box_options_list.tsx',
    description: 'Context lets components pass information deep down without explicitly\n' + 'passing props.\n' + '\n' + 'Created from {@link createContext}',
    displayName: 'EuiComboBoxOptionsList.contextType',
    methods: [],
    props: {},
    extendedInterfaces: ['CommonProps']
  };
} catch (e) {}