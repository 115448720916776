import React from 'react';
import PropTypes from "prop-types";
import { useView } from 'react-view';
import { GuideSectionPropsDescription } from './guide_section_props_description';

// @ts-ignore not TS
import Knobs from '../../../services/playground/knobs';
// @ts-ignore not TS
import { propUtilityForPlayground } from '../../../services/playground';
export var GuideSectionPropsTable = function GuideSectionPropsTable(_ref) {
  var componentName = _ref.componentName,
    component = _ref.component;
  var docgenInfo = Array.isArray(component.__docgenInfo) ? component.__docgenInfo[0] : component.__docgenInfo;
  var props = docgenInfo.props;
  return ___EmotionJSX(React.Fragment, null, componentName && ___EmotionJSX(GuideSectionPropsDescription, {
    componentName: componentName,
    component: component
  }), ___EmotionJSX(PlaygroundProps, {
    isPlayground: false,
    config: {
      componentName: componentName,
      props: propUtilityForPlayground(props),
      scope: component
    }
  }));
};
GuideSectionPropsTable.propTypes = {
  componentName: PropTypes.any,
  component: PropTypes.any.isRequired
};
var PlaygroundProps = ({
  config,
  isPlayground
}) => {
  const params = useView(config);
  return <Knobs {...params.knobProps} isPlayground={isPlayground} />;
};
try {
  GuideSectionPropsTable.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_props_table.tsx',
    description: '',
    displayName: 'GuideSectionPropsTable',
    methods: [],
    props: {
      componentName: {
        defaultValue: null,
        description: '',
        name: 'componentName',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_props_table.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'any'
        }
      },
      component: {
        defaultValue: null,
        description: '',
        name: 'component',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_props_table.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'any'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}