function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
import PropTypes from "prop-types";
/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { cloneElement, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import chroma from 'chroma-js';
import { useEuiMemoizedStyles, keys, useEuiPaletteColorBlind } from '../../services';
import { EuiFieldText, EuiFormControlLayout, EuiFormRow, EuiRange } from '../form';
import { useEuiI18n } from '../i18n';
import { EuiPopover } from '../popover';
import { EuiColorPickerSwatch } from './color_picker_swatch';
import { EuiHue } from './hue';
import { EuiSaturation } from './saturation';
import { getChromaColor, parseColor, HEX_FALLBACK, HSV_FALLBACK, RGB_FALLBACK, RGB_JOIN } from './utils';
import { euiColorPickerStyles } from './color_picker.styles';
function isKeyboardEvent(event) {
  return _typeof(event) === 'object' && 'key' in event;
}
var getOutput = function getOutput(text) {
  var showAlpha = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var color = getChromaColor(text, true);
  var isValid = true;
  if (!showAlpha && color !== null) {
    isValid = color.alpha() === 1;
  }
  // Note that if a consumer has disallowed opacity,
  // we still return the color with an alpha channel, but mark it as invalid
  return color ? {
    rgba: color.rgba(),
    hex: color.hex(),
    isValid: isValid
  } : {
    rgba: RGB_FALLBACK,
    hex: HEX_FALLBACK,
    isValid: false
  };
};
var getHsv = function getHsv(hsv) {
  var fallback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  // Chroma's passthrough (RGB) parsing determines that black/white/gray are hue-less and returns `NaN`
  // For our purposes we can process `NaN` as `0` if necessary
  if (!hsv) return HSV_FALLBACK;
  var hue = isNaN(hsv[0]) ? fallback : hsv[0];
  return [hue, hsv[1], hsv[2]];
};
export var EuiColorPicker = ({
  button,
  className,
  color,
  compressed = false,
  disabled,
  display = 'default',
  fullWidth = false,
  id,
  isInvalid,
  mode = 'default',
  onBlur,
  onChange,
  onFocus,
  readOnly = false,
  swatches: _swatches,
  popoverZIndex,
  prepend,
  append,
  showAlpha = false,
  format,
  secondaryInputDisplay = 'none',
  isClearable = false,
  placeholder,
  'data-test-subj': dataTestSubj
}) => {
  const [popoverLabel, colorLabel, colorErrorMessage, transparent, alphaLabel, openLabel, closeLabel] = useEuiI18n(['euiColorPicker.popoverLabel', 'euiColorPicker.colorLabel', 'euiColorPicker.colorErrorMessage', 'euiColorPicker.transparent', 'euiColorPicker.alphaLabel', 'euiColorPicker.openLabel', 'euiColorPicker.closeLabel'], ['Color selection dialog', 'Color value', 'Invalid color value', 'Transparent', 'Alpha channel (opacity) value', 'Press the escape key to close the popover', 'Press the down key to open a popover containing color options']);
  const defaultSwatches = useEuiPaletteColorBlind();
  const swatches = _swatches ?? defaultSwatches;
  const preferredFormat = useMemo(() => {
    if (format) return format;
    const parsed = parseColor(color);
    return parsed != null && typeof parsed === 'object' ? 'rgba' : 'hex';
  }, [color, format]);
  const chromaColor = useMemo(() => getChromaColor(color, showAlpha), [color, showAlpha]);
  const [alphaRangeValue, setAlphaRangeValue] = useState('100');
  const alphaChannel = useMemo(() => {
    return chromaColor ? chromaColor.alpha() : 1;
  }, [chromaColor]);
  useEffect(() => {
    const percent = (alphaChannel * 100).toFixed();
    setAlphaRangeValue(percent);
  }, [alphaChannel]);
  const [isColorSelectorShown, setIsColorSelectorShown] = useState(false);
  const [inputRef, setInputRef] = useState(null); // Ideally this is uses `useRef`, but `EuiFieldText` isn't ready for that

  const prevColor = useRef(chromaColor ? chromaColor.rgba().join() : null);
  const [colorAsHsv, setColorAsHsv] = useState(chromaColor ? getHsv(chromaColor.hsv()) : HSV_FALLBACK);
  const usableHsv = useMemo(() => {
    if (chromaColor && chromaColor.rgba().join() !== prevColor.current) {
      const [h, s, v] = chromaColor.hsv();
      const hue = isNaN(h) ? colorAsHsv[0] : h;
      return [hue, s, v];
    }
    return colorAsHsv;
  }, [chromaColor, colorAsHsv]);
  const saturationRef = useRef(null);
  const swatchRef = useRef(null);
  const testSubjAnchor = classNames('euiColorPickerAnchor', dataTestSubj);
  const updateColorAsHsv = ([h, s, v]) => {
    setColorAsHsv(getHsv([h, s, v], usableHsv[0]));
  };
  const classes = classNames('euiColorPicker', className);
  const styles = useEuiMemoizedStyles(euiColorPickerStyles);
  const handleOnChange = text => {
    const output = getOutput(text, showAlpha);
    if (output.isValid) {
      prevColor.current = output.rgba.join();
    }
    onChange(text, output);
  };
  const handleOnBlur = () => {
    // `onBlur` also gets called when the popover is closing
    // so prevent a second `onBlur` if the popover is open
    if (!isColorSelectorShown && onBlur) {
      onBlur();
    }
  };
  const closeColorSelector = (shouldDelay = false) => {
    if (onBlur) {
      onBlur();
    }
    if (shouldDelay) {
      setTimeout(() => setIsColorSelectorShown(false));
    } else {
      setIsColorSelectorShown(false);
    }
  };
  const showColorSelector = () => {
    if (isColorSelectorShown || readOnly) return;
    if (onFocus) {
      onFocus();
    }
    setIsColorSelectorShown(true);
  };
  const handleToggle = () => {
    if (isColorSelectorShown) {
      closeColorSelector();
    } else {
      showColorSelector();
    }
  };
  const handleFinalSelection = () => {
    // When the trigger is an input, focus the input so you can adjust
    if (inputRef) {
      inputRef.focus();
    }
    closeColorSelector(true);
  };
  const handleOnKeyDown = event => {
    if (event.key === keys.ENTER) {
      if (isColorSelectorShown) {
        handleFinalSelection();
      } else {
        showColorSelector();
      }
    }
  };
  const handleInputActivity = event => {
    if (isKeyboardEvent(event)) {
      if (event.key === keys.ENTER) {
        event.preventDefault();
        handleToggle();
      }
    } else {
      showColorSelector();
    }
  };
  const handleToggleOnKeyDown = event => {
    if (event.key === keys.ARROW_DOWN) {
      event.preventDefault();
      if (isColorSelectorShown) {
        const nextFocusEl = mode !== 'swatch' ? saturationRef : swatchRef;
        if (nextFocusEl.current) {
          nextFocusEl.current.focus();
        }
      } else {
        showColorSelector();
      }
    }
  };
  const handleColorInput = event => {
    handleOnChange(event.target.value);
    const newColor = getChromaColor(event.target.value, showAlpha);
    if (newColor) {
      updateColorAsHsv(newColor.hsv());
    }
  };
  const handleClearInput = () => {
    handleOnChange('');
    if (secondaryInputDisplay === 'none' && isColorSelectorShown) {
      closeColorSelector();
    }
  };
  const updateWithHsv = hsv => {
    const color = chroma.hsv(...hsv).alpha(alphaChannel);
    let formatted;
    if (preferredFormat === 'rgba') {
      formatted = alphaChannel < 1 ? color.rgba().join(RGB_JOIN) : color.rgb().join(RGB_JOIN);
    } else {
      formatted = color.hex();
    }
    handleOnChange(formatted);
    updateColorAsHsv(hsv);
  };
  const handleColorSelection = color => {
    const [h] = usableHsv;
    const [, s, v] = color;
    const newHsv = [h, s, v];
    updateWithHsv(newHsv);
  };
  const handleHueSelection = hue => {
    const [, s, v] = usableHsv;
    const newHsv = [hue, s, v];
    updateWithHsv(newHsv);
  };
  const handleSwatchSelection = color => {
    const newColor = getChromaColor(color, showAlpha);
    handleOnChange(color);
    if (newColor) {
      updateColorAsHsv(newColor.hsv());
    }
    handleFinalSelection();
  };
  const handleAlphaSelection = (e, isValid) => {
    const target = e.target;
    setAlphaRangeValue(target.value || '');
    if (isValid) {
      const alpha = parseInt(target.value, 10) / 100;
      const newColor = chromaColor ? chromaColor.alpha(alpha) : null;
      const hex = newColor ? newColor.hex() : HEX_FALLBACK;
      const rgba = newColor ? newColor.rgba() : RGB_FALLBACK;
      let text;
      if (preferredFormat === 'rgba') {
        text = alpha < 1 ? rgba.join(RGB_JOIN) : rgba.slice(0, 3).join(RGB_JOIN);
      } else {
        text = hex;
      }
      onChange(text, {
        hex,
        rgba,
        isValid: !!newColor
      });
    }
  };
  const showSecondaryInput = mode === 'secondaryInput' || secondaryInputDisplay !== 'none';
  const inlineInput = showSecondaryInput && <EuiFormRow isInvalid={isInvalid} error={isInvalid ? colorErrorMessage : null}>
      <EuiFormControlLayout clear={isClearable && color && !readOnly && !disabled ? {
      onClick: handleClearInput
    } : undefined} readOnly={readOnly} isDisabled={disabled} isInvalid={isInvalid} compressed={display === 'inline' ? compressed : true}>
        <EuiFieldText controlOnly={true} compressed={display === 'inline' ? compressed : true} value={color ? color.toUpperCase() : HEX_FALLBACK} placeholder={!color ? placeholder || transparent : undefined} onChange={handleColorInput} isInvalid={isInvalid} disabled={disabled} readOnly={readOnly} aria-label={colorLabel} autoComplete="off" data-test-subj={`euiColorPickerInput_${secondaryInputDisplay}`} />
      </EuiFormControlLayout>
    </EuiFormRow>;
  const showSecondaryInputOnly = mode === 'secondaryInput';
  const showPicker = mode !== 'swatch' && !showSecondaryInputOnly;
  const showSwatches = mode !== 'picker' && !showSecondaryInputOnly;
  const composite = <>
      {secondaryInputDisplay === 'top' && inlineInput}
      {showPicker && <>
          <EuiSaturation id={id} color={usableHsv} hex={chromaColor ? chromaColor.hex() : undefined} onChange={handleColorSelection} ref={saturationRef} onKeyDown={handleOnKeyDown} />
          <EuiHue id={id} hue={usableHsv[0]} hex={chromaColor ? chromaColor.hex() : undefined} onChange={handleHueSelection} onKeyDown={handleOnKeyDown} />
        </>}
      {showSwatches && <ul css={styles.euiColorPicker__swatches} className="euiColorPicker__swatches">
          {swatches.map((swatch, index) => <li key={swatch}>
              <EuiColorPickerSwatch color={swatch} onClick={() => handleSwatchSelection(swatch)} ref={index === 0 ? swatchRef : undefined} />
            </li>)}
        </ul>}
      {secondaryInputDisplay === 'bottom' && inlineInput}
      {showAlpha && <EuiRange css={styles.euiColorPicker__alphaRange} className="euiColorPicker__alphaRange" data-test-subj="euiColorPickerAlpha" compressed={true} showInput={true} max={100} min={0} value={alphaRangeValue} append="%" onChange={handleAlphaSelection} aria-label={alphaLabel} />}
    </>;
  let buttonOrInput;
  if (button) {
    buttonOrInput = cloneElement(button, {
      onClick: handleToggle,
      id: id,
      disabled: disabled,
      'data-test-subj': testSubjAnchor
    });
  } else {
    const colorStyle = chromaColor ? chromaColor.css() : undefined;
    buttonOrInput = <EuiFormControlLayout icon={chromaColor ? {
      type: 'swatchInput',
      side: 'left',
      color: colorStyle,
      css: styles.euiColorPicker__swatchInputIcon
    } : {
      type: 'stopSlash',
      side: 'left',
      color: 'subdued'
    }} clear={isClearable && color && !readOnly && !disabled ? {
      onClick: handleClearInput
    } : undefined} readOnly={readOnly} fullWidth={fullWidth} compressed={compressed} onKeyDown={handleToggleOnKeyDown} prepend={prepend} append={append} isInvalid={isInvalid} isDisabled={disabled} isDropdown>
        <EuiFieldText className="euiColorPicker__input" onClick={handleInputActivity} onKeyDown={handleInputActivity} onBlur={handleOnBlur} value={color ? color.toUpperCase() : HEX_FALLBACK} placeholder={!color ? placeholder || transparent : undefined} id={id} onChange={handleColorInput} inputRef={setInputRef} isInvalid={isInvalid} compressed={compressed} disabled={disabled} readOnly={readOnly} fullWidth={fullWidth} autoComplete="off" data-test-subj={testSubjAnchor} aria-label={isColorSelectorShown ? openLabel : closeLabel} controlOnly // Don't need two EuiFormControlwrappers
      />
      </EuiFormControlLayout>;
  }
  return display === 'inline' ? <div css={styles.euiColorPicker} className={classes}>
      {composite}
    </div> : <EuiPopover initialFocus={inputRef ?? undefined} button={buttonOrInput} isOpen={isColorSelectorShown} closePopover={handleFinalSelection} zIndex={popoverZIndex} className="euiColorPicker__popoverAnchor" panelClassName="euiColorPicker__popoverPanel" display={button ? 'inline-block' : 'block'} attachToAnchor={button ? false : true} anchorPosition="downLeft" panelPaddingSize="s" tabIndex={-1} aria-label={popoverLabel} focusTrapProps={inputRef ? {
    shards: [inputRef]
  } : undefined}>
      <div css={styles.euiColorPicker} className={classes} data-test-subj="euiColorPickerPopover">
        {composite}
      </div>
    </EuiPopover>;
};
EuiColorPicker.propTypes = {
  /**
     *  Custom element to use instead of text input
     */
  button: PropTypes.element,
  /**
     *  Use the compressed style for EuiFieldText
     */
  compressed: PropTypes.bool,
  display: PropTypes.oneOf(["default", "inline"]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  /**
     *  Custom validation flag
     */
  isInvalid: PropTypes.bool,
  /**
     * Choose between swatches with gradient picker (default), swatches only, gradient picker only, or secondary input only.
     */
  mode: PropTypes.oneOf(["default", "swatch", "picker", "secondaryInput"]),
  /**
     *  Custom z-index for the popover
     */
  popoverZIndex: PropTypes.number,
  readOnly: PropTypes.bool,
  /**
     *  Array of hex strings (3 or 6 character) to use as swatch options. Defaults to EUI visualization colors
     */
  swatches: PropTypes.arrayOf(PropTypes.string.isRequired),
  /**
     * Creates an input group with element(s) coming before input. It only shows when the `display` is set to `default`.
     * `string` | `ReactElement` or an array of these
     */
  prepend: PropTypes.any,
  /**
     * Creates an input group with element(s) coming after input. It only shows when the `display` is set to `default`.
     * `string` | `ReactElement` or an array of these
     */
  append: PropTypes.any,
  /**
     * Whether to render the alpha channel (opacity) value range slider.
     */
  showAlpha: PropTypes.bool,
  /**
     * Will format the text input in the provided format when possible (hue and saturation selection)
     * Exceptions: Manual text input and swatches will display as-authored
     * Default is to display the last format entered by the user
     */
  format: PropTypes.oneOf(["hex", "rgba"]),
  /**
     * Placement option for a secondary color value input.
     */
  secondaryInputDisplay: PropTypes.oneOf(["top", "bottom", "none"]),
  /**
     * Add a button to the primary input to clear its value.
     */
  isClearable: PropTypes.bool,
  /**
     * Text to replace the default 'Transparent' placeholder for unset color values.
     */
  placeholder: PropTypes.string,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
     * hex (string)
     * RGB (as comma separated string)
     * RGBa (as comma separated string)
     * Empty string will register as 'transparent'
     */
  color: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.oneOf([null])]),
  onBlur: PropTypes.func,
  /**
     * text (string, as entered or selected)
     * hex (8-digit hex if alpha < 1, otherwise 6-digit hex)
     * RGBa (as array; values of NaN if color is invalid)
     * isValid (boolean signifying if the input text is a valid color)
     */
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func
};
try {
  EuiColorPicker.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/color_picker/color_picker.tsx',
    description: '',
    displayName: 'EuiColorPicker',
    methods: [],
    props: {
      button: {
        defaultValue: null,
        description: 'Custom element to use instead of text input',
        name: 'button',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'ReactElement'
        }
      },
      compressed: {
        defaultValue: {
          value: 'false'
        },
        description: 'Use the compressed style for EuiFieldText',
        name: 'compressed',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      display: {
        defaultValue: {
          value: 'default'
        },
        description: '',
        name: 'display',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'EuiColorPickerDisplay',
          value: [{
            value: '"default"'
          }, {
            value: '"inline"'
          }]
        }
      },
      disabled: {
        defaultValue: null,
        description: '',
        name: 'disabled',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'fullWidth',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      id: {
        defaultValue: null,
        description: '',
        name: 'id',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      isInvalid: {
        defaultValue: null,
        description: 'Custom validation flag',
        name: 'isInvalid',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      mode: {
        defaultValue: {
          value: 'default'
        },
        description: 'Choose between swatches with gradient picker (default), swatches only, gradient picker only, or secondary input only.',
        name: 'mode',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'EuiColorPickerMode',
          value: [{
            value: '"default"'
          }, {
            value: '"swatch"'
          }, {
            value: '"picker"'
          }, {
            value: '"secondaryInput"'
          }]
        }
      },
      popoverZIndex: {
        defaultValue: null,
        description: 'Custom z-index for the popover',
        name: 'popoverZIndex',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      readOnly: {
        defaultValue: {
          value: 'false'
        },
        description: '',
        name: 'readOnly',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      swatches: {
        defaultValue: null,
        description: 'Array of hex strings (3 or 6 character) to use as swatch options. Defaults to EUI visualization colors',
        name: 'swatches',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'string[]'
        }
      },
      prepend: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming before input. It only shows when the `display` is set to `default`.\n' + '`string` | `ReactElement` or an array of these',
        name: 'prepend',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      append: {
        defaultValue: null,
        description: 'Creates an input group with element(s) coming after input. It only shows when the `display` is set to `default`.\n' + '`string` | `ReactElement` or an array of these',
        name: 'append',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'PrependAppendType'
        }
      },
      showAlpha: {
        defaultValue: {
          value: 'false'
        },
        description: 'Whether to render the alpha channel (opacity) value range slider.',
        name: 'showAlpha',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      format: {
        defaultValue: null,
        description: 'Will format the text input in the provided format when possible (hue and saturation selection)\n' + 'Exceptions: Manual text input and swatches will display as-authored\n' + 'Default is to display the last format entered by the user',
        name: 'format',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"hex" | "rgba"',
          value: [{
            value: '"hex"'
          }, {
            value: '"rgba"'
          }]
        }
      },
      secondaryInputDisplay: {
        defaultValue: {
          value: 'none'
        },
        description: 'Placement option for a secondary color value input.',
        name: 'secondaryInputDisplay',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"top" | "bottom" | "none"',
          value: [{
            value: '"top"'
          }, {
            value: '"bottom"'
          }, {
            value: '"none"'
          }]
        }
      },
      isClearable: {
        defaultValue: {
          value: 'false'
        },
        description: 'Add a button to the primary input to clear its value.',
        name: 'isClearable',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      placeholder: {
        defaultValue: null,
        description: "Text to replace the default 'Transparent' placeholder for unset color values.",
        name: 'placeholder',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'EuiColorPickerProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: '',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      color: {
        defaultValue: null,
        description: 'hex (string)\n' + 'RGB (as comma separated string)\n' + 'RGBa (as comma separated string)\n' + "Empty string will register as 'transparent'",
        name: 'color',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'HTMLDivElementOverrides'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'HTMLDivElementOverrides'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onBlur: {
        defaultValue: null,
        description: '',
        name: 'onBlur',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'HTMLDivElementOverrides'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'HTMLDivElementOverrides'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      },
      onChange: {
        defaultValue: null,
        description: 'text (string, as entered or selected)\n' + 'hex (8-digit hex if alpha < 1, otherwise 6-digit hex)\n' + 'RGBa (as array; values of NaN if color is invalid)\n' + 'isValid (boolean signifying if the input text is a valid color)',
        name: 'onChange',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'HTMLDivElementOverrides'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'HTMLDivElementOverrides'
        }],
        required: true,
        type: {
          name: '(text: string, output: EuiColorPickerOutput) => void'
        }
      },
      onFocus: {
        defaultValue: null,
        description: '',
        name: 'onFocus',
        parent: {
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'HTMLDivElementOverrides'
        },
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker.tsx',
          name: 'HTMLDivElementOverrides'
        }],
        required: false,
        type: {
          name: '() => void'
        }
      }
    },
    extendedInterfaces: ['EuiColorPickerProps', 'CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'HTMLDivElementOverrides']
  };
} catch (e) {}