/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { forwardRef, useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { useEuiI18n } from '../i18n';
import { getChromaColor } from './utils';
import { euiColorPickerSwatchStyles } from './color_picker_swatch.styles';
import { EuiToolTip } from '../tool_tip';
export var EuiColorPickerSwatch = forwardRef(({
  className,
  color,
  style,
  toolTipProps,
  showToolTip = true,
  ...rest
}, ref) => {
  const classes = classNames('euiColorPickerSwatch', className);
  const styles = useEuiMemoizedStyles(euiColorPickerSwatchStyles);
  const chromaColor = useMemo(() => getChromaColor(color, true), [color]);
  const background = useMemo(() => chromaColor ? chromaColor.css() : 'transparent', [chromaColor]);
  const ariaLabel = useEuiI18n('euiColorPickerSwatch.ariaLabel', 'Select {color} as the color', {
    color
  });
  const element = <button type="button" css={styles.euiColorPickerSwatch} className={classes} aria-label={ariaLabel} ref={ref} style={{
    background,
    ...style
  }} {...rest} />;
  return showToolTip ? <EuiToolTip content={color} {...toolTipProps} anchorProps={{
    ...toolTipProps?.anchorProps,
    css: [toolTipProps?.anchorProps?.css, styles.tooltip]
  }}
  // since the button already has a descriptive `ariaLabel` we can disable
  // the tooltip content from being read additionally by screen readers
  disableScreenReaderOutput={true}>
        {element}
      </EuiToolTip> : element;
});
EuiColorPickerSwatch.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  color: PropTypes.string,
  /**
       * renders a tooltip with the color value to provide a visual text alternative
       * @default true
       */
  showToolTip: PropTypes.bool,
  /** Additional props for the EuiToolip when `showToolTip={true}` */toolTipProps: PropTypes.shape({
    /**
       * Passes onto the span wrapping the trigger.
       */
    anchorClassName: PropTypes.string,
    /**
       * Passes onto the span wrapping the trigger.
       */
    anchorProps: PropTypes.shape({
      className: PropTypes.string,
      "aria-label": PropTypes.string,
      "data-test-subj": PropTypes.string,
      css: PropTypes.any
    }),
    /**
       * Passes onto the tooltip itself, not the trigger.
       */
    className: PropTypes.string,
    /**
       * The main content of your tooltip.
       */
    content: PropTypes.node,
    /**
       * Common display alternatives for the anchor wrapper
       */
    display: PropTypes.any,
    /**
       * An optional title for your tooltip.
       */
    title: PropTypes.node,
    /**
       * Unless you provide one, this will be randomly generated.
       */
    id: PropTypes.string,
    /**
       * When `true`, the tooltip's position is re-calculated when the user
       * scrolls. This supports having fixed-position tooltip anchors.
       *
       * When nesting an `EuiTooltip` in a scrollable container, `repositionOnScroll` should be `true`
       */
    repositionOnScroll: PropTypes.bool,
    /**
       * Disables the tooltip content being read by screen readers when focusing the trigger element.
       * Do not use when the trigger `aria-label` and tooltip `content` can be rephrased to be standalone
       * information (action & additional information).
       * Enable this prop only when the trigger has a descriptive label that either duplicates or includes
       * the tooltip content and would result in repetitive output.
       * @default false
       */
    disableScreenReaderOutput: PropTypes.bool,
    /**
       * If supplied, called when mouse movement causes the tool tip to be
       * hidden.
       */
    onMouseOut: PropTypes.func,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    css: PropTypes.any,
    delay: PropTypes.any,
    position: PropTypes.any
  })
};
EuiColorPickerSwatch.displayName = 'EuiColorPickerSwatch';
try {
  EuiColorPickerSwatch.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/color_picker/color_picker_swatch.tsx',
    description: '',
    displayName: 'EuiColorPickerSwatch',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@emotion/react/types/css-prop.d.ts',
          name: 'Attributes'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      color: {
        defaultValue: null,
        description: '',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker_swatch.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      showToolTip: {
        defaultValue: {
          value: 'true'
        },
        description: 'renders a tooltip with the color value to provide a visual text alternative',
        name: 'showToolTip',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker_swatch.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      toolTipProps: {
        defaultValue: null,
        description: 'Additional props for the EuiToolip when `showToolTip={true}`',
        name: 'toolTipProps',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/color_picker/color_picker_swatch.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Omit<EuiToolTipProps, "children" | "position" | "delay"> & { delay?: ToolTipDelay; position?: ToolTipPositions; }'
        }
      },
      ref: {
        defaultValue: null,
        description: 'Allows getting a ref to the component instance.\n' + 'Once the component unmounts, React will set `ref.current` to `null`\n' + '(or call the ref with `null` if you passed a callback ref).\n' + '@see {@link https ://react.dev/learn/referencing-values-with-refs#refs-and-the-dom React Docs}',
        name: 'ref',
        parent: {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        },
        declarations: [{
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'RefAttributes'
        }],
        required: false,
        type: {
          name: 'LegacyRef<HTMLButtonElement>'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes', 'ButtonHTMLAttributes', 'Attributes']
  };
} catch (e) {}