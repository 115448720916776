/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { EuiTextColor } from './text_color';
import { EuiTextAlign } from './text_align';
import { euiTextStyles } from './text.styles';
export var TEXT_SIZES = ['xs', 's', 'm', 'relative'];
export var EuiText = ({
  component = 'div',
  size = 'm',
  color,
  grow = true,
  textAlign,
  children,
  className,
  ...rest
}) => {
  const styles = useEuiMemoizedStyles(euiTextStyles);
  const cssStyles = [styles.euiText, !grow ? styles.constrainedWidth : undefined, styles[size]];
  const classes = classNames('euiText', className);
  const Component = component;
  let text = <Component css={cssStyles} className={classes} {...rest}>
      {children}
    </Component>;
  if (color) {
    text = <EuiTextColor component={component} color={color} className={classes} cloneElement>
        {text}
      </EuiTextColor>;
  }
  if (textAlign) {
    text = <EuiTextAlign component={component} textAlign={textAlign} className={classes} cloneElement>
        {text}
      </EuiTextAlign>;
  }
  return text;
};
EuiText.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * The HTML element/tag to render.
       * Use with care when nesting multiple components to ensure valid XHTML:
       * - `<div>` and other block tags are not valid to use inside `<p>`. If using the `<p>` tag, we recommend passing strings/text only.
       * - `<span>` is valid to be nested in any tag, and can have any tag nested within it.
       */
  component: PropTypes.oneOf(["div", "span", "p"]),
  /**
     * Any of our named colors or a `hex`, `rgb` or `rgba` value.
     * @default inherit
     */
  color: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired]),
  /**
     * Applies horizontal text alignment
     * @default left
     */
  textAlign: PropTypes.any,
  /**
       * Determines the text size. Choose `relative` to control the `font-size` based on the value of a parent container.
       */
  size: PropTypes.any,
  grow: PropTypes.bool
};
try {
  EuiText.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/text/text.tsx',
    description: '',
    displayName: 'EuiText',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      component: {
        defaultValue: {
          value: 'div'
        },
        description: 'The HTML element/tag to render.\n' + 'Use with care when nesting multiple components to ensure valid XHTML:\n' + '- `<div>` and other block tags are not valid to use inside `<p>`. If using the `<p>` tag, we recommend passing strings/text only.\n' + '- `<span>` is valid to be nested in any tag, and can have any tag nested within it.',
        name: 'component',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"div" | "p" | "span"',
          value: [{
            value: '"div"'
          }, {
            value: '"p"'
          }, {
            value: '"span"'
          }]
        }
      },
      color: {
        defaultValue: {
          value: 'inherit'
        },
        description: 'Any of our named colors or a `hex`, `rgb` or `rgba` value.',
        name: 'color',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Color | "default" | "accent" | "accentSecondary" | "success" | "warning" | "danger" | "subdued" | "ghost"'
        }
      },
      textAlign: {
        defaultValue: {
          value: 'left'
        },
        description: 'Applies horizontal text alignment',
        name: 'textAlign',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text/types.ts',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"center" | "left" | "right"',
          value: [{
            value: '"center"'
          }, {
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      size: {
        defaultValue: {
          value: 'm'
        },
        description: 'Determines the text size. Choose `relative` to control the `font-size` based on the value of a parent container.',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text/text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m" | "xs" | "relative"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }, {
            value: '"xs"'
          }, {
            value: '"relative"'
          }]
        }
      },
      grow: {
        defaultValue: {
          value: 'true'
        },
        description: '',
        name: 'grow',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/text/text.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}