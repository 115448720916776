/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../services';
import { EuiFormLabel } from '../form/form_label/form_label';
import { euiKeyPadMenuStyles } from './key_pad_menu.styles';
export var EuiKeyPadMenu = ({
  children,
  className,
  checkable,
  ...rest
}) => {
  const hasCheckableConfig = typeof checkable === 'object';
  const classes = classNames('euiKeyPadMenu', className);
  const styles = useEuiMemoizedStyles(euiKeyPadMenuStyles);
  const cssStyles = [styles.euiKeyPadMenu];
  const legendCssStyles = [styles.euiKeyPadMenu__legend, hasCheckableConfig && checkable?.legendProps?.css];
  const legend = hasCheckableConfig && checkable.legend ? <EuiFormLabel {...checkable.legendProps} css={legendCssStyles} type="legend">
        {checkable.legend}
      </EuiFormLabel> : undefined;
  return checkable ? <fieldset css={cssStyles} className={classes} aria-label={hasCheckableConfig ? checkable.ariaLegend : undefined} {...rest}>
      {legend}
      {children}
    </fieldset> : <ul css={cssStyles} className={classes} {...rest}>
      {React.Children.map(children, child => <li>{child}</li>)}
    </ul>;
};
EuiKeyPadMenu.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  /**
       * Renders the the group as a `fieldset`.
       * Set to `true` to customize the labelling, or pass an #EuiKeyPadMenuCheckableProps object to add a `legend` or `ariaLegend`
       */
  checkable: PropTypes.oneOfType([PropTypes.shape({
    /**
         * Rendered within a `legend` to label the `fieldset`.
         * To create a visually hidden legend, use `ariaLegend`
         */
    legend: PropTypes.node,
    /**
         * Pass through props to a `EuiFormLabel` component, except for `type`
         */
    legendProps: PropTypes.any,
    /**
         * Custom aria-attribute for creating a *visually hidden* legend.
         * To create a visible legend, use `legend`
         */
    ariaLegend: PropTypes.string
  }).isRequired, PropTypes.oneOf([true])])
};
try {
  EuiKeyPadMenu.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/key_pad_menu/key_pad_menu.tsx',
    description: '',
    displayName: 'EuiKeyPadMenu',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      checkable: {
        defaultValue: null,
        description: 'Renders the the group as a `fieldset`.\n' + 'Set to `true` to customize the labelling, or pass an #EuiKeyPadMenuCheckableProps object to add a `legend` or `ariaLegend`',
        name: 'checkable',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/key_pad_menu/key_pad_menu.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'true | (DisambiguateSet<{ legend: ReactNode; legendProps?: Omit<_EuiFormLegendProps, "type">; }, { ariaLegend: string; }> & { ...; }) | (DisambiguateSet<...> & { ...; })'
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}