import React from 'react';
import PropTypes from "prop-types";
import { EuiCodeBlock, EuiHorizontalRule, EuiSpacer, useEuiTheme } from '../../../../../src';
export var GuideSectionSnippets = ({
  snippets
}) => {
  const {
    highContrastMode
  } = useEuiTheme();
  const codeBlockProps = {
    language: 'jsx',
    fontSize: 'm',
    paddingSize: 'm',
    isCopyable: true,
    // Code block is used within a panel which already has a border - skip doubling up
    ...(highContrastMode && {
      css: {
        border: 'none'
      }
    })
  };
  let snippetCode;
  if (typeof snippets === 'string') {
    snippetCode = <EuiCodeBlock {...codeBlockProps}>{snippets}</EuiCodeBlock>;
  } else {
    snippetCode = snippets.map((snip, index) => <React.Fragment key={`snippet${index}`}>
        <EuiCodeBlock {...codeBlockProps}>{snip}</EuiCodeBlock>
        {index < snippets.length - 1 && (highContrastMode ? <EuiHorizontalRule margin="xs" /> : <EuiSpacer size="xs" />)}
      </React.Fragment>);
  }
  return <React.Fragment>{snippetCode}</React.Fragment>;
};
GuideSectionSnippets.propTypes = {
  snippets: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired).isRequired]).isRequired
};
try {
  GuideSectionSnippets.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_snippets.tsx',
    description: '',
    displayName: 'GuideSectionSnippets',
    methods: [],
    props: {
      snippets: {
        defaultValue: null,
        description: '',
        name: 'snippets',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src-docs/src/components/guide_section/guide_section_parts/guide_section_snippets.tsx',
          name: 'TypeLiteral'
        }],
        required: true,
        type: {
          name: 'string | string[]'
        }
      }
    },
    extendedInterfaces: []
  };
} catch (e) {}