/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React, { useCallback } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { isTabbable } from 'tabbable';
import { useEuiMemoizedStyles } from '../../../services';
import { EuiButton } from '../../button/button';
import { EuiScreenReaderOnly } from '../screen_reader_only';
import { euiSkipLinkStyles } from './skip_link.styles';
export var POSITIONS = ['static', 'fixed', 'absolute'];
export var EuiSkipLink = ({
  destinationId,
  fallbackDestination = 'main',
  overrideLinkBehavior,
  tabIndex,
  position = 'static',
  children,
  className,
  onClick: _onClick,
  ...rest
}) => {
  const classes = classNames('euiSkipLink', className);
  const styles = useEuiMemoizedStyles(euiSkipLinkStyles);
  const cssStyles = [styles.euiSkipLink, position !== 'static' ? styles[position] : undefined];
  const onClick = useCallback(e => {
    let destinationEl = null;
    // Check if the destination ID is valid
    destinationEl = document.getElementById(destinationId);
    const hasValidId = !!destinationEl;
    // Check the fallback destination if not
    if (!destinationEl && fallbackDestination) {
      if (Array.isArray(fallbackDestination)) {
        for (let i = 0; i < fallbackDestination.length; i++) {
          destinationEl = document.querySelector(fallbackDestination[i]);
          if (destinationEl) break; // Stop once the first fallback has been found
        }
      } else {
        destinationEl = document.querySelector(fallbackDestination);
      }
    }
    if ((overrideLinkBehavior || !hasValidId) && destinationEl) {
      e.preventDefault();

      // Scroll to the top of the destination content only if it's ~mostly out of view
      const destinationY = destinationEl.getBoundingClientRect().top;
      const halfOfViewportHeight = window.innerHeight / 2;
      if (destinationY >= halfOfViewportHeight || window.scrollY >= destinationY + halfOfViewportHeight) {
        destinationEl.scrollIntoView();
      }

      // Ensure the destination content is focusable
      if (!isTabbable(destinationEl)) {
        destinationEl.tabIndex = -1;
        destinationEl.addEventListener('blur', () => destinationEl?.removeAttribute('tabindex'), {
          once: true
        });
      }
      destinationEl.focus({
        preventScroll: true
      }); // Scrolling is already handled above, and focus autoscroll behaves oddly on Chrome around fixed headers
    }
    _onClick?.(e);
  }, [overrideLinkBehavior, destinationId, fallbackDestination, _onClick]);
  return <EuiScreenReaderOnly showOnFocus>
      <EuiButton css={cssStyles} className={classes} tabIndex={position === 'fixed' ? 0 : tabIndex} size="s" fill href={`#${destinationId}`} onClick={onClick} {...rest}>
        {children}
      </EuiButton>
    </EuiScreenReaderOnly>;
};
EuiSkipLink.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  /**
     * Change the display position of the element when focused.
     * If 'fixed', the link will be fixed to the top left of the viewport
     */
  position: PropTypes.any,
  /**
     * Typically an anchor id (e.g. `a11yMainContent`), the value provided
     * will be prepended with a hash `#` and used as the link `href`
     */
  destinationId: PropTypes.string.isRequired,
  /**
     * If no destination ID element exists or can be found, you may provide a query selector
     * string to fall back to.
     *
     * For complex applications with potentially variable layouts per page, an array of
     * query selectors can be passed, e.g. `['main', '[role=main]', '.appWrapper']`, which
     * prioritizes looking for multiple fallbacks based on array order.
     * @default main
     */
  fallbackDestination: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired).isRequired]),
  /**
     * If default HTML anchor link behavior is not desired (e.g. for SPAs with hash routing),
     * setting this flag to true will manually scroll to and focus the destination element
     * without changing the browser URL's hash
     */
  overrideLinkBehavior: PropTypes.bool,
  /**
     * When position is fixed, this is forced to `0`
     */
  tabIndex: PropTypes.number,
  children: PropTypes.node,
  /**
     * Make button a solid color for prominence
     */
  fill: PropTypes.bool,
  /**
     * Any of the named color palette options.
     */
  color: PropTypes.any,
  /**
     * Use size `s` in confined spaces
     */
  size: PropTypes.any,
  /**
     * `disabled` is also allowed
     */
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  buttonRef: PropTypes.any
};
try {
  EuiSkipLink.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/accessibility/skip_link/skip_link.tsx',
    description: '',
    displayName: 'EuiSkipLink',
    methods: [],
    props: {
      position: {
        defaultValue: {
          value: 'static'
        },
        description: 'Change the display position of the element when focused.\n' + "If 'fixed', the link will be fixed to the top left of the viewport",
        name: 'position',
        parent: {
          fileName: 'eui/src/components/accessibility/skip_link/skip_link.tsx',
          name: 'EuiSkipLinkInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/accessibility/skip_link/skip_link.tsx',
          name: 'EuiSkipLinkInterface'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"fixed" | "static" | "absolute"',
          value: [{
            value: '"fixed"'
          }, {
            value: '"static"'
          }, {
            value: '"absolute"'
          }]
        }
      },
      destinationId: {
        defaultValue: null,
        description: 'Typically an anchor id (e.g. `a11yMainContent`), the value provided\n' + 'will be prepended with a hash `#` and used as the link `href`',
        name: 'destinationId',
        parent: {
          fileName: 'eui/src/components/accessibility/skip_link/skip_link.tsx',
          name: 'EuiSkipLinkInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/accessibility/skip_link/skip_link.tsx',
          name: 'EuiSkipLinkInterface'
        }],
        required: true,
        type: {
          name: 'string'
        }
      },
      fallbackDestination: {
        defaultValue: {
          value: 'main'
        },
        description: 'If no destination ID element exists or can be found, you may provide a query selector\n' + 'string to fall back to.\n' + '\n' + 'For complex applications with potentially variable layouts per page, an array of\n' + "query selectors can be passed, e.g. `['main', '[role=main]', '.appWrapper']`, which\n" + 'prioritizes looking for multiple fallbacks based on array order.',
        name: 'fallbackDestination',
        parent: {
          fileName: 'eui/src/components/accessibility/skip_link/skip_link.tsx',
          name: 'EuiSkipLinkInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/accessibility/skip_link/skip_link.tsx',
          name: 'EuiSkipLinkInterface'
        }],
        required: false,
        type: {
          name: 'string | string[]'
        }
      },
      overrideLinkBehavior: {
        defaultValue: null,
        description: 'If default HTML anchor link behavior is not desired (e.g. for SPAs with hash routing),\n' + 'setting this flag to true will manually scroll to and focus the destination element\n' + "without changing the browser URL's hash",
        name: 'overrideLinkBehavior',
        parent: {
          fileName: 'eui/src/components/accessibility/skip_link/skip_link.tsx',
          name: 'EuiSkipLinkInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/accessibility/skip_link/skip_link.tsx',
          name: 'EuiSkipLinkInterface'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      tabIndex: {
        defaultValue: null,
        description: 'When position is fixed, this is forced to `0`',
        name: 'tabIndex',
        parent: {
          fileName: 'eui/src/components/accessibility/skip_link/skip_link.tsx',
          name: 'EuiSkipLinkInterface'
        },
        declarations: [{
          fileName: 'eui/src/components/accessibility/skip_link/skip_link.tsx',
          name: 'EuiSkipLinkInterface'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'number'
        }
      },
      fill: {
        defaultValue: null,
        description: 'Make button a solid color for prominence',
        name: 'fill',
        parent: {
          fileName: 'eui/src/components/button/button.tsx',
          name: 'BaseProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button.tsx',
          name: 'BaseProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      color: {
        defaultValue: null,
        description: 'Any of the named color palette options.',
        name: 'color',
        parent: {
          fileName: 'eui/src/components/button/button.tsx',
          name: 'BaseProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button.tsx',
          name: 'BaseProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"text" | "accent" | "accentSecondary" | "primary" | "success" | "warning" | "danger"',
          value: [{
            value: '"text"'
          }, {
            value: '"accent"'
          }, {
            value: '"accentSecondary"'
          }, {
            value: '"primary"'
          }, {
            value: '"success"'
          }, {
            value: '"warning"'
          }, {
            value: '"danger"'
          }]
        }
      },
      size: {
        defaultValue: null,
        description: 'Use size `s` in confined spaces',
        name: 'size',
        parent: {
          fileName: 'eui/src/components/button/button.tsx',
          name: 'BaseProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button.tsx',
          name: 'BaseProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      isDisabled: {
        defaultValue: null,
        description: '`disabled` is also allowed',
        name: 'isDisabled',
        parent: {
          fileName: 'eui/src/components/button/button.tsx',
          name: 'BaseProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button.tsx',
          name: 'BaseProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      style: {
        defaultValue: null,
        description: '',
        name: 'style',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'CSSProperties'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      element: {
        defaultValue: null,
        description: '',
        name: 'element',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"a" | "button" | "span"',
          value: [{
            value: '"a"'
          }, {
            value: '"button"'
          }, {
            value: '"span"'
          }]
        }
      },
      isSelected: {
        defaultValue: null,
        description: 'Applies the boolean state as the `aria-pressed` property to create a toggle button.\n' + '*Only use when the readable text does not change between states.*',
        name: 'isSelected',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      fullWidth: {
        defaultValue: null,
        description: 'Extends the button to 100% width',
        name: 'fullWidth',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      minWidth: {
        defaultValue: null,
        description: 'Override the default minimum width',
        name: 'minWidth',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        }],
        required: false,
        type: {
          name: 'false | MinWidth<string | number>'
        }
      },
      isLoading: {
        defaultValue: null,
        description: 'Force disables the button and changes the icon to a loading spinner',
        name: 'isLoading',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        }],
        required: false,
        type: {
          name: 'boolean'
        }
      },
      contentProps: {
        defaultValue: null,
        description: "Object of props passed to the <span/> wrapping the button's content",
        name: 'contentProps',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        }],
        required: false,
        type: {
          name: 'CommonProps & HTMLAttributes<HTMLSpanElement>'
        }
      },
      type: {
        defaultValue: null,
        description: '',
        name: 'type',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display.tsx',
          name: 'EuiButtonDisplayCommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AnchorHTMLAttributes'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"button" | "reset" | "submit"',
          value: [{
            value: '"button"'
          }, {
            value: '"reset"'
          }, {
            value: '"submit"'
          }]
        }
      },
      iconType: {
        defaultValue: null,
        description: 'Any `type` accepted by EuiIcon',
        name: 'iconType',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }],
        required: false,
        type: {
          name: 'IconType'
        }
      },
      iconSide: {
        defaultValue: null,
        description: 'Can only be one side `left` or `right`',
        name: 'iconSide',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: 'ButtonContentIconSide',
          value: [{
            value: '"left"'
          }, {
            value: '"right"'
          }]
        }
      },
      textProps: {
        defaultValue: null,
        description: "Object of props passed to the `<span>` wrapping the content's text/children only (not icon)\n" + '\n' + 'This span wrapper can be removed by passing `textProps={false}`.',
        name: 'textProps',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }],
        required: false,
        type: {
          name: "false | (HTMLAttributes<HTMLSpanElement> & CommonProps & { ref?: Ref<HTMLSpanElement>; 'data-text'?: string; })"
        }
      },
      iconSize: {
        defaultValue: null,
        description: '',
        name: 'iconSize',
        parent: {
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        },
        declarations: [{
          fileName: 'eui/src/components/button/button_display/_button_display_content.tsx',
          name: 'EuiButtonDisplayContentProps'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      },
      href: {
        defaultValue: null,
        description: '',
        name: 'href',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AnchorHTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      onClick: {
        defaultValue: null,
        description: '',
        name: 'onClick',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'TypeLiteral'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'DOMAttributes'
        }],
        required: false,
        type: {
          name: 'MouseEventHandler<HTMLAnchorElement>'
        }
      },
      buttonRef: {
        defaultValue: null,
        description: '',
        name: 'buttonRef',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/accessibility/skip_link/skip_link.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'Ref<HTMLAnchorElement>'
        }
      }
    },
    extendedInterfaces: ['EuiSkipLinkInterface', 'BaseProps', 'EuiButtonDisplayCommonProps', 'CommonProps', 'EuiButtonDisplayContentProps', 'AnchorHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}