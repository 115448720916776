/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiMemoizedStyles } from '../../../services';
import { EuiIcon } from '../../icon';
import { useEuiI18n } from '../../i18n';
import { EuiFormControlLayoutClearButtonStyles } from './form_control_layout_clear_button.styles';
export var EuiFormControlLayoutClearButton = ({
  className,
  onClick,
  size = 'm',
  ...rest
}) => {
  const classes = classNames('euiFormControlLayoutClearButton', className);
  const styles = useEuiMemoizedStyles(EuiFormControlLayoutClearButtonStyles);
  const cssStyles = [styles.euiFormControlLayoutClearButton, styles.size[size]];
  const iconStyles = [styles.icon.euiFormControlLayoutClearButton__icon, styles.icon.size[size]];
  const ariaLabel = useEuiI18n('euiFormControlLayoutClearButton.label', 'Clear input');
  return <button type="button" css={cssStyles} className={classes} onClick={onClick} aria-label={ariaLabel} {...rest}>
      <EuiIcon css={iconStyles} className="euiFormControlLayoutClearButton__icon" type="cross" size={size} />
    </button>;
};
EuiFormControlLayoutClearButton.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  css: PropTypes.any,
  size: PropTypes.oneOf(["s", "m"])
};
try {
  EuiFormControlLayoutClearButton.__docgenInfo = {
    tags: {},
    filePath: '/app/packages/eui/src/components/form/form_control_layout/form_control_layout_clear_button.tsx',
    description: '',
    displayName: 'EuiFormControlLayoutClearButton',
    methods: [],
    props: {
      className: {
        defaultValue: null,
        description: '',
        name: 'className',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'HTMLAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'aria-label': {
        defaultValue: null,
        description: 'Defines a string value that labels the current element.\n' + '@see aria-labelledby.',
        name: 'aria-label',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }, {
          fileName: 'eui/node_modules/@types/react/ts5.0/index.d.ts',
          name: 'AriaAttributes'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      'data-test-subj': {
        defaultValue: null,
        description: '',
        name: 'data-test-subj',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'string'
        }
      },
      css: {
        defaultValue: null,
        description: '',
        name: 'css',
        parent: {
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        },
        declarations: [{
          fileName: 'eui/src/components/common.ts',
          name: 'CommonProps'
        }],
        required: false,
        type: {
          name: 'Interpolation<Theme>'
        }
      },
      size: {
        defaultValue: {
          value: 'm'
        },
        description: '',
        name: 'size',
        parent: undefined,
        declarations: [{
          fileName: 'eui/src/components/form/form_control_layout/form_control_layout_clear_button.tsx',
          name: 'TypeLiteral'
        }],
        required: false,
        type: {
          name: 'enum',
          raw: '"s" | "m"',
          value: [{
            value: '"s"'
          }, {
            value: '"m"'
          }]
        }
      }
    },
    extendedInterfaces: ['CommonProps', 'ButtonHTMLAttributes', 'HTMLAttributes', 'AriaAttributes', 'DOMAttributes']
  };
} catch (e) {}